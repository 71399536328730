/* eslint unicorn/filename-case: "off" */
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  Theme,
  ThemeOptions,
} from '@material-ui/core';
import { DarkVariant } from '../variants/Dark';

export const createAriivaTheme = (options: ThemeOptions): Theme => {
  const { name, ...rest } = DarkVariant;

  return createMuiTheme({
    ...rest,
    ...options,
    overrides: {
      MuiDialogTitle: {
        root: {
          padding: 24,
          backgroundColor: '#e5e8ee',
          border: '1px solid #dadfe8',
        },
      },
    },
  });
};
