import React, { ErrorInfo } from 'react';
import { Typography, Button } from '@material-ui/core';
import { FormWrapper } from './components/FormWrapper';
import { Title } from './components/Title';

interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
  eventId?: string;
}

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryState> {
  public state = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return {
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    /* const promise = ErrorReportingService.error(error, errorInfo);
    // eslint-disable-next-line promise/no-promise-in-callback
    promise
      .then((eventId: string) => {
        this.setState({
          eventId,
          errorInfo,
        });
        return eventId;
      })
      .catch(() => {});
     */
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    if (!this.state.error) {
      return this.props.children;
    }

    // Hooks must not be used here. Otherwise this will lead to
    // a runtime error. We have to use another approach here.

    return (
      <div
        style={{
          maxWidth: 500,
          margin: '0 auto',
          top: '50%',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}
      >
        <FormWrapper>
          <Title>Etwas ist schiefgelaufen</Title>
          <Typography
            style={{ marginBottom: 20, textAlign: 'center' }}
            dangerouslySetInnerHTML={{
              __html:
                'Ooops ... das hätte nicht passieren dürfen. <br />Die Entwickler wurden über diesen Fehler informiert.',
            }}
          />
          <Typography
            style={{ marginBottom: 20, textAlign: 'center' }}
            dangerouslySetInnerHTML={{
              __html:
                'Bitte versuchen Sie es in Kürze noch einmal. Auf Wunsch können Sie uns auch mehr Details zu dem Fehler mitteilen.',
            }}
          />
          <Button variant="contained" color="secondary" href="/" fullWidth>
            Zurück zum Dashboard
          </Button>
        </FormWrapper>
      </div>
    );
  }
}

export const ErrorBoundary = ErrorBoundaryComponent;
