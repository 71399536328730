export const API_ENDPOINT = 'REACT_APP_SRV_ENDPOINT';
export const AZURE_AD_CLIENT_ID = 'REACT_APP_AZURE_AD_CLIENT_ID';
export const AZURE_AD_AUTHORITY = 'REACT_APP_AZURE_AD_AUTHORITY';
export const AZURE_AD_REDIRECT_URI = 'REACT_APP_AZURE_AD_REDIRECT_URI';
export const AZURE_AD_POSTLOGOUT_REDIRECT_URI = 'AD_POSTLOGOUT_REDIRECT_URI';

const getValue = (name: string): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window._env_[name] || process.env[name];
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window._env_ = window._env_ || {};

export const Environment = {
  API_ENDPOINT: getValue(API_ENDPOINT),
  AZURE_AD_CLIENT_ID: getValue(AZURE_AD_CLIENT_ID),
  AZURE_AD_AUTHORITY: getValue(AZURE_AD_AUTHORITY),
  AZURE_AD_REDIRECT_URI: getValue(AZURE_AD_REDIRECT_URI),
  AZURE_AD_POSTLOGOUT_REDIRECT_URI: getValue(AZURE_AD_POSTLOGOUT_REDIRECT_URI),
};
