import { ComponentsProps } from '@material-ui/core/styles/props';

export const Props: ComponentsProps = {
  MuiButtonBase: {
    disableRipple: false,
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: 'h6' },
  },
};
