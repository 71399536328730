import React from 'react';
import { CellProps } from 'react-table';
import { Invoice } from '../../../../../dto/Invoice';
import { StatusBadge } from '../../StatusBadge';

export const StatusCell = <TYPE extends Invoice>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;

  return <StatusBadge status={value} />;
};
