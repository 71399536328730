import styled from 'styled-components';

import { ResponsivenessConfiguration } from '../ResponsivenessConfiguration';

export const Drawer = styled.div`
  ${(props) =>
    props.theme.breakpoints.up(
      ResponsivenessConfiguration.permanentSidebarBreakPoint
    )} {
    width: ${ResponsivenessConfiguration.drawerWidth}px;
    flex-shrink: 0;
  }
`;
