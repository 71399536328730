import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext, ArrayField, useFieldArray } from 'react-hook-form';
import {
  TextField,
  LinearProgress,
  Fab,
  CircularProgress,
  MenuItem,
  Popper,
  PopperProps,
  Checkbox,
  FormControlLabel,
  Tooltip,
  FormControl,
} from '@material-ui/core';
import {
  Add,
  DeleteForever,
  Refresh,
  Send,
  ArrowDownward,
  ArrowUpward,
  Block,
} from '@material-ui/icons';
import { updateSelectedInvoice } from '../../../../../stores/globalStore';
import { statusBadges } from '../../StatusBadge';
// one day make real queries for this data
/* import { useSapTypesQuery } from '../../../../../query/SapTypesQuery';
import { useCurrenciesquerry } from '../../../../../query/CurrenciesQuery'; */
import { useUsersQuery } from '../../../../../query/UsersQuery';
import {
  useCostcenterQuery,
  Costcenter,
} from '../../../../../query/CostcenterQuery';
import { useGlListQuery, GlList } from '../../../../../query/GlListQuery';
import { useZTermsQuery } from '../../../../../query/ZTermsQuery';
import { useOrdersQuery } from '../../../../../query/OrdersQuery';
import { useUpdateSapMutation } from '../../../../../query/UpdateSapMutation';
import { Tax, taxSignsSap } from '../../../../../query/taxSigns';
import {
  Comment,
  InvoicePosition,
  InvoicePositionFi,
  ZTerm,
} from '../../../../../dto/Invoice';
import { Order, Position } from '../../../../../dto/Order';
import { InvoiceSelectModal } from '../InvoiceSelectModal/InvoiceSelectModal';
import { useWorkflowQuery } from '../../../../../query/WorkflowQuery';
import { useMsal } from '@azure/msal-react';
import { checkAuthorisation } from '../../../checkAuthorisation';

const SCForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
  width: 100%;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 150px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 10px 10px 10px;
  width: 100%;
  background: white;
  border-radius: 5px;
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  margin-bottom: 3px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormElementStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineBreak = styled.div`
  flex-basis: 100%;
  width: 0px;
  height: 3px;
  display: inline-block;
  & > * {
    height: 1px;
    width: 100%;
    color: lightgrey;
    background: lightgrey;
    margin-top: 5px;
  }
`;
const LineBreakBlanc = styled.div`
  flex-basis: 100%;
  width: 0px;
  height: 3px;
  display: inline-block;
  & > * {
    height: 1px;
    width: 100%;
    margin-top: 5px;
  }
`;

const BruttoTextfield = styled(TextField)`
  width: 120px;
  & > label {
    color: black !important;
  }
  & :disabled {
    color: black;
    font-weight: bold;
  }
`;

const BruttoInvoiceTextfield = styled(TextField).attrs(
  (props: { customColor: string }) => props
)`
  width: 120px;
  & > label {
    color: ${(props) => props.customColor};
  }
  & :disabled {
    color: black;
    font-weight: bold;
  }
`;

const MaterialTextfield = styled(TextField)`
  width: 110px;
  & :disabled {
    color: black;
  }
`;

const SCPopper = (props: PopperProps): JSX.Element => {
  return (
    <Popper {...props} style={{ width: 'auto' }} placement="bottom-start" />
  );
};

const calculateNettoByWareneingang = (
  piecesTotal: string,
  piecesWareneingang: string,
  nettoTotal: string
): string => {
  const piecesTotalFloat = Number.parseFloat(piecesTotal);
  const piecesWareneingangFloat = Number.parseFloat(piecesWareneingang);
  const nettoTotalFloat = Number.parseFloat(nettoTotal);

  const calculationFloat =
    (nettoTotalFloat / piecesTotalFloat) * piecesWareneingangFloat;
  return calculationFloat.toFixed(2).toString();
};

export const InvoiceDetailForm = (props: any): JSX.Element => {
  const {
    fieldsMm,
    fieldsFi,
    addPositionRender,
    addManualPositionRender,
    removePositionRender,
    fieldsComments,
    appendComment,
    setCalc,
    config,
    disableWholeForm,
  } = props;
  const { state } = useStateMachine({
    updateSelectedInvoice,
  });
  const [focusKostenträger, setFocusKostenträger] = useState(false);
  const [showManual, setShowManual] = useState(false);

  const { accounts: user } = useMsal();

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    errors,
  } = useFormContext();

  watch();
  const { data: usersData } = useUsersQuery();

  const { data: zTermsData } = useZTermsQuery();
  const {
    data: ordersData,
    isLoading: isLoadingOrders,
    refetch: refetchOrders,
  } = useOrdersQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    state.selectedInvoice!.creditorId
  );
  const { data: glListData, isLoading: isLoadingGlList } = useGlListQuery();
  const {
    data: workflowData,
    refetch: refetchWorkflow,
    isLoading,
  } = useWorkflowQuery();
  const {
    data: costcenterData,
    isLoading: isLoadingCostcenters,
  } = useCostcenterQuery();

  const { mutate: mutateSap, isLoading: sapIsLoading } = useUpdateSapMutation({
    _id: state.selectedInvoice?._id,
    creditorId: getValues('creditorId'),
  });

  useEffect(() => {
    refetchOrders();
    setCalc(true);
  }, [refetchOrders, setCalc]);

  // exchange with real fetched data in the future
  const currenciesData = ['EUR', 'CHF', 'GBP', 'CZK', 'HUF', 'USD', 'SGD'];
  const sapTypesData = ['MM', 'FI'];
  const controllingTypes = ['Kostenstelle', 'PSP-Element', 'Innenauftrag'];
  const lockOptions = [
    'Zur Zahlung frei',
    'Aussond.-Insolvenz',
    'bereits manuell bez.',
    'Firmenworklaut',
    'Freigabe fehlt',
    'Konto übergehen',
    'per Nachnahme bez.',
    'Rechnungsprüfung',
    'Vorauszahlung',
    'Bankeinzug',
  ];

  const accounts = ['Soll', 'Haben'];
  const [addPosition, setAddPosition] = useState<boolean>(false);
  const [addManualPosition, setAddManualPosition] = useState<boolean>(false);
  const [removePosition, setRemovePosition] = useState<boolean>(false);
  const [removalIndex, setRemovalIndex] = useState<number>();

  const removePositionByIndex = (index: number): void => {
    setRemovalIndex(index);
    setRemovePosition(true);
  };

  useEffect(() => {
    if (addPosition) {
      addPositionRender();
      setAddPosition(false);
      setCalc(true);
    }
    if (addManualPosition) {
      addManualPositionRender();
      setAddManualPosition(false);
      setCalc(true);
    }
    if (removePosition) {
      removePositionRender(removalIndex);
      setRemovePosition(false);
      setCalc(true);
    }
    if (focusKostenträger) {
      setFocusKostenträger(false);
    }
  });

  const getIndexOfSelectedOrder = (formIdentifier: string): number => {
    let returnValue = 0;
    if (ordersData) {
      ordersData.orders.forEach((order, index) => {
        if (order.orderId === getValues(formIdentifier).orderId) {
          returnValue = index;
        }
      });
    }
    return returnValue;
  };

  const getIndexOfSelectedPosition = (
    formIdenfifierOrder: string,
    formIdentifierPosition: string
  ): number => {
    let returnValue = 0;
    if (ordersData) {
      ordersData.orders[
        getIndexOfSelectedOrder(formIdenfifierOrder)
      ].positions.forEach((position, index) => {
        if (position.EBELP === getValues(formIdentifierPosition).EBELP) {
          returnValue = index;
        }
      });
    }
    return returnValue;
  };

  const formatDate = (dateToChange: string | undefined): string => {
    if (!dateToChange) {
      return '';
    }

    return new Date(Number.parseInt(dateToChange, 10))
      .toISOString()
      .slice(0, 10);
  };

  const getRidOfLeadingZeros = (string: string): string => {
    if (!string) return string;
    let i = 0;
    while (string[i] === '0') {
      i += 1;
    }
    return string.slice(i);
  };

  return (
    <SCForm>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <fieldset disabled={disableWholeForm && 'disabled'}>
        <FormSection>
          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('creditorName')}
                value={getValues('creditorName')}
                onChange={(event) =>
                  setValue('creditorName', event.target.value)
                }
                label="Kreditorname"
                style={{ width: '500px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('invoiceStatus')}
                value={getValues('invoiceStatus')}
                onChange={(event) =>
                  setValue('invoiceStatus', event.target.value)
                }
                label="Rechnungs Status"
                style={{ width: '200px' }}
                select
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              >
                {Object.entries(statusBadges).map(([key, value]) => {
                  return (
                    <MenuItem key={value.label} value={key}>
                      {value.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormElementStyled>
          </FormRow>
          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('creditorCountry')}
                value={getValues('creditorCountry')}
                onChange={(event) =>
                  setValue('creditorCountry', event.target.value)
                }
                label="Land"
                style={{ width: '30px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('creditorStreet')}
                value={getValues('creditorStreet')}
                onChange={(event) =>
                  setValue('creditorStreet', event.target.value)
                }
                label="Straße"
                style={{ width: '100px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('creditorHouseNr')}
                value={getValues('creditorHouseNr')}
                onChange={(event) =>
                  setValue('creditorHouseNr', event.target.value)
                }
                label="Nr."
                style={{ width: '20px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('creditorPLZ')}
                value={getValues('creditorPLZ')}
                onChange={(event) =>
                  setValue('creditorPLZ', event.target.value)
                }
                label="PLZ"
                style={{ width: '50px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('creditorCity')}
                value={getValues('creditorCity')}
                onChange={(event) =>
                  setValue('creditorCity', event.target.value)
                }
                label="Ort"
                style={{ width: '150px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('creditorIBAN')}
                value={getValues('creditorIBAN')}
                onChange={(event) =>
                  setValue('creditorIBAN', event.target.value)
                }
                label="IBAN"
                style={{ width: '200px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
          </FormRow>
          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('sapType')}
                value={getValues('sapType')}
                onChange={(event) => {
                  setValue('sapType', event.target.value);
                  setValue('allocNr', '');
                  setValue('positionSumFi', '');
                  setValue('account', null);
                  setValue('taxSign', null);
                  setValue('orderNrSelected', '');
                  setValue('positionSelected', '');
                  setValue('positionCountMm', '');
                  setValue('positionMaterialMm', '');
                  setValue('positionSumMm', '');
                  setValue('controllingType', 'Kostenstelle');
                  setValue('pspElement', '');
                  setValue('internal', '');
                  setCalc(true);
                }}
                label="Rechnungstyp"
                style={{ width: '120px' }}
                select
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              >
                {(getValues('sapType') === 'MM' ||
                  getValues('sapType') === 'FI') &&
                  sapTypesData.map((value) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </FormElementStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ fontSize: '0.7em', margin: '0px' }}>Nachträglich</p>
              <Checkbox
                color="primary"
                checked={watch('subsequent')}
                size="small"
                onChange={() => {
                  setValue('subsequent', !watch('subsequent'));
                }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </div>
            <FormElementStyled>
              <TextField
                {...register('creditorId')}
                value={getValues('creditorId')}
                onChange={(event) => setValue('creditorId', event.target.value)}
                label="Kreditor Id"
                style={{ width: '150px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('sapInvoiceNumber')}
                value={getValues('sapInvoiceNumber')}
                onChange={(event) =>
                  setValue('sapInvoiceNumber', event.target.value)
                }
                label="Sap Belegnummer"
                style={{ width: '150px' }}
                disabled={config === 'archive'}
              />
            </FormElementStyled>

            {sapIsLoading ? (
              <FormElementStyled>
                <Fab variant="extended" size="medium" color="secondary">
                  <CircularProgress size={20} style={{ marginRight: '10px' }} />{' '}
                  Sap Abfrage
                </Fab>
              </FormElementStyled>
            ) : (
              <FormElementStyled>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    mutateSap();
                  }}
                  disabled={
                    !checkAuthorisation(user[0].username!) || disableWholeForm
                  }
                >
                  <Refresh style={{ marginRight: '10px' }} /> Sap Abfrage
                </Fab>
              </FormElementStyled>
            )}
          </FormRow>
        </FormSection>

        <FormSection>
          <FormRow>
            <FormElementStyled>
              <Autocomplete
                disableClearable
                PopperComponent={SCPopper}
                style={{ width: '200px' }}
                options={
                  workflowData
                    ? workflowData.workflows.map(
                        (workflow) => workflow.workflowName
                      )
                    : []
                }
                renderOption={(option) => <>{option}</>}
                getOptionLabel={(option) => `${option}`}
                getOptionSelected={(option) =>
                  option && option === getValues('workflowType')
                }
                value={getValues('workflowType')}
                onChange={(_, option) => {
                  setValue('workflowType', option);
                }}
                autoHighlight
                autoSelect
                selectOnFocus
                renderInput={(params) => (
                  <TextField {...params} label="Workflows" />
                )}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('workflowStep')}
                value={getValues('workflowStep')}
                onChange={(event) =>
                  setValue('workflowStep', event.target.value)
                }
                label="Stufe"
                style={{ width: '50px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <Autocomplete
                PopperComponent={SCPopper}
                multiple
                options={
                  usersData?.users
                    ? getValues('acceptors')
                      ? [
                          ...usersData.users.map((value) => value.mail),
                          ...getValues('acceptors'),
                        ]
                      : [...usersData.users.map((value) => value.mail)]
                    : []
                }
                style={{ width: '400px' }}
                value={getValues('acceptors')}
                onChange={(_, options) => setValue('acceptors', options)}
                renderInput={(params) => (
                  <TextField {...params} label="Freigeber" />
                )}
                disabled={disableWholeForm}
              />
            </FormElementStyled>
            <FormElementStyled>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontSize: '0.7em', margin: '0px' }}>
                  Autoworkflow blockieren
                </p>
                <Checkbox
                  color="primary"
                  checked={watch('blockAutoWorkflow')}
                  size="small"
                  onChange={() => {
                    setValue('blockAutoWorkflow', !watch('blockAutoWorkflow'));
                  }}
                  disabled={
                    !checkAuthorisation(user[0].username!) || disableWholeForm
                  }
                />
              </div>
            </FormElementStyled>
          </FormRow>
        </FormSection>

        <FormSection>
          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('invoiceNr')}
                value={getValues('invoiceNr')}
                onChange={(event) => setValue('invoiceNr', event.target.value)}
                label="Rechnungsnummer"
                style={{ width: '150px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
              <InvoiceSelectModal
                disabled={
                  // eslint-disable-next-line no-unneeded-ternary
                  state.selectedInvoice?.creditorId ? false : true
                }
                label={{ id: 'invoiceNr', label: 'Rechnungsnummer' }}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                invoice={state.selectedInvoice!}
              />
            </FormElementStyled>
            <FormElementStyled>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Block style={{ fontSize: '0.7em', margin: '0px' }} />
                <Checkbox
                  color="primary"
                  checked={watch('blockZTerm')}
                  size="small"
                  onChange={() => {
                    setValue('blockZTerm', !watch('blockZTerm'));
                  }}
                  disabled={
                    !checkAuthorisation(user[0].username!) || disableWholeForm
                  }
                />
              </div>
            </FormElementStyled>
            <FormElementStyled>
              <Autocomplete
                disableClearable
                openOnFocus
                PopperComponent={SCPopper}
                style={{ width: '200px' }}
                options={
                  zTermsData ? zTermsData.zTerms.map((zTerm) => zTerm) : []
                }
                getOptionLabel={(option: ZTerm) =>
                  `${option.ZTERM} ${option.VTEXT}`
                }
                renderOption={(option) => (
                  <>
                    {option.ZTERM} {option.VTEXT}
                  </>
                )}
                getOptionSelected={(option) =>
                  option && option.ZTERM === getValues('zTerm').number
                }
                value={{
                  ZTERM: getValues('zTerm').number,
                  VTEXT: getValues('zTerm').text,
                }}
                onChange={(_, option) => {
                  setValue('zTerm', {
                    number: option.ZTERM,
                    text: option.VTEXT,
                  });
                }}
                autoHighlight
                autoSelect
                selectOnFocus
                renderInput={(params) => (
                  <TextField {...params} label="Zahlungsbedingungen" />
                )}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <Autocomplete
                disableClearable
                PopperComponent={SCPopper}
                options={lockOptions.map((value) => value)}
                style={{ width: '110px' }}
                value={getValues('lock')}
                onChange={(_, options: string) => setValue('lock', options)}
                renderInput={(params) => (
                  <TextField {...params} label="Sperrvermerk" />
                )}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('customText')}
                value={getValues('customText')}
                onChange={(event) => setValue('customText', event.target.value)}
                label="Textfeld"
                style={{ width: '150px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
                error={errors.customText}
                helperText={errors.customText && 'Zu viele Zeichen'}
              />
            </FormElementStyled>
          </FormRow>
          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('invoiceDate')}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={
                  getValues('invoiceDate')
                    ? new Date(Number.parseInt(getValues('invoiceDate'), 10))
                        .toISOString()
                        .slice(0, 10)
                    : ''
                }
                onChange={(event: any) => {
                  if (event.target.value.length > 0) {
                    setValue(
                      'invoiceDate',
                      new Date(event.target.value).getTime().toString()
                    );
                  }
                }}
                label="Rechnungsdatum"
                style={{ width: '200px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('bookingDate')}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={new Date(Number.parseInt(getValues('bookingDate'), 10))
                  .toISOString()
                  .slice(0, 10)}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setValue(
                      'bookingDate',
                      new Date(event.target.value).getTime().toString()
                    );
                  }
                }}
                label="Buchnungsdatum"
                style={{ width: '200px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('startSkontoDate')}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={new Date(
                  Number.parseInt(getValues('startSkontoDate'), 10)
                )
                  .toISOString()
                  .slice(0, 10)}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setValue(
                      'startSkontoDate',
                      new Date(event.target.value).getTime().toString()
                    );
                  }
                }}
                label="Basisdatum"
                style={{ width: '200px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
          </FormRow>

          <FormRow>
            <FormElementStyled>
              <TextField
                {...register('currency')}
                value={getValues('currency') ? getValues('currency') : 'EUR'}
                onChange={(event) => setValue('currency', event.target.value)}
                label="Währung"
                style={{ width: '80px' }}
                select
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              >
                {currenciesData.map((value) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormElementStyled>
            <FormElementStyled>
              <BruttoTextfield
                {...register('grossTotal')}
                disabled
                value={watch('grossTotal')}
                onChange={(event) => setValue('grossTotal', event.target.value)}
                label="Brutto berechnet"
                InputLabelProps={{ shrink: true }}
              />
            </FormElementStyled>
            <FormElementStyled>
              <BruttoInvoiceTextfield
                {...register('grossTotalInvoice')}
                value={watch('grossTotalInvoice')}
                onChange={(event) =>
                  setValue(
                    'grossTotalInvoice',
                    event.target.value.replace(',', '.')
                  )
                }
                label="Brutto Rechnung"
                InputLabelProps={{ shrink: true }}
                customColor={
                  getValues('grossTotal') === getValues('grossTotalInvoice')
                    ? 'green'
                    : 'red'
                }
                error={errors.grossTotalInvoice}
                helperText={errors.grossTotalInvoice && 'Feld ist erforderlich'}
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('seperateCosts')}
                value={getValues('seperateCosts')}
                onChange={(event) => {
                  setValue(
                    'seperateCosts',
                    event.target.value.replace(',', '.')
                  );
                  setCalc(true);
                }}
                label="Ungepl. Nebenkosten"
                style={{ width: '140px' }}
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
            <FormElementStyled>
              <TextField
                {...register('skontoAmount')}
                value={getValues('skontoAmount') || ''}
                style={{ width: '140px' }}
                onChange={(event) => {
                  setValue(
                    'skontoAmount',
                    event.target.value.replace(',', '.')
                  );
                }}
                label="Skontobetrag"
                disabled={
                  !checkAuthorisation(user[0].username!) || disableWholeForm
                }
              />
            </FormElementStyled>
          </FormRow>
        </FormSection>

        {(isLoadingOrders || isLoadingCostcenters || isLoadingGlList) && (
          <FormElementStyled>
            <LinearProgress variant="determinate" value={0} />
          </FormElementStyled>
        )}
        {!isLoadingOrders &&
          getValues('sapType') === 'MM' &&
          (!ordersData || (ordersData && !ordersData.orders[0])) && (
            <p>Keine Bestellung vorhanden</p>
          )}
        {!isLoadingOrders &&
          getValues('sapType') === 'MM' &&
          ordersData &&
          ordersData.orders[0] &&
          checkAuthorisation(user[0].username!) && (
            <>
              <FormSection>
                <FormRow>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p style={{ fontSize: '0.7em', margin: '0px' }}>FI</p>
                    <Checkbox
                      color="primary"
                      checked={getValues('MmType')}
                      size="small"
                      onChange={() => {
                        setValue('MmType', !watch('MmType'));
                        setValue('allocNr', '');
                        setValue('positionSumFi', '');
                        setValue('account', null);
                        setValue('taxSign', null);
                        setValue('orderNrSelected', '');
                        setValue('positionSelected', '');
                        setValue('positionCountMm', '');
                        setValue('positionMaterialMm', '');
                        setValue('positionSumMm', '');
                        setValue('controllingType', 'Kostenstelle');
                        setValue('pspElement', '');
                        setValue('internal', '');
                      }}
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </div>
                  {getValues('MmType') === false && (
                    <>
                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '120px' }}
                          options={ordersData.orders
                            .map((value) => value)
                            .sort()}
                          getOptionLabel={(option: Order) =>
                            option.orderId || ''
                          }
                          getOptionSelected={(option, value: Order | '') => {
                            return option === value || value === '';
                          }}
                          value={getValues('orderNrSelected')}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(`orderNrSelected`, option);

                            if (option === null) {
                              setValue('positionSelected', '');
                              setValue('positionSumMm', '');
                              setValue('positionCountMm', '');
                              setValue('positionMaterialMm', '');
                              return;
                            }
                            setValue(
                              'positionSelected',
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              ordersData!.orders[
                                getIndexOfSelectedOrder(`orderNrSelected`)
                              ].positions[0]
                            );
                            setValue(
                              'positionSumMm',
                              calculateNettoByWareneingang(
                                ordersData!.orders[
                                  getIndexOfSelectedOrder(`orderNrSelected`)
                                ].positions[
                                  getIndexOfSelectedPosition(
                                    `orderNrSelected`,
                                    'positionSelected'
                                  )
                                ].MENGE,
                                (
                                  Number.parseFloat(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(`orderNrSelected`)
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `orderNrSelected`,
                                        'positionSelected'
                                      )
                                    ].WEMNG
                                  ) -
                                  Number.parseFloat(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(`orderNrSelected`)
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `orderNrSelected`,
                                        'positionSelected'
                                      )
                                    ].REMNG
                                  )
                                ).toString(),
                                ordersData!.orders[
                                  getIndexOfSelectedOrder(`orderNrSelected`)
                                ].positions[
                                  getIndexOfSelectedPosition(
                                    `orderNrSelected`,
                                    'positionSelected'
                                  )
                                ].NETWR
                              )
                            );
                            setValue(
                              'positionCountMm',
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              (
                                Number.parseFloat(
                                  ordersData!.orders[
                                    getIndexOfSelectedOrder(`orderNrSelected`)
                                  ].positions[0].WEMNG
                                ) -
                                Number.parseFloat(
                                  ordersData!.orders[
                                    getIndexOfSelectedOrder(`orderNrSelected`)
                                  ].positions[0].REMNG
                                )
                              ).toString()
                            );
                            setValue(
                              'positionMaterialMm',
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              ordersData!.orders[
                                getIndexOfSelectedOrder(`orderNrSelected`)
                              ].positions[0].MATNR
                            );
                            setCalc(true);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Bestellnummer" />
                          )}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>
                      <FormElementStyled>
                        <MaterialTextfield
                          {...register('positionMaterialMm')}
                          value={
                            getRidOfLeadingZeros(
                              getValues('positionMaterialMm')
                            ) || ''
                          }
                          style={{ width: '110px' }}
                          onChange={(event) => {
                            setValue(
                              'positionMaterialMm',
                              event.target.value.replace(',', '.')
                            );
                          }}
                          label="Materialnummer"
                          disabled
                        />
                      </FormElementStyled>
                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '70px' }}
                          options={ordersData.orders[
                            getIndexOfSelectedOrder(`orderNrSelected`)
                          ].positions.map((value) => value)}
                          getOptionLabel={(option: Position) =>
                            option ? getRidOfLeadingZeros(option.EBELP) : ''
                          }
                          getOptionSelected={(option, value: Position | '') => {
                            return option === value || value === '';
                          }}
                          value={getValues('positionSelected')}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue('positionSelected', option);
                            setValue(
                              'positionSumMm',
                              calculateNettoByWareneingang(
                                ordersData!.orders[
                                  getIndexOfSelectedOrder(`orderNrSelected`)
                                ].positions[
                                  getIndexOfSelectedPosition(
                                    `orderNrSelected`,
                                    'positionSelected'
                                  )
                                ].MENGE,
                                (
                                  Number.parseFloat(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(`orderNrSelected`)
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `orderNrSelected`,
                                        'positionSelected'
                                      )
                                    ].WEMNG
                                  ) -
                                  Number.parseFloat(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(`orderNrSelected`)
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `orderNrSelected`,
                                        'positionSelected'
                                      )
                                    ].REMNG
                                  )
                                ).toString(),
                                ordersData!.orders[
                                  getIndexOfSelectedOrder(`orderNrSelected`)
                                ].positions[
                                  getIndexOfSelectedPosition(
                                    `orderNrSelected`,
                                    'positionSelected'
                                  )
                                ].NETWR
                              )
                            );
                            setValue(
                              'positionCountMm',
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              (
                                Number.parseFloat(
                                  ordersData!.orders[
                                    getIndexOfSelectedOrder(`orderNrSelected`)
                                  ].positions[
                                    getIndexOfSelectedPosition(
                                      `orderNrSelected`,
                                      'positionSelected'
                                    )
                                  ].WEMNG
                                ) -
                                Number.parseFloat(
                                  ordersData!.orders[
                                    getIndexOfSelectedOrder(`orderNrSelected`)
                                  ].positions[
                                    getIndexOfSelectedPosition(
                                      `orderNrSelected`,
                                      'positionSelected'
                                    )
                                  ].REMNG
                                )
                              ).toString()
                            );
                            setValue(
                              'positionMaterialMm',
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              ordersData!.orders[
                                getIndexOfSelectedOrder(`orderNrSelected`)
                              ].positions[
                                getIndexOfSelectedPosition(
                                  `orderNrSelected`,
                                  'positionSelected'
                                )
                              ].MATNR
                            );
                            setCalc(true);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Position" />
                          )}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '60px' }}
                          options={taxSignsSap.map((value) => value)}
                          getOptionLabel={(option: Tax) =>
                            `${option.taxSign} ${option.text}`
                          }
                          renderOption={(option) => (
                            <>
                              {option.taxSign} {option.text}
                            </>
                          )}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          value={getValues('taxSign')}
                          onChange={(_, option) => {
                            setValue('taxSign', option);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Steuer" />
                          )}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '75px' }}
                          options={accounts.map((value) => value)}
                          getOptionLabel={(option) => `${option}`}
                          renderOption={(option) => <>{option}</>}
                          value={getValues(`account`)}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(`account`, option);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Konto" />
                          )}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>
                      {/* <FormElementStyled>
                      <p>ST</p>
                    </FormElementStyled> */}

                      <FormElementStyled>
                        <TextField
                          {...register('positionCountMm')}
                          value={getValues('positionCountMm') || ''}
                          style={{ width: '80px' }}
                          onChange={(event) =>
                            setValue(
                              'positionCountMm',
                              event.target.value.replace(',', '.')
                            )
                          }
                          label="Menge"
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>
                      <FormElementStyled>
                        <TextField
                          {...register('positionSumMm')}
                          value={getValues('positionSumMm') || ''}
                          style={{ width: '80px' }}
                          onChange={(event) => {
                            setValue(
                              'positionSumMm',
                              event.target.value.replace(',', '.')
                            );
                          }}
                          label="Nettobetrag"
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      <FormElementStyled>
                        <Fab
                          style={{ marginTop: '5px' }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            setAddPosition(true);
                          }}
                        >
                          <Add />
                        </Fab>
                      </FormElementStyled>
                    </>
                  )}
                  {getValues('MmType') === true &&
                    !isLoadingGlList &&
                    !isLoadingCostcenters && (
                      <>
                        {' '}
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '90px' }}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            options={glListData!.glList.map((value) => value)}
                            getOptionLabel={(option: GlList) =>
                              `${getRidOfLeadingZeros(option.GlAccount)}${' '}
                              ${option.LongText}`
                            }
                            renderOption={(option) => (
                              <>
                                {getRidOfLeadingZeros(option.GlAccount)}{' '}
                                {option.LongText}
                              </>
                            )}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue('GlAccount', option);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Sachkonto" />
                            )}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <Autocomplete
                            disabled
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '90px' }}
                            options={controllingTypes.map((value) => value)}
                            getOptionLabel={(option: string) => `${option}`}
                            renderOption={(option) => <>{option}</>}
                            value={getValues('controllingType')}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue('controllingType', option);
                              setValue('Costcenter', null);
                              setValue('internal', '');
                              setValue('pspElement', '');
                              setFocusKostenträger(true);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Kostenträger" />
                            )}
                          />
                        </FormElementStyled>
                        {getValues('controllingType') === 'Kostenstelle' && (
                          <FormElementStyled>
                            <Autocomplete
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '90px' }}
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              options={costcenterData!.costcenter.map(
                                (value) => value
                              )}
                              getOptionLabel={(option: Costcenter) =>
                                `${getRidOfLeadingZeros(option.Costcenter)}`
                              }
                              renderOption={(option) => (
                                <>
                                  {getRidOfLeadingZeros(option.Costcenter)}{' '}
                                  {option.CocntrTxt}
                                </>
                              )}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue('Costcenter', option);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoFocus={focusKostenträger}
                                  id="next"
                                  label="Kostenstelle"
                                />
                              )}
                              disabled={disableWholeForm}
                            />
                          </FormElementStyled>
                        )}
                        {getValues('controllingType') === 'PSP-Element' && (
                          <FormElementStyled>
                            <TextField
                              autoFocus={focusKostenträger}
                              id="next"
                              {...register('pspElement')}
                              value={getValues('pspElement')}
                              onChange={(event) => {
                                setValue('pspElement', event.target.value);
                              }}
                              style={{ width: '90px' }}
                              label="PSP-Element"
                              error={errors.pspElement}
                              helperText={
                                errors.pspElement && 'Feld ist erforderlich'
                              }
                            />
                          </FormElementStyled>
                        )}
                        {getValues('controllingType') === 'Innenauftrag' && (
                          <FormElementStyled>
                            <TextField
                              autoFocus={focusKostenträger}
                              id="next"
                              {...register('internal')}
                              value={getValues('internal')}
                              onChange={(event) => {
                                setValue('internal', event.target.value);
                              }}
                              style={{ width: '90px' }}
                              label="Innenauftrag"
                            />
                          </FormElementStyled>
                        )}
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '60px' }}
                            options={
                              taxSignsSap && taxSignsSap.map((value) => value)
                            }
                            getOptionLabel={(option: Tax) =>
                              `${option.taxSign} ${option.text}`
                            }
                            renderOption={(option) => (
                              <>
                                {option.taxSign} {option.text}
                              </>
                            )}
                            value={getValues('taxSign')}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue('taxSign', option);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Steuer" />
                            )}
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '75px' }}
                            options={accounts.map((value) => value)}
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => <>{option}</>}
                            getOptionSelected={(
                              option,
                              value: string | null
                            ) => {
                              return option === value || value === null;
                            }}
                            value={getValues(`account`)}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(`account`, option);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Konto" />
                            )}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <TextField
                            {...register('allocNr')}
                            value={getValues('allocNr')}
                            style={{ width: '80px' }}
                            onChange={(event) =>
                              setValue('allocNr', event.target.value)
                            }
                            label="Zuordnung"
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <TextField
                            {...register('positionSumFi')}
                            value={getValues('positionSumFi')}
                            style={{ width: '80px' }}
                            onChange={(event) => {
                              setValue(
                                'positionSumFi',
                                event.target.value.replace(',', '.')
                              );
                            }}
                            label="Nettobetrag"
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <Fab
                            style={{ marginTop: '5px' }}
                            size="small"
                            color="primary"
                            onClick={() => {
                              setAddPosition(true);
                            }}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          >
                            <Add />
                          </Fab>
                        </FormElementStyled>
                      </>
                    )}
                </FormRow>
              </FormSection>
            </>
          )}
        {!isLoadingGlList &&
          !isLoadingCostcenters &&
          getValues('sapType') === 'FI' &&
          checkAuthorisation(user[0].username!) && (
            <>
              <FormSection>
                <FormRow>
                  <FormElementStyled>
                    <Autocomplete
                      disableClearable
                      openOnFocus
                      PopperComponent={SCPopper}
                      style={{ width: '90px' }}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      options={glListData!.glList.map((value) => value)}
                      getOptionLabel={(option: GlList) =>
                        `${getRidOfLeadingZeros(option.GlAccount)}${' '}
                        ${option.LongText}`
                      }
                      renderOption={(option) => (
                        <>
                          {getRidOfLeadingZeros(option.GlAccount)}{' '}
                          {option.LongText}
                        </>
                      )}
                      autoHighlight
                      autoSelect
                      selectOnFocus
                      onChange={(_, option) => {
                        setValue('GlAccount', option);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Sachkonto" />
                      )}
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </FormElementStyled>

                  <FormElementStyled>
                    <Autocomplete
                      disableClearable
                      openOnFocus
                      PopperComponent={SCPopper}
                      style={{ width: '80px' }}
                      options={controllingTypes.map((value) => value)}
                      getOptionLabel={(option: string) => `${option}`}
                      renderOption={(option) => <>{option}</>}
                      value={getValues('controllingType')}
                      autoHighlight
                      autoSelect
                      selectOnFocus
                      onChange={(_, option) => {
                        setValue('controllingType', option);
                        setValue('Costcenter', null);
                        setValue('internal', '');
                        setValue('pspElement', '');
                        setFocusKostenträger(true);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Kostenträger" />
                      )}
                      disabled={disableWholeForm}
                    />
                  </FormElementStyled>

                  {getValues('controllingType') === 'Kostenstelle' && (
                    <FormElementStyled>
                      <Autocomplete
                        disableClearable
                        openOnFocus
                        PopperComponent={SCPopper}
                        style={{ width: '130px' }}
                        options={costcenterData!.costcenter.map(
                          (value) => value && value
                        )}
                        getOptionLabel={(option: Costcenter) =>
                          `${getRidOfLeadingZeros(option.Costcenter)} 
                              ${option.CocntrTxt}`
                        }
                        renderOption={(option) => (
                          <>
                            {getRidOfLeadingZeros(option.Costcenter)}{' '}
                            {option.CocntrTxt}
                          </>
                        )}
                        autoHighlight
                        autoSelect
                        selectOnFocus
                        onChange={(_, option) => {
                          setValue('Costcenter', option);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            autoFocus={focusKostenträger}
                            id="next"
                            label="Kostenstelle"
                          />
                        )}
                        disabled={disableWholeForm}
                      />
                    </FormElementStyled>
                  )}

                  {getValues('controllingType') === 'PSP-Element' && (
                    <FormElementStyled>
                      <TextField
                        autoFocus={focusKostenträger}
                        id="next"
                        {...register('pspElement')}
                        value={getValues('pspElement')}
                        onChange={(event) =>
                          setValue('pspElement', event.target.value)
                        }
                        style={{ width: '130px' }}
                        label="PSP-Element"
                        inputProps={{
                          style: { height: '1.6em', fontSize: '0.75em' },
                        }}
                        error={errors.pspElement}
                        helperText={
                          errors.pspElement && 'Feld falsch formatiert'
                        }
                      />
                    </FormElementStyled>
                  )}

                  {getValues('controllingType') === 'Innenauftrag' && (
                    <FormElementStyled>
                      <TextField
                        autoFocus={focusKostenträger}
                        id="next"
                        {...register('internal')}
                        value={getValues('internal')}
                        onChange={(event) =>
                          setValue('internal', event.target.value)
                        }
                        style={{ width: '130px' }}
                        label="Innenauftrag"
                      />
                    </FormElementStyled>
                  )}

                  <FormElementStyled>
                    <Autocomplete
                      disableClearable
                      openOnFocus
                      PopperComponent={SCPopper}
                      style={{ width: '60px' }}
                      options={taxSignsSap && taxSignsSap.map((value) => value)}
                      getOptionLabel={(option: Tax) =>
                        `${option.taxSign} ${option.text}`
                      }
                      renderOption={(option) => (
                        <>
                          {option.taxSign} {option.text}
                        </>
                      )}
                      autoHighlight
                      autoSelect
                      selectOnFocus
                      onChange={(_, option) => {
                        setValue('taxSign', option);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Steuer" />
                      )}
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </FormElementStyled>
                  <FormElementStyled>
                    <Autocomplete
                      disableClearable
                      openOnFocus
                      PopperComponent={SCPopper}
                      style={{ width: '75px' }}
                      options={accounts.map((value) => value)}
                      getOptionLabel={(option: string) => `${option}`}
                      renderOption={(option) => option}
                      value={getValues(`account`)}
                      autoHighlight
                      autoSelect
                      selectOnFocus
                      onChange={(_, option) => {
                        setValue(`account`, option);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Konto" />
                      )}
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </FormElementStyled>
                  <FormElementStyled>
                    <TextField
                      {...register('allocNr')}
                      value={getValues('allocNr')}
                      style={{ width: '80px' }}
                      onChange={(event) =>
                        setValue('allocNr', event.target.value)
                      }
                      label="Zuordnung"
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </FormElementStyled>

                  <FormElementStyled>
                    <TextField
                      {...register('positionSumFi')}
                      value={getValues('positionSumFi')}
                      style={{ width: '80px' }}
                      onChange={(event) => {
                        setValue(
                          'positionSumFi',
                          event.target.value.replace(',', '.')
                        );
                      }}
                      label="Nettobetrag"
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    />
                  </FormElementStyled>
                  <FormElementStyled>
                    <Fab
                      style={{ marginTop: '5px' }}
                      size="small"
                      color="primary"
                      onClick={() => {
                        setAddPosition(true);
                      }}
                      disabled={
                        !checkAuthorisation(user[0].username!) ||
                        disableWholeForm
                      }
                    >
                      <Add />
                    </Fab>
                  </FormElementStyled>
                </FormRow>
              </FormSection>
            </>
          )}
        {checkAuthorisation(user[0].username!) && watch('sapType') === 'MM' && (
          <FormSection>
            <FormRow
              style={{
                marginTop: '0px',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <p
                style={{
                  fontSize: '0.7em',
                }}
              >
                Manuelle Eingabe MM Positionen
              </p>
              {!showManual && (
                <ArrowDownward
                  style={{ marginLeft: 15, cursor: 'pointer', height: 15 }}
                  onClick={() => setShowManual(true)}
                />
              )}
              {showManual && (
                <ArrowUpward
                  style={{ marginLeft: 15, cursor: 'pointer', height: 15 }}
                  onClick={() => setShowManual(false)}
                />
              )}
            </FormRow>
            {showManual && (
              <FormRow>
                <FormElementStyled>
                  <TextField
                    {...register('positionManualOrderNr')}
                    style={{ width: 120 }}
                    label="Bestellnummer"
                    value={getValues('positionManualOrderNr')?.orderId || ''}
                    onChange={(event) => {
                      setValue('positionManualOrderNr', {
                        creditorId: '',
                        orderId: event.target.value,
                        positions: [],
                      });
                    }}
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <TextField
                    {...register('positionManualPosition')}
                    style={{ width: 60 }}
                    label="Position"
                    value={getValues('positionManualPosition')?.EBELP || ''}
                    onChange={(event) => {
                      setValue('positionManualPosition', {
                        BEDAT: '',
                        EBELN: '',
                        EBELP: event.target.value,
                        NETWR: '',
                        MENGE: '',
                        WEMNG: '',
                      });
                    }}
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <Autocomplete
                    disableClearable
                    openOnFocus
                    PopperComponent={SCPopper}
                    style={{ width: '60px' }}
                    options={taxSignsSap && taxSignsSap.map((value) => value)}
                    getOptionLabel={(option: Tax) =>
                      `${option.taxSign} ${option.text}`
                    }
                    renderOption={(option) => (
                      <>
                        {option.taxSign} {option.text}
                      </>
                    )}
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    onChange={(_, option) => {
                      setValue('taxSignManual', option);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Steuer" />
                    )}
                    disabled={
                      !checkAuthorisation(user[0].username!) || disableWholeForm
                    }
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <Autocomplete
                    disableClearable
                    openOnFocus
                    PopperComponent={SCPopper}
                    style={{ width: '75px' }}
                    options={accounts.map((value) => value)}
                    getOptionLabel={(option: string) => `${option}`}
                    renderOption={(option) => option}
                    value={getValues(`accountManual`)}
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    onChange={(_, option) => {
                      setValue(`accountManual`, option);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Konto" />
                    )}
                    disabled={
                      !checkAuthorisation(user[0].username!) || disableWholeForm
                    }
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <TextField
                    style={{ width: 100 }}
                    label="Menge"
                    {...register('positionManualCount')}
                    value={getValues('positionManualCount')}
                    onChange={(event) =>
                      setValue('positionManualCount', event.target.value)
                    }
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <TextField
                    style={{ width: 50 }}
                    label="Einheit"
                    {...register('positionManualUnit')}
                    value={getValues('positionManualUnit')}
                    onChange={(event) =>
                      setValue('positionManualUnit', event.target.value)
                    }
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <TextField
                    style={{ width: 80 }}
                    label="Nettobetrag"
                    {...register('positionManualSum')}
                    value={getValues('positionManualSum')}
                    onChange={(event) =>
                      setValue(
                        'positionManualSum',
                        event.target.value.replace(',', '.')
                      )
                    }
                  />
                </FormElementStyled>
                <FormElementStyled>
                  <Fab
                    style={{ marginTop: '5px' }}
                    size="small"
                    color="primary"
                    onClick={() => {
                      setAddManualPosition(true);
                    }}
                  >
                    <Add />
                  </Fab>
                </FormElementStyled>
              </FormRow>
            )}
          </FormSection>
        )}

        {((watch('positions').length > 0 && watch('sapType') === 'MM') ||
          (watch('positionsFi').length > 0 && watch('sapType') === 'FI')) && (
          <FormSection>
            {watch('positions').length > 0 && watch('sapType') === 'MM' && (
              <>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                    }}
                  >
                    MM Positionen
                  </p>
                </FormRow>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '120px',
                      marginTop: '0px',
                    }}
                  >
                    Bestellnummer
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '70px',
                      marginTop: '0px',
                    }}
                  >
                    Position
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Steuer
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Konto
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '100px',
                      marginTop: '0px',
                    }}
                  >
                    Menge
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '100px',
                      marginTop: '0px',
                    }}
                  >
                    Nettobetrag
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '50px',
                      marginTop: '0px',
                    }}
                  >
                    Löschen
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '30px',
                      marginTop: '0px',
                    }}
                  >
                    .
                  </p>
                </FormRow>
              </>
            )}
            {!isLoadingGlList &&
              !isLoadingCostcenters &&
              getValues('sapType') === 'MM' &&
              fieldsMm &&
              fieldsMm.map(
                (
                  field: Partial<ArrayField<InvoicePosition, 'id'>>,
                  index: number
                ) => {
                  if (field.MmType === false) {
                    register(`positions[${index}].OrderNrSelected`);
                    register(`positions[${index}].PositionSelected`);
                    register(`positions[${index}].taxSign`);
                    register(`positions[${index}].count`);
                    register(`positions[${index}].sum`);
                    register(`positions[${index}].MmType`);
                    register(`positions[${index}].account`);
                    register(`positions[${index}].taged`);

                    if (
                      !getValues(`positions[${index}].OrderNrSelected`) &&
                      field.OrderNrSelected
                    ) {
                      setValue(
                        `positions[${index}].OrderNrSelected`,
                        field.OrderNrSelected
                      );
                    }
                    if (
                      !getValues(`positions[${index}].PositionSelected`) &&
                      field.PositionSelected
                    ) {
                      setValue(
                        `positions[${index}].PositionSelected`,
                        field.PositionSelected
                      );
                    }
                    if (
                      !getValues(`positions[${index}].taxSign`) &&
                      field.taxSign
                    ) {
                      setValue(`positions[${index}].taxSign`, field.taxSign);
                    }
                    if (!getValues(`positions[${index}].sum`) && field.sum) {
                      setValue(`positions[${index}].sum`, field.sum);
                    }
                    if (
                      !getValues(`positions[${index}].count`) &&
                      field.count
                    ) {
                      setValue(`positions[${index}].count`, field.count);
                    }
                    if (
                      getValues(`positions[${index}].MmType`) === undefined &&
                      field.MmType !== undefined
                    ) {
                      setValue(`positions[${index}].MmType`, field.MmType);
                    }
                    if (
                      !getValues(`positions[${index}].account`) &&
                      field.account
                    ) {
                      setValue(`positions[${index}].account`, field.account);
                    }
                    if (
                      getValues(`positions[${index}].taged`) === undefined &&
                      field.taged !== undefined
                    ) {
                      setValue(`positions[${index}].taged`, field.taged);
                    }

                    return (
                      <Tooltip title="Hallo">
                        <FormRow
                          style={{
                            marginTop: '0px',
                            paddingLeft: '3px',
                            borderRadius: '3px',
                            background:
                              getValues(`positions[${index}].taged`) &&
                              'indianred',
                          }}
                          key={field.id}
                        >
                          <FormElementStyled>
                            <Autocomplete
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '120px' }}
                              options={
                                ordersData?.orders
                                  .map((value) => value)
                                  .sort() || []
                              }
                              getOptionLabel={(option: Order) => option.orderId}
                              value={getValues(
                                `positions[${index}].OrderNrSelected`
                              )}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue(
                                  `positions[${index}].OrderNrSelected`,
                                  option
                                );
                                if (option === null) {
                                  setValue(
                                    `positions[${index}].PositionSelected`,
                                    ''
                                  );
                                  setValue(`positions[${index}].sum`, '');
                                  setValue(`positions[${index}].count`, '');
                                  return;
                                }
                                setValue(
                                  `positions[${index}].PositionSelected`,
                                  ordersData!.orders[
                                    getIndexOfSelectedOrder(
                                      `positions[${index}].OrderNrSelected`
                                    )
                                  ].positions[0]
                                );
                                setValue(
                                  `positions[${index}].sum`,
                                  calculateNettoByWareneingang(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(
                                        `positions[${index}].OrderNrSelected`
                                      )
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `positions[${index}].OrderNrSelected`,
                                        `positions[${index}].PositionSelected`
                                      )
                                    ].MENGE,
                                    (
                                      Number.parseFloat(
                                        ordersData!.orders[
                                          getIndexOfSelectedOrder(
                                            `positions[${index}].OrderNrSelected`
                                          )
                                        ].positions[
                                          getIndexOfSelectedPosition(
                                            `positions[${index}].OrderNrSelected`,
                                            `positions[${index}].PositionSelected`
                                          )
                                        ].WEMNG
                                      ) -
                                      Number.parseFloat(
                                        ordersData!.orders[
                                          getIndexOfSelectedOrder(
                                            `positions[${index}].OrderNrSelected`
                                          )
                                        ].positions[
                                          getIndexOfSelectedPosition(
                                            `positions[${index}].OrderNrSelected`,
                                            `positions[${index}].PositionSelected`
                                          )
                                        ].REMNG
                                      )
                                    ).toString(),
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(
                                        `positions[${index}].OrderNrSelected`
                                      )
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `positions[${index}].OrderNrSelected`,
                                        `positions[${index}].PositionSelected`
                                      )
                                    ].NETWR
                                  )
                                );
                                setValue(
                                  `positions[${index}].count`,
                                  (
                                    Number.parseFloat(
                                      ordersData!.orders[
                                        getIndexOfSelectedOrder(
                                          `positions[${index}].OrderNrSelected`
                                        )
                                      ].positions[0].WEMNG
                                    ) -
                                    Number.parseFloat(
                                      ordersData!.orders[
                                        getIndexOfSelectedOrder(
                                          `positions[${index}].OrderNrSelected`
                                        )
                                      ].positions[0].REMNG
                                    )
                                  ).toString()
                                );
                                setCalc(true);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          <FormElementStyled>
                            <Autocomplete
                              id={`position${index}`}
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '70px' }}
                              options={
                                ordersData?.orders[
                                  getIndexOfSelectedOrder(
                                    `positions[${index}].OrderNrSelected`
                                  )
                                ]?.positions.map((value) => {
                                  return value;
                                }) || []
                              }
                              getOptionLabel={(option: Position) =>
                                option ? getRidOfLeadingZeros(option.EBELP) : ''
                              }
                              value={getValues(
                                `positions[${index}].PositionSelected`
                              )}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue(
                                  `positions[${index}].PositionSelected`,
                                  option
                                );
                                setValue(
                                  `positions[${index}].sum`,
                                  calculateNettoByWareneingang(
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(
                                        `positions[${index}].OrderNrSelected`
                                      )
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `positions[${index}].OrderNrSelected`,
                                        `positions[${index}].PositionSelected`
                                      )
                                    ].MENGE,
                                    (
                                      Number.parseFloat(
                                        ordersData!.orders[
                                          getIndexOfSelectedOrder(
                                            `positions[${index}].OrderNrSelected`
                                          )
                                        ].positions[
                                          getIndexOfSelectedPosition(
                                            `positions[${index}].OrderNrSelected`,
                                            `positions[${index}].PositionSelected`
                                          )
                                        ].WEMNG
                                      ) -
                                      Number.parseFloat(
                                        ordersData!.orders[
                                          getIndexOfSelectedOrder(
                                            `positions[${index}].OrderNrSelected`
                                          )
                                        ].positions[
                                          getIndexOfSelectedPosition(
                                            `positions[${index}].OrderNrSelected`,
                                            `positions[${index}].PositionSelected`
                                          )
                                        ].REMNG
                                      )
                                    ).toString(),
                                    ordersData!.orders[
                                      getIndexOfSelectedOrder(
                                        `positions[${index}].OrderNrSelected`
                                      )
                                    ].positions[
                                      getIndexOfSelectedPosition(
                                        `positions[${index}].OrderNrSelected`,
                                        `positions[${index}].PositionSelected`
                                      )
                                    ].NETWR
                                  )
                                );
                                setValue(
                                  `positions[${index}].count`,
                                  (
                                    Number.parseFloat(
                                      ordersData!.orders[
                                        getIndexOfSelectedOrder(
                                          `positions[${index}].OrderNrSelected`
                                        )
                                      ].positions[
                                        getIndexOfSelectedPosition(
                                          `positions[${index}].OrderNrSelected`,
                                          `positions[${index}].PositionSelected`
                                        )
                                      ].WEMNG
                                    ) -
                                    Number.parseFloat(
                                      ordersData!.orders[
                                        getIndexOfSelectedOrder(
                                          `positions[${index}].OrderNrSelected`
                                        )
                                      ].positions[
                                        getIndexOfSelectedPosition(
                                          `positions[${index}].OrderNrSelected`,
                                          `positions[${index}].PositionSelected`
                                        )
                                      ].REMNG
                                    )
                                  ).toString()
                                );
                                setCalc(true);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          <FormElementStyled>
                            <Autocomplete
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '60px' }}
                              options={
                                taxSignsSap && taxSignsSap.map((value) => value)
                              }
                              getOptionLabel={(option: Tax) =>
                                `${option.taxSign} ${option.text}`
                              }
                              renderOption={(option) => (
                                <>
                                  {option.taxSign} {option.text}
                                </>
                              )}
                              value={getValues(`positions[${index}].taxSign`)}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue(`positions[${index}].taxSign`, option);
                                setCalc(true);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          <FormElementStyled>
                            <Autocomplete
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '60px' }}
                              options={accounts.map((value) => value)}
                              getOptionLabel={(option) => `${option}`}
                              renderOption={(option) => <>{option}</>}
                              value={getValues(`positions[${index}].account`)}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue(`positions[${index}].account`, option);
                                setCalc(true);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          <FormElementStyled>
                            <TextField
                              {...register(`positions[${index}].count`)}
                              value={
                                getValues(`positions[${index}].count`) || ''
                              }
                              style={{ width: '100px' }}
                              onChange={(event) =>
                                setValue(
                                  `positions[${index}].count`,
                                  event.target.value.replace(',', '.')
                                )
                              }
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          <FormElementStyled>
                            <TextField
                              {...register(`positions[${index}].sum`)}
                              value={getValues(`positions[${index}].sum`) || ''}
                              style={{ width: '100px' }}
                              onChange={(event) => {
                                setValue(
                                  `positions[${index}].sum`,
                                  event.target.value.replace(',', '.')
                                );
                                setCalc(true);
                              }}
                              disabled={
                                !checkAuthorisation(user[0].username!) ||
                                disableWholeForm
                              }
                            />
                          </FormElementStyled>
                          {!disableWholeForm &&
                          checkAuthorisation(user[0].username!) ? (
                            <DeleteForever
                              style={{
                                cursor: 'pointer',
                                width: '50px',
                                color: 'primary',
                              }}
                              key={Math.random()}
                              onClick={() => {
                                removePositionByIndex(index);
                              }}
                            />
                          ) : (
                            <DeleteForever
                              style={{
                                width: '50px',
                                color: 'grey',
                              }}
                              key={Math.random()}
                            />
                          )}
                          <FormElementStyled>
                            <Checkbox
                              {...register(`positions[${index}].taged`)}
                              style={{
                                width: 30,
                                transform: `translate(-5px, -5px)`,
                              }}
                              defaultChecked={getValues(
                                `positions[${index}].taged`
                              )}
                              size="small"
                              onChange={(event) => {
                                setValue(
                                  `positions[${index}].taged`,
                                  event.target.checked
                                );
                              }}
                            />
                          </FormElementStyled>
                        </FormRow>
                      </Tooltip>
                    );
                  }
                  if (field.MmType === true) {
                    return undefined;
                  }
                  return undefined;
                }
              )}

            {watch('positions').length > 0 && watch('sapType') === 'MM' && (
              <>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                    }}
                  >
                    FI Positionen
                  </p>
                </FormRow>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '90px',
                      marginTop: '0px',
                    }}
                  >
                    Sachkonto
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '100px',
                      marginTop: '0px',
                    }}
                  >
                    Kostenträger
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '130px',
                      marginTop: '0px',
                    }}
                  >
                    {' '}
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Steuer
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Konto
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '80px',
                      marginTop: '0px',
                    }}
                  >
                    Zuordnung
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '90px',
                      marginTop: '0px',
                    }}
                  >
                    Nettobetrag
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '50px',
                      marginTop: '0px',
                    }}
                  >
                    Löschen
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '30px',
                      marginTop: '0px',
                    }}
                  >
                    .
                  </p>
                </FormRow>
              </>
            )}

            {ordersData &&
              !isLoadingGlList &&
              !isLoadingCostcenters &&
              getValues('sapType') === 'MM' &&
              fieldsMm &&
              fieldsMm.map(
                (
                  field: Partial<ArrayField<InvoicePosition, 'id'>>,
                  index: number
                ) => {
                  if (field.MmType === false) {
                    return undefined;
                  }
                  if (field.MmType === true) {
                    register(`positions[${index}].GlAccount`);
                    register(`positions[${index}].controllingType`);
                    register(`positions[${index}].Costcenter`);
                    register(`positions[${index}].internal`);
                    register(`positions[${index}].pspElement`);
                    register(`positions[${index}].taxSign`);
                    register(`positions[${index}].sum`);
                    register(`positions[${index}].allocNr`);
                    register(`positions[${index}].MmType`);
                    register(`positions[${index}].account`);
                    register(`positions[${index}].taged`);

                    if (
                      !getValues(`positions[${index}].GlAccount`) &&
                      field.GlAccount
                    ) {
                      setValue(
                        `positions[${index}].GlAccount`,
                        field.GlAccount
                      );
                    }
                    if (
                      !getValues(`positions[${index}].controllingType`) &&
                      field.controllingType
                    ) {
                      setValue(
                        `positions[${index}].controllingType`,
                        field.controllingType
                      );
                    }
                    if (
                      !getValues(`positions[${index}].Costcenter`) &&
                      field.Costcenter &&
                      getValues(`positions[${index}].controllingType`) ===
                        'Kostenstelle'
                    ) {
                      setValue(
                        `positions[${index}].Costcenter`,
                        field.Costcenter
                      );
                    }
                    if (
                      !getValues(`positions[${index}].internal`) &&
                      field.internal &&
                      getValues(`positions[${index}].controllingType`) ===
                        'Innenauftrag'
                    ) {
                      setValue(`positions[${index}].internal`, field.internal);
                    }
                    if (
                      !getValues(`positions[${index}].pspElement`) &&
                      field.pspElement &&
                      getValues(`positions[${index}].controllingType`) ===
                        'PSP-Element'
                    ) {
                      setValue(
                        `positions[${index}].pspElement`,
                        field.pspElement
                      );
                    }
                    if (
                      !getValues(`positions[${index}].taxSign`) &&
                      field.taxSign
                    ) {
                      setValue(`positions[${index}].taxSign`, field.taxSign);
                    }
                    if (!getValues(`positions[${index}].sum`) && field.sum) {
                      setValue(`positions[${index}].sum`, field.sum);
                    }
                    if (
                      !getValues(`positions[${index}].allocNr`) &&
                      field.allocNr
                    ) {
                      setValue(`positions[${index}].allocNr`, field.allocNr);
                    }
                    if (
                      getValues(`positions[${index}].MmType`) === undefined &&
                      field.GlAccount !== undefined
                    ) {
                      setValue(`positions[${index}].MmType`, field.MmType);
                    }
                    if (
                      !getValues(`positions[${index}].account`) &&
                      field.account
                    ) {
                      setValue(`positions[${index}].account`, field.account);
                    }
                    if (
                      getValues(`positions[${index}].taged`) === undefined &&
                      field.taged !== undefined
                    ) {
                      setValue(`positions[${index}].taged`, field.taged);
                    }

                    return (
                      <FormRow
                        style={{
                          marginTop: '0px',
                          paddingLeft: '3px',
                          borderRadius: '3px',
                          background:
                            getValues(`positions[${index}].taged`) &&
                            'indianred',
                        }}
                        key={field.id}
                      >
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '90px' }}
                            options={glListData!.glList.map((value) => value)}
                            getOptionLabel={(option: GlList) =>
                              `${getRidOfLeadingZeros(option.GlAccount)}${' '}
                              ${option.LongText}`
                            }
                            renderOption={(option) => (
                              <>
                                {getRidOfLeadingZeros(option.GlAccount)}{' '}
                                {option.LongText}
                              </>
                            )}
                            value={getValues(`positions[${index}].GlAccount`)}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(`positions[${index}].GlAccount`, option);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>

                        <FormElementStyled>
                          <Autocomplete
                            disabled
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '100px' }}
                            options={controllingTypes.map((value) => value)}
                            getOptionLabel={(option: string) => `${option}`}
                            renderOption={(option) => <>{option}</>}
                            value={getValues(
                              `positions[${index}].controllingType`
                            )}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(
                                `positions[${index}].controllingType`,
                                option
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </FormElementStyled>

                        {getValues(`positions[${index}].controllingType`) ===
                          'Kostenstelle' && (
                          <FormElementStyled>
                            <Autocomplete
                              disableClearable
                              openOnFocus
                              PopperComponent={SCPopper}
                              style={{ width: '130px' }}
                              options={costcenterData!.costcenter.map(
                                (value) => value && value
                              )}
                              getOptionLabel={(option: Costcenter) =>
                                `${getRidOfLeadingZeros(option.Costcenter)} 
                              ${option.CocntrTxt}`
                              }
                              renderOption={(option) => (
                                <>
                                  {getRidOfLeadingZeros(option.Costcenter)}{' '}
                                  {option.CocntrTxt}
                                </>
                              )}
                              value={getValues(
                                `positions[${index}].Costcenter`
                              )}
                              autoHighlight
                              autoSelect
                              selectOnFocus
                              onChange={(_, option) => {
                                setValue(
                                  `positions[${index}].Costcenter`,
                                  option
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </FormElementStyled>
                        )}

                        {getValues(`positions[${index}].controllingType`) ===
                          'PSP-Element' && (
                          <FormElementStyled>
                            <TextField
                              {...register(`positions[${index}].pspElement`)}
                              value={getValues(
                                `positions[${index}].pspElement`
                              )}
                              onChange={(event) =>
                                setValue(
                                  `positions[${index}].pspElement`,
                                  event.target.value
                                )
                              }
                              style={{ width: '130px' }}
                              inputProps={{
                                style: { height: '1.6em', fontSize: '0.75em' },
                              }}
                            />
                          </FormElementStyled>
                        )}

                        {getValues(`positions[${index}].controllingType`) ===
                          'Innenauftrag' && (
                          <FormElementStyled>
                            <TextField
                              {...register(`positions[${index}].internal`)}
                              value={getValues(`positions[${index}].internal`)}
                              onChange={(event) =>
                                setValue(
                                  `positions[${index}].internal`,
                                  event.target.value
                                )
                              }
                              style={{ width: '130px' }}
                            />
                          </FormElementStyled>
                        )}

                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '60px' }}
                            options={
                              taxSignsSap && taxSignsSap.map((value) => value)
                            }
                            getOptionLabel={(option: Tax) =>
                              `${option.taxSign} ${option.text}`
                            }
                            renderOption={(option) => (
                              <>
                                {option.taxSign} {option.text}
                              </>
                            )}
                            value={getValues(`positions[${index}].taxSign`)}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(`positions[${index}].taxSign`, option);
                              setCalc(true);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '60px' }}
                            options={accounts.map((value) => value)}
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => <>{option}</>}
                            value={getValues(`positions[${index}].account`)}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(`positions[${index}].account`, option);
                              setCalc(true);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>
                        <FormElementStyled>
                          <TextField
                            {...register(`positions[${index}].allocNr`)}
                            value={getValues(`positions[${index}].allocNr`)}
                            style={{ width: '80px' }}
                            onChange={(event) =>
                              setValue(
                                `positions[${index}].allocNr`,
                                event.target.value
                              )
                            }
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>

                        <FormElementStyled>
                          <TextField
                            {...register(`positions[${index}].sum`)}
                            value={getValues(`positions[${index}].sum`)}
                            style={{ width: '90px' }}
                            onChange={(event) => {
                              setValue(
                                `positions[${index}].sum`,
                                event.target.value.replace(',', '.')
                              );
                              setCalc(true);
                            }}
                            disabled={
                              !checkAuthorisation(user[0].username!) ||
                              disableWholeForm
                            }
                          />
                        </FormElementStyled>

                        {!disableWholeForm &&
                        checkAuthorisation(user[0].username!) ? (
                          <DeleteForever
                            style={{
                              cursor: 'pointer',
                              width: '50px',
                              color: 'primary',
                            }}
                            key={Math.random()}
                            onClick={() => {
                              removePositionByIndex(index);
                            }}
                          />
                        ) : (
                          <DeleteForever
                            style={{
                              width: '50px',
                              color: 'grey',
                            }}
                            key={Math.random()}
                          />
                        )}
                        <FormElementStyled>
                          <Checkbox
                            {...register(`positions[${index}].taged`)}
                            style={{
                              width: 30,
                              transform: `translate(-5px, -5px)`,
                            }}
                            defaultChecked={getValues(
                              `positions[${index}].taged`
                            )}
                            size="small"
                            onChange={(event) => {
                              setValue(
                                `positions[${index}].taged`,
                                event.target.checked
                              );
                            }}
                          />
                        </FormElementStyled>
                      </FormRow>
                    );
                  }
                  return undefined;
                }
              )}
            {watch('positionsFi').length > 0 && watch('sapType') === 'FI' && (
              <>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                    }}
                  >
                    FI Positionen
                  </p>
                </FormRow>
                <FormRow style={{ marginTop: '0px' }}>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '90px',
                      marginTop: '0px',
                    }}
                  >
                    Sachkonto
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '100px',
                      marginTop: '0px',
                    }}
                  >
                    Kostenträger
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '130px',
                      marginTop: '0px',
                    }}
                  >
                    {' '}
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Steuer
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '75px',
                      marginTop: '0px',
                    }}
                  >
                    Konto
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Zuordnung
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '60px',
                      marginTop: '0px',
                    }}
                  >
                    Nettobetrag
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '50px',
                      marginTop: '0px',
                    }}
                  >
                    Löschen
                  </p>
                  <p
                    style={{
                      fontSize: '0.7em',
                      width: '30px',
                      marginTop: '0px',
                    }}
                  >
                    .
                  </p>
                </FormRow>
              </>
            )}
            {glListData &&
              costcenterData &&
              getValues('sapType') === 'FI' &&
              fieldsFi &&
              fieldsFi.map(
                (
                  field: Partial<ArrayField<InvoicePositionFi, 'id'>>,
                  index: number
                ) => {
                  register(`positionsFi[${index}].GlAccount`);
                  register(`positionsFi[${index}].controllingType`);
                  register(`positionsFi[${index}].Costcenter`);
                  register(`positionsFi[${index}].internal`);
                  register(`positionsFi[${index}].pspElement`);
                  register(`positionsFi[${index}].taxSign`);
                  register(`positionsFi[${index}].sum`);
                  register(`positionsFi[${index}].allocNr`);
                  register(`positionsFi[${index}].account`);
                  register(`positionsFi[${index}].taged`);

                  if (
                    !getValues(`positionsFi[${index}].GlAccount`) &&
                    field.GlAccount
                  ) {
                    setValue(
                      `positionsFi[${index}].GlAccount`,
                      field.GlAccount
                    );
                  }
                  // test
                  if (
                    !getValues(`positionsFi[${index}].controllingType`) &&
                    field.controllingType
                  ) {
                    setValue(
                      `positionsFi[${index}].controllingType`,
                      field.controllingType
                    );
                  }
                  if (
                    !getValues(`positionsFi[${index}].Costcenter`) &&
                    field.Costcenter &&
                    getValues(`positionsFi[${index}].controllingType`) ===
                      'Kostenstelle'
                  ) {
                    setValue(
                      `positionsFi[${index}].Costcenter`,
                      field.Costcenter
                    );
                  }
                  if (
                    !getValues(`positionsFi[${index}].internal`) &&
                    field.internal &&
                    getValues(`positionsFi[${index}].controllingType`) ===
                      'Innenauftrag'
                  ) {
                    setValue(`positionsFi[${index}].internal`, field.internal);
                  }
                  if (
                    !getValues(`positionsFi[${index}].pspElement`) &&
                    field.pspElement &&
                    getValues(`positionsFi[${index}].controllingType`) ===
                      'PSP-Element'
                  ) {
                    setValue(
                      `positionsFi[${index}].pspElement`,
                      field.pspElement
                    );
                  }
                  if (
                    !getValues(`positionsFi[${index}].taxSign`) &&
                    field.taxSign
                  ) {
                    setValue(`positionsFi[${index}].taxSign`, field.taxSign);
                  }
                  if (!getValues(`positionsFi[${index}].sum`) && field.sum) {
                    setValue(`positionsFi[${index}].sum`, field.sum);
                  }
                  if (
                    !getValues(`positionsFi[${index}].allocNr`) &&
                    field.allocNr
                  ) {
                    setValue(`positionsFi[${index}].allocNr`, field.allocNr);
                  }
                  if (
                    !getValues(`positionsFi[${index}].account`) &&
                    field.account
                  ) {
                    setValue(`positionsFi[${index}].account`, field.account);
                  }
                  if (
                    getValues(`positionsFi[${index}].taged`) === undefined &&
                    field.taged !== undefined
                  ) {
                    setValue(`positionsFi[${index}].taged`, field.taged);
                  }

                  return (
                    <FormRow
                      key={field.id}
                      style={{
                        marginTop: '0px',
                        paddingLeft: '3px',
                        borderRadius: '3px',
                        background:
                          getValues(`positionsFi[${index}].taged`) &&
                          'indianred',
                      }}
                    >
                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '90px' }}
                          options={glListData!.glList.map((value) => value)}
                          getOptionLabel={(option: GlList) =>
                            `${getRidOfLeadingZeros(option.GlAccount)}${' '}
                            ${option.LongText}`
                          }
                          renderOption={(option) => (
                            <>
                              {getRidOfLeadingZeros(option.GlAccount)}{' '}
                              {option.LongText}
                            </>
                          )}
                          value={getValues(`positionsFi[${index}].GlAccount`)}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(`positionsFi[${index}].GlAccount`, option);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '100px' }}
                          options={controllingTypes.map((value) => value)}
                          getOptionLabel={(option: string) => `${option}`}
                          renderOption={(option) => <>{option}</>}
                          value={getValues(
                            `positionsFi[${index}].controllingType`
                          )}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(
                              `positionsFi[${index}].controllingType`,
                              option
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={disableWholeForm}
                        />
                      </FormElementStyled>

                      {getValues(`positionsFi[${index}].controllingType`) ===
                        'Kostenstelle' && (
                        <FormElementStyled>
                          <Autocomplete
                            disableClearable
                            openOnFocus
                            PopperComponent={SCPopper}
                            style={{ width: '130px' }}
                            options={costcenterData!.costcenter.map(
                              (value) => value
                            )}
                            getOptionLabel={(option: Costcenter) =>
                              `${getRidOfLeadingZeros(option.Costcenter)}`
                            }
                            renderOption={(option) => (
                              <>
                                {getRidOfLeadingZeros(option.Costcenter)}{' '}
                                {option.CocntrTxt}
                              </>
                            )}
                            value={getValues(
                              `positionsFi[${index}].Costcenter`
                            )}
                            autoHighlight
                            autoSelect
                            selectOnFocus
                            onChange={(_, option) => {
                              setValue(
                                `positionsFi[${index}].Costcenter`,
                                option
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={disableWholeForm}
                          />
                        </FormElementStyled>
                      )}

                      {getValues(`positionsFi[${index}].controllingType`) ===
                        'PSP-Element' && (
                        <FormElementStyled>
                          <TextField
                            {...register(`positionsFi[${index}].pspElement`)}
                            value={getValues(
                              `positionsFi[${index}].pspElement`
                            )}
                            onChange={(event) =>
                              setValue(
                                `positionsFi[${index}].pspElement`,
                                event.target.value
                              )
                            }
                            style={{ width: '130px' }}
                            inputProps={{
                              style: { height: '1.6em', fontSize: '0.75em' },
                            }}
                          />
                        </FormElementStyled>
                      )}

                      {getValues(`positionsFi[${index}].controllingType`) ===
                        'Innenauftrag' && (
                        <FormElementStyled>
                          <TextField
                            {...register(`positionsFi[${index}].internal`)}
                            value={getValues(`positionsFi[${index}].internal`)}
                            onChange={(event) =>
                              setValue(
                                `positionsFi[${index}].internal`,
                                event.target.value
                              )
                            }
                            style={{ width: '130px' }}
                          />
                        </FormElementStyled>
                      )}

                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '60px' }}
                          options={
                            taxSignsSap && taxSignsSap.map((value) => value)
                          }
                          getOptionLabel={(option: Tax) =>
                            `${option.taxSign} ${option.text}`
                          }
                          renderOption={(option) => (
                            <>
                              {option.taxSign} {option.text}
                            </>
                          )}
                          value={getValues(`positionsFi[${index}].taxSign`)}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(`positionsFi[${index}].taxSign`, option);
                            setCalc(true);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>
                      <FormElementStyled>
                        <Autocomplete
                          disableClearable
                          openOnFocus
                          PopperComponent={SCPopper}
                          style={{ width: '75px' }}
                          options={accounts.map((value) => value)}
                          getOptionLabel={(option) => `${option}`}
                          renderOption={(option) => option}
                          value={getValues(`positionsFi[${index}].account`)}
                          autoHighlight
                          autoSelect
                          selectOnFocus
                          onChange={(_, option) => {
                            setValue(`positionsFi[${index}].account`, option);
                            setCalc(true);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      <FormElementStyled>
                        <TextField
                          {...register(`positionsFi[${index}].allocNr`)}
                          value={getValues(`positionsFi[${index}].allocNr`)}
                          style={{ width: '60px' }}
                          onChange={(event) =>
                            setValue(
                              `positionsFi[${index}].allocNr`,
                              event.target.value
                            )
                          }
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>
                      <FormElementStyled>
                        <TextField
                          {...register(`positionsFi[${index}].sum`)}
                          value={getValues(`positionsFi[${index}].sum`)}
                          style={{ width: '60px' }}
                          onChange={(event) => {
                            setValue(
                              `positionsFi[${index}].sum`,
                              event.target.value.replace(',', '.')
                            );
                            setCalc(true);
                          }}
                          disabled={
                            !checkAuthorisation(user[0].username!) ||
                            disableWholeForm
                          }
                        />
                      </FormElementStyled>

                      {!disableWholeForm &&
                      checkAuthorisation(user[0].username!) ? (
                        <DeleteForever
                          style={{
                            cursor: 'pointer',
                            width: '50px',
                            color: 'primary',
                          }}
                          key={Math.random()}
                          onClick={() => {
                            removePositionByIndex(index);
                          }}
                        />
                      ) : (
                        <DeleteForever
                          style={{
                            width: '50px',
                            color: 'grey',
                          }}
                          key={Math.random()}
                        />
                      )}
                      <FormElementStyled>
                        <Checkbox
                          {...register(`positionsFi[${index}].taged`)}
                          style={{
                            width: 30,
                            transform: `translate(-5px, -5px)`,
                          }}
                          defaultChecked={getValues(
                            `positionsFi[${index}].taged`
                          )}
                          size="small"
                          onChange={(event) => {
                            setValue(
                              `positionsFi[${index}].taged`,
                              event.target.checked
                            );
                          }}
                        />
                      </FormElementStyled>
                    </FormRow>
                  );
                }
              )}
          </FormSection>
        )}
        <FormSection>
          <FormRow style={{ margin: '0px 0px 3px 0px' }}>
            <TextField
              {...register('commentField')}
              style={{ width: '95%', marginTop: '0px', marginBottom: '5px' }}
              multiline
              label="Hinterlassen Sie hier ein Kommentar"
              value={watch('commentField')}
              onChange={(event) => setValue('commentField', event.target.value)}
            />
            <Send
              style={{
                color: 'secondary',
                marginTop: '15px',
                cursor: 'pointer',
                width: '20px',
                boxSizing: 'border-box',
                maxHeight: '20px',
              }}
              onClick={() => {
                appendComment({
                  author: user[0].username,
                  comment: getValues('commentField'),
                  date: Date.now().toString(),
                  reason: 'comment',
                });
                setValue('commentField', '');
              }}
            />
          </FormRow>

          {fieldsComments
            .map((comment: Partial<ArrayField<Comment, 'id'>>) => {
              return (
                <FormColumn
                  key={comment.id}
                  style={{
                    borderColor:
                      comment.reason === 'comment' ? 'lightgrey' : 'red',
                    padding: '0px 10px 0px 10px',
                    borderRadius: '5px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    width: '95%',
                    marginBottom: '3px',
                    gap: '0px',
                    background: 'white',
                  }}
                >
                  <p style={{ width: '100%', margin: '5px 5px 5px 5px' }}>
                    {comment.comment}
                  </p>
                  <p style={{ fontSize: '0.7em', margin: '5px 5px 5px 5px' }}>
                    {new Date(Number.parseInt(comment.date!, 10))
                      .toString()
                      .slice(0, 10)}{' '}
                    von {comment.author}
                  </p>
                </FormColumn>
              );
            })
            .reverse()}
        </FormSection>
      </fieldset>
    </SCForm>
  );
};
