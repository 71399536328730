import { BlueVariant } from './variants/Blue';
import { DarkVariant } from './variants/Dark';
import { LightVariant } from './variants/Light';
import { IndigoVariant } from './variants/Indigo';
import { GreenVariant } from './variants/Green';
import { TealVariant } from './variants/Teal';

export const Variants = [
  DarkVariant,
  LightVariant,
  BlueVariant,
  GreenVariant,
  IndigoVariant,
  TealVariant,
];
