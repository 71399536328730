import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { PopupRequest } from '@azure/msal-browser';

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const AuthenticationCheck: FC = (props) => {
  const { children } = props;
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (isAuthenticated) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      timeoutRef.current = (setTimeout(() => {
        history.push('/login');
      }, 500) as unknown) as number;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
};
