import styled from 'styled-components';
import React, { FC } from 'react';
import { BoxCoordinates } from '../../../../../../utils/OCRUtils';

export const Button = styled.div`
  position: absolute;
  transition-duration: 0.3s;
  background-color: rgba(255, 50, 50, 0.3);
  border-color: rgba(255, 50, 50, 1);
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  opacity: 0;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const ImageTextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageText = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 5px;
  pointer-events: none;
`;

export interface ClickableInvoiceOverlayProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  coordinates: BoxCoordinates;
  highlight?: string;
}

export const ClickableInvoiceOverlay: FC<ClickableInvoiceOverlayProps> = (
  props
) => {
  const { onClick, coordinates, children, highlight } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    onClick(event);
  };

  const style: React.CSSProperties = {
    top: `${coordinates.y - 5}px`,
    left: `${coordinates.x - 10}px`,
    width: `${coordinates.width + 20}px`,
    height: `${coordinates.height + 10}px`,
  };

  if (highlight) {
    style.borderColor = highlight;
    style.opacity = 1;
  }

  return (
    <Button onClick={handleClick} style={style}>
      <ImageTextWrapper>
        <ImageText>{children}</ImageText>
      </ImageTextWrapper>
    </Button>
  );
};
