export const checkAuthorisation = (currentUser: string): boolean => {
  const admins = [
    'richard.prammer@intern.hitzinger.at',
    'marco.pichler@intern.hitzinger.at',
    'sebastian.schneeberger@intern.hitzinger.at',
    'richard.prammer@hitzinger.at',
    'marco.pichler@hitzinger.at',
    'sebastian.schneeberger@hitzinger.at',
    'richard.prammer@viingo.com',
    'sandra.hartl@hitzinger.at',
    'sandra.hartl@intern.hitzinger.at',
    'berthold.holz@intern.hitzinger.at',
    'praktikant.fico@hitzinger.at',
    'praktikant.fico@intern.hitzinger.at',
    'Countit4@intern.hitzinger.at',
    'Countit5@intern.hitzinger.at',
    'Countit4@hitzinger.at',
    'Countit5@hitzinger.at',
    'admin@hitzingerat.onmicrosoft.com',
    'lejla.velicova@hitzinger.at',
    'lejla.velicova@intern.hitzinger.at',
    'franz.rossler@intern.hitzinger.at',
    'franz.rossler@hitzinger.at',
    'sandra.ausweger@hitzinger.at',
    'sandra.ausweger@intern.hitzinger.at',
    'gernot.teibinger@hitzinger.at',
    'gernot.teibinger@intern.hitzinger.at',
    'margot.schaffer@hitzinger.at',
    'margot.schaffer@intern.hitzinger.at',
    'claudia.caesar@hitzinger.at',
    'claudia.caesar@intern.hitzinger.at',
    'marco.hager-pichler@hitzinger.at',
    'marco.hager-pichler@intern.hitzinger.at',
    'denise.waldschuetz@hitzinger.at',
    'denise.waldschuetz@intern.hitzinger.at',
  ];
  // console.log('AUTHCHECK', admins.includes(currentUser));
  return admins.includes(currentUser);
};
