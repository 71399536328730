/* eslint unicorn/filename-case: "off" */
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  Theme,
  ThemeOptions,
} from '@material-ui/core';
import { DarkVariant } from '../variants/Dark';

export const createDashboardTheme = (options: ThemeOptions): Theme => {
  const { name, ...rest } = DarkVariant;

  return createMuiTheme({
    ...rest,
    ...options,
    overrides: {
      MuiDialogTitle: {
        root: {
          padding: 24,
          backgroundColor: '#e5e8ee',
          border: '1px solid #dadfe8',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 24,
          fontWeight: 500,
          color: '#212121',
          marginBottom: 12,
        },
        body2: {},
      },
      MuiCardHeader: {
        root: {
          fontWeight: 'bold',
          padding: 12,
          paddingBottom: 8,
        },
        action: {
          marginTop: 0,
          marginRight: 0,
        },
        title: {
          color: '#212121',
          fontSize: 16,
        },
      },
      MuiCardContent: {
        root: {
          paddingTop: 0,
        },
      },
      MuiChip: {
        sizeSmall: {
          borderRadius: 4,
          height: 18,
          fontSize: 10,
        },
        labelSmall: {
          paddingLeft: 6,
          paddingRight: 6,
          fontWeight: 300,
        },
        colorPrimary: {
          backgroundColor: '#4682DA',
        },
      },
    },
  });
};
