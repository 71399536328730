import React, { FC, useState, useEffect } from 'react';
import {
  TextareaAutosize,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Button,
  Radio,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import styled from 'styled-components';
import { useLazyQuery, gql } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { useStateMachine } from 'little-state-machine';
import { updateSelectedInvoice } from '../../../../../stores/globalStore';
import { useFormContext } from 'react-hook-form';
import { useUpdateInvoiceMutation } from '../../../../../query/UpdateInvoiceMutation';
import { queryClient } from '../../../../../query/setup';
import { useAcceptInvoiceMutation } from '../../../../../query/AcceptInvoiceMutation';
import { checkAuthorisation } from '../../../checkAuthorisation';

const SCBody = styled.div`
  padding: 40px;
  min-width: 300px;
`;

const SCTextArea = styled(TextareaAutosize)`
  width: 100%;
`;

interface InvoiceAcceptModalBodyProps {
  closeModal: () => void;
  dataComplete: boolean;
  setDataComplete: React.Dispatch<React.SetStateAction<boolean>>;
  areAcceptorsInMailList: () => string[];
  appendComment: any;
}

export const InvoiceAcceptModalBody: FC<InvoiceAcceptModalBodyProps> = (
  props
) => {
  const { actions, state } = useStateMachine({ updateSelectedInvoice });
  const [valueChange, setValueChange] = useState('bestätigen');
  const [text, setText] = useState<string>();
  const [noText, setNoText] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const [dataProcessed, setDataProcessed] = useState<boolean>(false);
  const [refetched, setRefetched] = useState<boolean>(false);
  const [mutateClose, setMutateClose] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [refetchAndClose, setRefetchAndClose] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const { accounts } = useMsal();
  const [acceptType, setAcceptType] = useState<string>('accept');

  const { closeModal: closeModalFunc, dataComplete, appendComment } = props;
  const {
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const { mutate: mutateInvoiceClose } = useUpdateInvoiceMutation(
    {
      ...state.selectedInvoice!,
      ...getValues(),
      positions: watch('positions'),
      positionsFi: watch('positionsFi'),
      comments: watch('comments'),
    },
    true
  );

  const {
    data: acceptData,
    mutate: acceptMutate,
    isLoading: isLoadingAccept,
  } = useAcceptInvoiceMutation({
    _id: state.selectedInvoice!._id,
    acceptor: accounts[0].username!,
    acceptType,
  });

  const declineInvoice = (): void => {
    if (!text) {
      setNoText(true);
    } else {
      const newAcceptorsArray: string[] = getValues('acceptors').filter(
        (user: string) => user !== accounts[0].username
      );
      setValue('acceptors', newAcceptorsArray);
      setValue('invoiceStatus', '9');
      appendComment({
        author: accounts[0].username,
        comment: text,
        date: Date.now().toString(),
        reason: 'dismiss',
      });
      setMutateClose(true);
    }
  };

  const acceptInvoice = (type: 'accept' | 'book' | 'park'): void => {
    setAcceptType(type);
    queryClient.setQueryData('acceptInvoice', undefined);
    if (valueChange === 'bestätigen') {
      setAccept(true);
    }
    if (valueChange === 'ablehnen') {
      declineInvoice();
    }
  };

  useEffect(() => {
    if (accept) {
      console.log('acceptloop');
      setAccept(false);
      setLoadingButton(true);
      acceptMutate();
    }
    if (refetchAndClose && !queryClient.isMutating()) {
      setRefetchAndClose(false);
      setCloseModal(true);
    }

    if (
      closeModal &&
      !queryClient.isFetching('invoices') &&
      !queryClient.isFetching('workInProgress') &&
      acceptData.acceptInvoice.booked
    ) {
      actions.updateSelectedInvoice(undefined);
      queryClient.refetchQueries('invoices');
      queryClient.refetchQueries('workInProgress');
      queryClient.refetchQueries('accepted');
      setLoadingButton(false);
    }
    /*  if (
      closeModal &&
      !queryClient.isFetching('invoices') &&
      !queryClient.isFetching('workInProgress') &&
      !acceptData.acceptInvoice.booked
    ) {
      setLoadingButton(false);
      closeModalFunc();
    } */

    /*  if (acceptData && acceptData.booked) {
      actions.updateSelectedInvoice(undefined);
    }
    */
    if (mutateClose) {
      mutateInvoiceClose();
      setMutateClose(false);
    }
  }, [accept, refetchAndClose, mutateClose]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValueChange((event.target as HTMLInputElement).value);
    setNoText(false);
  };

  const handleData = (): void => {
    setDataProcessed(true);
    const responseData = acceptData.acceptInvoice;
    console.log('RESPONSEDATA', responseData);
    if (responseData.booked) {
      setRefetchAndClose(true);
    }
    if (!responseData.booked) {
      setResponseMessage(responseData);
      setLoadingButton(false);
    }
    /*  const historyAcceptors = getValues('acceptorsHistory')
      ? [...getValues('acceptorsHistory')]
      : [];
    setResponseMessage(newData);
    setDataProcessed(true); */
    /*  if (!newData.booked && !couldNotBook && !error) {
      setError(true);
      setValue('invoiceStatus', '5');
      setValue('sapInvoiceNumber', '');
      mutateInvoice();
    } else {
      setValue('sapInvoiceNumber', newData.receiptNumber.slice(0, 10));
      setValue('acceptorsHistory', [...historyAcceptors, accounts[0].username]);
      setValue('invoiceStatus', '4');
      mutateInvoice();
    } */
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    if (text) {
      setNoText(false);
    }
    setText(event.target.value);
  };

  return (
    <SCBody>
      {noText && <p>Bitte Begründung für die Ablehnung eingeben</p>}
      {acceptData && !dataProcessed && handleData()}
      {responseMessage && responseMessage.booked && (
        <>
          <p>Die Rechnung erfolgreich vorerfasst:</p>
          <p style={{ color: 'green' }}>
            {' '}
            Belegnummer: {responseMessage.receiptNumber}
          </p>
        </>
      )}
      {responseMessage && !responseMessage.booked && (
        <>
          <p>
            Die Rechnung wurde von Sap aus folgendem Grund nicht angenommen:
          </p>
          {responseMessage.message.map(
            (object: {
              type: string;
              message: string;
              messagev1: string;
              messagev2: string;
              messagev3: string;
              messagev4: string;
            }) => {
              return (
                <div key={Math.random()}>
                  <p
                    style={{
                      margin: '0px',
                      color:
                        object.type === 'W'
                          ? 'orange'
                          : object.type === 'S'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {object.message}
                  </p>
                  <p
                    style={{
                      margin: '0px',

                      color:
                        object.type === 'W'
                          ? 'orange'
                          : object.type === 'S'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {object.messagev1}
                  </p>
                  <p
                    style={{
                      margin: '0px',

                      color:
                        object.type === 'W'
                          ? 'orange'
                          : object.type === 'S'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {object.messagev2}
                  </p>
                  <p
                    style={{
                      margin: '0px',

                      color:
                        object.type === 'W'
                          ? 'orange'
                          : object.type === 'S'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {object.messagev3}
                  </p>
                  <p
                    style={{
                      margin: '0px',

                      color:
                        object.type === 'W'
                          ? 'orange'
                          : object.type === 'S'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {object.messagev4}
                  </p>
                </div>
              );
            }
          )}
        </>
      )}
      {isLoadingAccept && <p>Loading...</p>}
      {/*         {data && !alreadyShown && <div>{data.message}</div> && handleData(data)}
       */}{' '}
      <FormControl component="fieldset">
        {getValues('sapType') === 'MM' && <h3>MM Beleg</h3>}
        {getValues('sapType') === 'FI' && <h3>FI Beleg</h3>}
        <br />
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={valueChange}
          onChange={handleChange}
        >
          <FormControlLabel
            value="bestätigen"
            control={<Radio />}
            label="Bestätigen"
          />
          <FormControlLabel
            value="ablehnen"
            control={<Radio />}
            label="Ablehen"
          />
        </RadioGroup>
      </FormControl>
      <br />
      <SCTextArea
        aria-label="minimum height"
        rowsMin={6}
        placeholder="Tragen Sie hier den Grund der Ablehnung ein"
        onChange={(event) => handleTextChange(event)}
      />
      <br />
      <Button
        disableRipple
        autoFocus
        onClick={() => acceptInvoice('accept')}
        color="primary"
        variant="outlined"
      >
        Bestätigen
      </Button>
      {dataComplete || valueChange === 'ablehnen' ? (
        <>
          {loadingButton ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {checkAuthorisation(accounts[0].username!) && (
                <>
                  <Button
                    disableRipple
                    autoFocus
                    onClick={() => acceptInvoice('book')}
                    color="primary"
                  >
                    Buchen
                  </Button>
                  <Button
                    disableRipple
                    autoFocus
                    onClick={() => acceptInvoice('park')}
                    color="primary"
                  >
                    Vorerfassen
                  </Button>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p>Es fehlen Daten um die Rechnung abschließen zu können</p>
      )}
    </SCBody>
  );
};
