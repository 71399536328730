import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background-color: rgb(247,249,252);
  } 

  body {
    background: rgb(247,249,252);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .CollectionDataTable .MuiTableHead-root th:last-child {
    text-align: right;
  }
  
  .CollectionDataTable .MuiTableHead-root th .MuiTableSortLabel-root {
    position: unset !important;
    display: inline !important;
  }
  
  .CollectionDataTable .MuiTableHead-root th .MuiTableSortLabel-root .responsiveLabel {
      position: absolute;
      left: 16px;
      right: 16px;
  }
  
  .CollectionDataTable .MuiTableHead-root th .MuiTableSortLabel-root svg.MuiTableSortLabel-icon {
    float: left;
    margin-left: -20px;
    margin-top: 2px;
    opacity: 0;  
  }
  
  .CollectionDataTable .MuiTableHead-root th:last-child  .MuiTableSortLabel-root svg {
    display: none;
  }
  
  .CollectionDataTable .MuiTableBody-root td:last-child {
    
  }
  
  .CollectionDataTable .MuiTableBody-root td:last-child > div {
    justify-content: flex-end;
  }
  
  .CollectionDataTable .MuiTableCell-sizeSmall {
    padding: 6px 16px 6px 16px
  }
  
  .CollectionDataTable td.MuiTableCell-root.MuiTableCell-paddingNone div button { 
    width: 48px !important;
  }
  
  @-webkit-keyframes autofill {
    0%,100% {
        color: #000;
        background: #fff;
    }
}

  #signinform .MuiFormLabel-root {
     z-index: 1;
     pointer-events: none;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-internal-autofill-selected,
  input:-internal-autofill-selected:hover,
  input:-internal-autofill-selected:focus,
  input:-internal-autofill-selected:active,
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active,{
    border: 0;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: none;
    transition: none;
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 100px white inset;
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
  
  @media(max-width: 650px) {
    .CollectionDataTable .MuiTablePagination-root {
      width: 650px !important;
      max-width: 100%;
    } 
  
    .CollectionDataTable .MuiTablePagination-root .MuiTablePagination-spacer {
      display: none;
    }
    
    .CollectionDataTable .MuiTablePagination-root .MuiTablePagination-toolbar div {
       margin: 0 auto; 
    }
    
    .CollectionDataTable div.MuiToolbar-root div.MTableToolbar-actions-311 > div > div  button {
      padding: 6px;
    }    
  }
  
  #tileimage .uppy-DragDrop--is-dragdrop-supported {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='8' stroke-dasharray='40' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
    border: none;
  }
  
  /* Hide chonky options menu */
  /* .chonky-dropdown + .chonky-dropdown .chonky-dropdown-button */ 
  .chonky-dropdown {
    display: none !important;
  }
  
  .chonky-tooltip::before {
    height: auto !important;
  }
  
  .fc-header-toolbar .fc-prev-button .fc-icon.fc-icon-chevron-left,
  .fc-header-toolbar .fc-next-button .fc-icon.fc-icon-chevron-right {
    line-height: .6em;
  }
  
  .fc-event {
    cursor: pointer;
  }
  
  .fc-h-event .fc-event-main {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .fc-list-event-time {
    width: 0;
    color: transparent;  
    display: none;
  }
  
  /** 
   * Improvement for the rendering of material-ui autocomplete. The
   * original widget would often show an empty extra line due to the
   * input field having a min width of 30px. The following fix avoids 
   * this by setting the input field size to 0 when it is not focused.
   */
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0px !important;
    padding: 0 !important;
  }
  
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input:focus {
    min-width: 30px !important;
    padding: 4px !important;
  }
  
  /* If the field is empty we need more padding as the tags are higher than the
   * input field.
  */
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 6px 0 !important;
  }
  
  /* end material ui autocomplete improvements */
  
  /**
    Material table improvements
  */ 
  tr[mode="delete"] > td  > .MuiTypography-h6 {
    font-size: 1em;
  }
`;
