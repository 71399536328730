import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { Widget } from '../Widget';
import { Kpi } from '../../../../../dto/Kpi';
import { totals } from './WidgetCalculationUtils';

export interface SingleValueWidgetProps {
  label: string;
  value?: string;
  relativeChange?: number;
}

const RelativeChange = styled.span`
  display: inline-block;
  font-weight: bold;
  padding: 2px 4px;
`;

const NegativeChange = styled(RelativeChange)`
  color: #f6433c;
  background-color: #feecec;
`;

const PositiveChange = styled(RelativeChange)`
  color: #4bae51;
  background-color: #ecf8ee;
`;

const INVOICE_QUERY = gql`
  query($startDate: Float, $endDate: Float) {
    kpiOne(startDate: $startDate, endDate: $endDate) {
      Kpi
      KpiDate
      Counter
      Netvalue
    }
  }
`;

export const VorerfassteRechnungen: FC<SingleValueWidgetProps> = (props) => {
  const { label, relativeChange = 0 } = props;
  const RelativeChangeComponent =
    relativeChange === 0
      ? RelativeChange
      : relativeChange > 0
      ? PositiveChange
      : NegativeChange;

  const startDate = startOfWeek(new Date());
  const endDate = addWeeks(endOfWeek(new Date()), 4);

  const { data } = useQuery<{ kpiOne: Kpi[] }>(INVOICE_QUERY, {
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
  });

  const totalValues = totals(data?.kpiOne || []);

  return (
    <Widget title={label} height={1.25} width={3}>
      <Typography variant="body1">{totalValues.Counter}</Typography>

      {/*  <Typography variant="body2" style={{ display: 'none' }}>
        <RelativeChangeComponent>
          {relativeChange > 0 && '+'}
          {relativeChange}%
        </RelativeChangeComponent>
        &nbsp;&nbsp;&nbsp;&nbsp;zur Vergleichsperiode
      </Typography> */}
    </Widget>
  );
};
