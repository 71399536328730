import React, { ReactElement } from 'react';
import { FilterProps } from 'react-table';
import { TextField } from '@material-ui/core';
import { Invoice } from '../../../../../dto/Invoice';

export const TextFilter = <TYPE extends Invoice>(
  props: FilterProps<TYPE>
): ReactElement => {
  const { column } = props;
  const { filterValue, setFilter } = column;

  return (
    <TextField
      value={filterValue || ''}
      onChange={(e) => {
        // eslint-disable-next-line unicorn/no-null
        setFilter(e.target.value || null);
      }}
    />
  );
};
