import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createAriivaTheme } from './styles/createAriivaTheme';
import { Variants } from './Variants';
import { Typography } from './Typography';
import { Overrides } from './Overrides';
import { Breakpoints } from './Breakpoints';
import { Props } from './Props';
import { Shadows } from './Shadows';
import { createDashboardTheme } from './styles/createDashboardTheme';

const theme = (): Theme => {
  return createAriivaTheme({
    spacing: 4,
    breakpoints: Breakpoints,
    overrides: Overrides,
    props: Props,
    typography: Typography,
    shadows: Shadows,
  });
};

export const Themes = Variants.map(() => theme());

export const DashboardWidgetTheme = createDashboardTheme({
  spacing: 4,
  breakpoints: Breakpoints,
  overrides: Overrides,
  props: Props,
  typography: Typography,
  shadows: Shadows,
});
