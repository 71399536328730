import React, { FC } from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

interface BreadcrumbProps {
  pageTitle: string;
  entries: {
    name: string;
    to: string;
  }[];
}

const BreadcrumbsComponent: FC<BreadcrumbProps> = (props) => {
  const { pageTitle, entries } = props;

  return (
    <>
      <StyledBreadcrumbs aria-label="Breadcrumb" mt={2} mb={4}>
        {entries.map(({ name, to }) => (
          <Link key={to} component={NavLink} exact to={to}>
            {name}
          </Link>
        ))}
        <Typography>{pageTitle}</Typography>
      </StyledBreadcrumbs>
      <Divider mb={6} />
    </>
  );
};

export const Breadcrumbs = BreadcrumbsComponent;
