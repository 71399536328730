import { QueryClient } from 'react-query';
import { GraphQLClient } from 'graphql-request';
import { Environment } from '../config/Environment';
import { msalInstance } from '../setupAuthentication';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line unicorn/prefer-number-properties
      staleTime: Infinity,
    },
  },
});

const idTokenClaims = { ...msalInstance?.getAllAccounts()[0]?.idTokenClaims };

export const endpoint = `${Environment.API_ENDPOINT}/graphql`;
export const headers = {
  headers: {
    authorization: idTokenClaims ? `${JSON.stringify(idTokenClaims)}` : '',
  },
};
export const graphQLClient = new GraphQLClient(endpoint, headers);
