import React, { FC, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import { TableInstance, TableState } from 'react-table';
import { Invoice } from '../../../../../dto/Invoice';

export interface LoadIconProps {
  instance: TableInstance<Invoice>;
  initialState: Partial<TableState<Invoice>>;
}

export const LoadIcon: FC<LoadIconProps> = (props) => {
  const { instance, initialState } = props;
  const {
    setAllFilters,
    setGlobalFilter,
    setPageSize,
    setHiddenColumns,
    setSortBy,
    setGroupBy,
    setColumnOrder,
  } = instance;
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(undefined);
  };

  const restoreInitialState = (): void => {
    setAllFilters(initialState.filters || []);
    setGlobalFilter(initialState.globalFilter);
    setColumnOrder(initialState.columnOrder || []);
    setGroupBy(initialState.groupBy || []);
    setHiddenColumns(initialState.hiddenColumns || []);
    setPageSize(initialState.pageSize || 50);
    setSortBy(initialState.sortBy || []);
    handleClose();
  };

  const loadCustomState = (key: string): void => {
    const states = JSON.parse(localStorage.getItem('stored-states') || '{}');
    const state = states[key];
    if (state) {
      setAllFilters(state.filters);
      setGlobalFilter(state.globalFilter);
      setColumnOrder(state.columnOrder);
      setGroupBy(state.groupBy);
      setHiddenColumns(state.hiddenColumns);
      setPageSize(state.pageSize);
      setSortBy(state.sortBy);
    }
    handleClose();
  };

  let states = {};
  try {
    states = JSON.parse(localStorage.getItem('stored-states') || '{}');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error interacting with localstorage', error);
  }

  return (
    <>
      <Tooltip title="Tabellenkonfiguration laden">
        <IconButton
          aria-label="Tabellenkonfiguration laden"
          onClick={handleClick}
        >
          <Restore />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        <MenuItem onClick={restoreInitialState}>Standardkonfiguration</MenuItem>
        {Object.keys(states)
          .filter((key) => key !== 'latest')
          .map((key, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} onClick={() => loadCustomState(key)}>
              {key}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
