import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import { Link } from '@material-ui/icons';
import { InvoiceSelectImage } from './InvoiceSelectImage';
import { Invoice } from '../../../../../dto/Invoice';
import { DialogTitle } from '../DialogTitle';
import { Environment } from '../../../../../config/Environment';

const SCLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => {
    const color = props;
    return color.theme.sidebar.background;
  }};
  margin: 5px;
`;
const SCLinkDisabled = styled(Link)`
  color: grey;
  margin: 5px;
`;
export interface InvoiceSelectModalProps {
  invoice: Invoice;
  disabled: boolean;
  label: { id: string; label: string };
}

export const InvoiceSelectModal: FC<InvoiceSelectModalProps> = (props) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [json, setJson] = useState<any[]>();
  const { invoice, label, disabled: buttonDisabled } = props;

  const handleClickOpen = (event: React.MouseEvent): void => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    if (!json) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jsonArray: any[] = [];
      // eslint-disable-next-line unicorn/consistent-destructuring
      for (let i = 0; i < invoice.pageCount; i++) {
        axios(
          `${Environment.API_ENDPOINT}/requests/${invoice._id}-${i + 1}.json`
        )
          .then((response) => {
            jsonArray[i] = JSON.stringify(response.data);
            return response;
          })
          .catch((error: Error) => {
            // eslint-disable-next-line no-console
            console.log('jsonError', error);
          });
      }
      setJson(jsonArray);
    }
  }, [invoice._id, json, invoice.pageCount]);

  return (
    <div>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle onClose={handleClose}>Regel erstellen</DialogTitle>
        {json && (
          <InvoiceSelectImage
            json={json}
            invoice={invoice}
            label={label}
            handleClose={handleClose}
          />
        )}
      </Dialog>
      {buttonDisabled ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <SCLinkDisabled />
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <SCLink onClick={(event) => handleClickOpen(event)} />
      )}
    </div>
  );
};
