import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import { Entity } from '../../../../../dto/Entity';

const SCAccessors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCAccessorsBadge = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 2px;
  background: ${(props) => {
    const color = props;
    return color.color;
  }};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const convertMailAddressToNameInitials = (mailAddress: string): string => {
  if (!mailAddress) return '';
  if (mailAddress.includes('marco.pichler'.toLowerCase())) return 'MHA';
  const mailAddressPreAt = mailAddress.split('@');
  if (!mailAddressPreAt[0].includes('.')) return mailAddress;
  const mailAddressName = mailAddressPreAt[0].split('.');
  const initials = `${mailAddressName[0]
    .charAt(0)
    .toUpperCase()}${mailAddressName[1]
    .charAt(0)
    .toUpperCase()}${mailAddressName[1].charAt(1).toUpperCase()}`;
  return initials;
};

const calculateTrafficLightValue = (invoiceDate: number): number => {
  const currentDate = Date.now();
  const differenceInDate = currentDate - invoiceDate;

  return differenceInDate / 86400000;
};

export const AccessorsCell = <TYPE extends Entity>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value, row } = props;
  const trafficLight = calculateTrafficLightValue(row.values.received);

  const badges: any[] = [];

  value?.forEach((badge: any) => {
    if (!badges.includes(badge)) badges.push(badge);
  });

  return (
    <SCAccessors>
      {badges && badges.length > 0 ? (
        badges.map((acceptor: string) => {
          return (
            <Tooltip title={acceptor} key={Math.random()}>
              <SCAccessorsBadge
                color={
                  trafficLight < 2
                    ? ' green'
                    : trafficLight < 3 && trafficLight > 2
                    ? ' orange'
                    : ' red'
                }
              >
                {convertMailAddressToNameInitials(acceptor)}
              </SCAccessorsBadge>
            </Tooltip>
          );
        })
      ) : (
        <p>-</p>
      )}
    </SCAccessors>
  );
};
