import React, { FC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { startOfWeek, endOfWeek, addWeeks, format } from 'date-fns';
import {
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  Legend,
} from 'recharts';
import { Kpi } from '../../../../../dto/Kpi';
import { Widget } from '../Widget';
import {
  aggregateByWeek,
  AggregatedChartDataValue,
} from './WidgetCalculationUtils';

const INVOICE_QUERY = gql`
  query($startDate: Float, $endDate: Float) {
    kpiTwo(startDate: $startDate, endDate: $endDate) {
      Kpi
      KpiDate
      Counter
      Netvalue
    }
    kpiThree(startDate: $startDate, endDate: $endDate) {
      Kpi
      KpiDate
      Counter
      Netvalue
    }
  }
`;

export interface CombinedChartData extends AggregatedChartDataValue {
  vorerfasst: number;
  gebucht: number;
}

export const Rechnungsfaelligkeit: FC = () => {
  const startDate = startOfWeek(new Date());
  const endDate = addWeeks(endOfWeek(new Date()), 4);

  const { data } = useQuery<{ kpiTwo: Kpi[]; kpiThree: Kpi[] }>(INVOICE_QUERY, {
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
  });

  const chartDataVorerfasst = aggregateByWeek(
    startDate,
    endDate,
    data?.kpiTwo || []
  );
  const chartDataGebucht = aggregateByWeek(
    startDate,
    endDate,
    data?.kpiThree || []
  );
  const chartDataAccumulated = aggregateByWeek(startDate, endDate, [
    ...(data?.kpiTwo || []),
    ...(data?.kpiThree || []),
  ]);

  const combinedValues = Object.keys(chartDataAccumulated).map((key) => {
    const chartDataAccumulatedEntry = chartDataAccumulated[
      key
    ] as CombinedChartData;
    chartDataAccumulatedEntry.vorerfasst = chartDataVorerfasst[key].value;
    chartDataAccumulatedEntry.gebucht = chartDataGebucht[key].value;
    return chartDataAccumulatedEntry;
  });

  const monthTickFormatter = (tick: string): string => {
    const date = new Date(tick);
    return `KW ${format(date, 'I')}`;
  };

  const labelFormatter = (tick: string): string => {
    const date = new Date(tick);
    return `KW ${format(date, 'I')}`;
  };

  return (
    <Widget
      width={6}
      height={3}
      title="Fälligkeit von Rechnungen"
      subtitle="Für die nächsten 4 Wochen"
    >
      <BarChart
        width={550}
        height={230}
        data={combinedValues}
        margin={{
          top: 15,
          bottom: 5,
        }}
      >
        <XAxis
          tickCount={5}
          dataKey="date"
          tickFormatter={monthTickFormatter}
          style={{ fontSize: '.8em' }}
        />
        <YAxis
          tickCount={5}
          tickFormatter={(tick: number) => `${tick} €`}
          style={{ fontSize: '.8em' }}
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Legend />
        <Tooltip
          cursor={{ fill: '#eee' }}
          labelFormatter={labelFormatter}
          formatter={(value: number) => `${value.toFixed(2)} €`}
        />
        <Bar
          dataKey="vorerfasst"
          name="Vorerfasst"
          fill="#ccc"
          yAxisId={0}
          barSize={8}
        />
        <Bar
          dataKey="gebucht"
          name="Gebucht"
          fill="#74d667"
          yAxisId={0}
          barSize={8}
        />
        <Bar
          dataKey="value"
          name="Gesamt"
          fill="#4682DA"
          yAxisId={0}
          barSize={8}
        />
      </BarChart>
    </Widget>
  );
};
