import {
  Line,
  Region,
  Word,
  JSONData,
} from '../modules/admin/components/dialog/InvoiceSelectModal/JSONData';

export interface BoxCoordinates {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const extractWords = (object: JSONData): Word[] => {
  const result = [] as Word[];
  object.regions.forEach((region: Region) => {
    region.lines.forEach((line: Line) => {
      result.push(...line.words);
    });
  });

  return result;
};

export const splitBoxCoordinates = (boxCoordinates: string): BoxCoordinates => {
  const result = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  let number = '';
  let switcher = 0;

  for (const boxCoordinate of boxCoordinates) {
    if (boxCoordinate === ',') {
      switch (switcher) {
        case 0:
          result.x = Number.parseInt(number, 10);
          switcher += 1;
          number = '';
          break;
        case 1:
          result.y = Number.parseInt(number, 10);
          switcher += 1;
          number = '';
          break;
        case 2:
          result.width = Number.parseInt(number, 10);
          switcher += 1;
          number = '';
          break;
        default:
          break;
      }
    } else {
      number += boxCoordinate;
    }
  }
  result.height = Number.parseInt(number, 10);
  return result;
};
