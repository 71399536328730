import React from 'react';
import { CellProps } from 'react-table';
import { PlaylistAddCheck } from '@material-ui/icons';
import { Entity } from '../../../../../dto/Entity';

export const HeadCheckCell = <TYPE extends Entity>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;

  return (
    <PlaylistAddCheck
      style={{ color: value ? 'rgb(0,255,0)' : 'rgb(255,0,0)' }}
    />
  );
};
