import React from 'react';
import styled from 'styled-components';
import { CellProps } from 'react-table';
import { Invoice } from '../../../../../dto/Invoice';

const SapType = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SapTypeCell = <TYPE extends Invoice>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;

  return value === 'MM' ? (
    <SapType style={{ background: 'lightblue' }}>
      <p>MM</p>
    </SapType>
  ) : (
    <SapType style={{ background: 'lightgreen' }}>
      <p>FI</p>
    </SapType>
  );
};
