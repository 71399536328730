import React, { FC } from 'react';
import { TableInstance } from 'react-table';
import { TableCell, TableRow } from '@material-ui/core';
import { Invoice } from '../../../../dto/Invoice';

export interface FilterRowProps {
  instance: TableInstance<Invoice>;
}

export const FilterRow: FC<FilterRowProps> = (props) => {
  const { instance } = props;
  const { headerGroups } = instance;
  return (
    <>
      {headerGroups.map((headerGroup) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps({
            style: {
              backgroundColor: '#f0f1f2',
            },
          })}
        >
          {headerGroup.headers.map((column) => (
            <TableCell {...column.getHeaderProps()}>
              {column.canFilter ? column.render('Filter') : 'no filter'}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
