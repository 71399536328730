import React, { FC } from 'react';
import { GlobalStyle } from './modules/admin/layout/GlobalStyle';
import { AppProvider } from './AppProvider';
import { Routes } from './routes/Routes';

export const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <AppProvider>
        <Routes />
      </AppProvider>
    </>
  );
};
// forceReleaseComment
