import React, { FC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { startOfWeek, endOfWeek, addWeeks, format } from 'date-fns';
import {
  Bar,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from 'recharts';
import { Kpi } from '../../../../../dto/Kpi';
import { Widget } from '../Widget';
import { aggregateByWeek } from './WidgetCalculationUtils';

const SKONTO_QUERY = gql`
  query($startDate: Float, $endDate: Float) {
    kpiFour(startDate: $startDate, endDate: $endDate) {
      Kpi
      KpiDate
      Counter
      Netvalue
    }
  }
`;

export const SkontoForecast: FC = () => {
  const startDate = startOfWeek(new Date());
  const endDate = addWeeks(endOfWeek(new Date()), 4);

  const { data } = useQuery<{ kpiFour: Kpi[] }>(SKONTO_QUERY, {
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
  });

  const chartData = aggregateByWeek(startDate, endDate, data?.kpiFour || []);

  const monthTickFormatter = (tick: string): string => {
    const date = new Date(tick);
    return `KW ${format(date, 'I')}`;
  };

  const labelFormatter = (tick: string): string => {
    const date = new Date(tick);
    return `KW ${format(date, 'I')}`;
  };
  const formatTooltipValues = (value: number, label: string): string => {
    if (label === 'Anzahl Skonti') {
      return `${value}`;
    }
    if (label === 'Skontobetrag') {
      return `${value.toFixed(2)} €`;
    }
    return '';
  };

  return (
    <Widget
      width={6}
      height={3}
      title="Skonto Forecast"
      subtitle="Für die nächsten 4 Wochen"
    >
      <ComposedChart
        width={550}
        height={230}
        data={Object.values(chartData)}
        margin={{
          top: 15,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <Legend />
        <XAxis
          tickCount={5}
          dataKey="date"
          tickFormatter={monthTickFormatter}
          style={{ fontSize: '.8em' }}
          scale="band"
        />
        <YAxis
          tickCount={5}
          yAxisId={0}
          style={{ fontSize: '.8em' }}
          tickFormatter={(tick: number) => `${tick} €`}
        />
        <YAxis
          orientation="right"
          yAxisId={1}
          minTickGap={1}
          tickCount={3}
          style={{ fontSize: '.8em' }}
        />
        <Tooltip
          cursor={{ fill: '#eee' }}
          labelFormatter={labelFormatter}
          formatter={(value: number, label: string) =>
            formatTooltipValues(value, label)
          }
        />
        <Bar
          dataKey="value"
          name="Skontobetrag"
          fill="#ccc"
          yAxisId={0}
          barSize={15}
        />
        <Line dataKey="count" name="Anzahl Skonti" fill="#4682DA" yAxisId={1} />
      </ComposedChart>
    </Widget>
  );
};
