import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const PageContentContainer = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)}px;
  flex: 1 1 0;
  background: rgb(247, 249, 252);

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(6)}px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
