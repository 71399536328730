import React from 'react';
import {
  DashboardOutlined,
  Receipt,
  AllInbox,
  Person,
  DeviceHub,
  Storage,
  Ballot,
  ScatterPlot,
  HighlightOff,
  DoneOutline,
} from '@material-ui/icons';
import { DashboardPage } from '../pages/Dashboard';
import { ArchivePage } from '../pages/Archive';
import { IncomingInvoicesPage } from '../pages/IncomingInvoices';
import { WorkInProgressPage } from '../pages/WorkInProgress';
import { ParkedInvoicesPage } from '../pages/ParkedInvoices';
import { ServerPage } from '../pages/Server';
import { UsersPage } from '../pages/Users';
import { WorkflowPage } from '../pages/Workflow';
import { AcceptedPage } from '../pages/Accepted';

import { Route, RouteSection } from '../../../routes/Route';
import { DismissedPage } from '../pages/Dismissed';

export const AdminRouteSections: RouteSection[] = [
  {
    label: '',
    routes: [
      {
        id: 'mainmenu.dashboard',
        label: 'Dashboard',
        path: '/',
        icon: <DashboardOutlined />,
        children: [],
        component: DashboardPage,
        authRequired: true,
        role: 'admin',
      },
    ],
  },
  {
    label: 'Rechnungen',
    routes: [
      {
        id: 'incominginvoices',
        label: 'Rechnungseingang',
        path: '/rechnungseingang',
        icon: <Receipt />,
        children: [],
        component: IncomingInvoicesPage,
        authRequired: true,
        exact: false,
        role: 'admin',
      },
      {
        id: 'workinprogress',
        label: 'In Bearbeitung',
        path: '/inbearbeitung',
        icon: <Ballot />,
        children: [],
        component: WorkInProgressPage,
        authRequired: true,
        exact: false,
      },
      {
        id: 'accepted',
        label: 'Freigegeben',
        path: '/freigegeben',
        icon: <DoneOutline />,
        children: [],
        component: AcceptedPage,
        authRequired: true,
        exact: false,
        role: '',
      },
      {
        id: 'dismissed',
        label: 'Abgelehnt',
        path: '/abgelehnt',
        icon: <HighlightOff />,
        children: [],
        component: DismissedPage,
        authRequired: true,
        exact: false,
        role: 'admin',
      },
      {
        id: 'parkedinvoices',
        label: 'Vorerfasst',
        path: '/vorerfasst',
        icon: <ScatterPlot />,
        children: [],
        component: ParkedInvoicesPage,
        authRequired: true,
        exact: false,
        role: 'admin',
      },
      {
        id: 'archive',
        label: 'Archiv',
        path: '/archiv',
        icon: <AllInbox />,
        children: [],
        component: ArchivePage,
        authRequired: true,
        exact: false,
      },
    ],
  },
  {
    label: 'Konfiguration',
    routes: [
      {
        id: 'workFlow',
        label: 'Workflow',
        path: '/workflow',
        icon: <Storage />,
        children: [],
        component: WorkflowPage,
        authRequired: true,
        role: 'admin',
      },
      {
        id: 'users',
        label: 'Benutzer',
        path: '/users',
        icon: <Person />,
        children: [],
        component: UsersPage,
        authRequired: true,
        role: 'admin',
      },
      {
        id: 'server',
        label: 'Server',
        path: '/server',
        icon: <DeviceHub />,
        children: [],
        component: ServerPage,
        authRequired: true,
        exact: false,
        role: 'admin',
      },
    ],
  },
];

export const AdminRoutes: Route[] = AdminRouteSections.flatMap(
  (section) => section.routes
);
