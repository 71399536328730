import React, { FC } from 'react';
import { CssBaseline } from '@material-ui/core';
import backgroundHolo from '../assets/images/background-vision.jpg';
import ariivaLogo from '../assets/images/vFlow_Logo_dark.svg';

const AuthLayoutComponent: FC = ({ children }) => {
  return (
    <div
      style={{
        backgroundSize: `auto 100%`,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundHolo})`,
        backgroundPosition: 'right',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          maxWidth: 520,
          margin: '0 auto',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          minHeight: '100%',
        }}
      >
        <CssBaseline />
        <div
          style={{
            width: '50%',
            margin: '0 auto 50px auto',
          }}
        >
          <img src={ariivaLogo} alt="ARiiVA Mobile Service Management" />
        </div>
        {children}
      </div>
    </div>
  );
};

export const AuthLayout = AuthLayoutComponent;
