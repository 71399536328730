import React, { FC } from 'react';
import { Menu } from '@material-ui/icons';
import { IconButton } from './IconButton';

interface MenuIconProps {
  onClick(): void;
}

const MenuIconComponent: FC<MenuIconProps> = (props) => {
  const { onClick } = props;
  return (
    <IconButton color="inherit" aria-label="Open drawer" onClick={onClick}>
      <Menu />
    </IconButton>
  );
};

export const MenuIcon = MenuIconComponent;
