import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { Breadcrumbs } from '../layout/components/page/Breadcrumbs';
import { InvoiceDetailModal } from '../components/dialog/InvoiceDetailModal/InvoiceDetailModal';
import { InvoiceTable } from '../tables/InvoiceTable';
import { useArchiveQuery } from '../../../query/ArchiveQuery';
import { Invoice } from '../../../dto/Invoice';
import { useMsal } from '@azure/msal-react';
import { Button, LinearProgress } from '@material-ui/core';
import { checkAuthorisation } from '../checkAuthorisation';
import { startOfMonth } from 'date-fns/esm';
import { addMonths, endOfMonth } from 'date-fns';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const filterData = (
  account: string,
  invoices?: Invoice[] | undefined
): Invoice[] | undefined => {
  if (!invoices) return undefined;
  const accountIntern = account.replace('@intern.', '@');
  return invoices.filter((invoice) => {
    return (
      invoice.acceptors.includes(account.toLowerCase()) ||
      invoice.mailObserver.includes(account.toLowerCase()) ||
      invoice.acceptors.includes(accountIntern.toLowerCase()) ||
      invoice.mailObserver.includes(accountIntern.toLowerCase()) ||
      invoice.acceptorsHistory.includes(account.toLowerCase()) ||
      invoice.acceptorsHistory.includes(accountIntern.toLowerCase()) ||
      checkAuthorisation(account.toLowerCase()) ||
      checkAuthorisation(accountIntern.toLowerCase())
    );
  });
};

export const ArchivePage = (): JSX.Element => {
  const filterStatus = ['2'];
  const [firstDay, setFirstDay] = useState<Date>(startOfMonth(new Date()));
  const [lastDay, setLastDay] = useState<Date>(endOfMonth(new Date()));
  const [dateOffset, setDateOffset] = useState<number>(0);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const { data, error, isLoading, refetch, isFetching } = useArchiveQuery({
    invoiceStatus: filterStatus,
    received: { gte: firstDay, lte: lastDay },
  });

  const { state } = useStateMachine();
  const { accounts } = useMsal();

  useEffect(() => {
    if (state.selectedInvoice === undefined) {
      refetch();
    }
  }, [state.selectedInvoice]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayHelper = addMonths(currentDate, dateOffset);
    setFirstDay(startOfMonth(firstDayHelper));
    const lastDayHelper = addMonths(currentDate, dateOffset);
    setLastDay(endOfMonth(lastDayHelper));
    console.log(dateOffset, firstDayHelper, lastDayHelper);
    setShouldRefetch(true);
  }, [dateOffset]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const filteredData = filterData(accounts[0].username!, data?.invoices);

  return (
    <PageContentContainer>
      <PageTitle>Archiv</PageTitle>
      <Breadcrumbs
        pageTitle="Archiv"
        entries={[{ name: 'Dashboard', to: '/' }]}
      />
      <div>
        {error && (
          <>
            <p style={{ color: 'red' }}>
              Derzeit besteht keine Verbindung zum Server
            </p>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            onClick={() => {
              setDateOffset(dateOffset - 1);
            }}
          >
            <ArrowBackIos />
          </Button>
          <p>
            {addMonths(new Date(), dateOffset).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: 'long',
            })}
          </p>
          <Button
            onClick={() => {
              setDateOffset(dateOffset + 1);
            }}
          >
            <ArrowForwardIos />
          </Button>
        </div>
        {(isLoading || isFetching) && <LinearProgress color="secondary" />}
        {data && <InvoiceTable invoiceData={filteredData!} />}
      </div>
      {state.selectedInvoice !== undefined && (
        <InvoiceDetailModal
          config="archive"
          queryString="archive"
          disableWholeForm
        />
      )}
    </PageContentContainer>
  );
};
