import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { FormWrapper } from './components/FormWrapper';
import { Title } from './components/Title';

type PageProps = RouteComponentProps;

export const Page404: FC<PageProps> = () => {
  return (
    <FormWrapper>
      <Title>Etwas ist schiefgelaufen</Title>
      <Typography
        style={{
          margin: '32px 0 32px 0',
          textAlign: 'center',
          lineHeight: '1.8em',
        }}
        dangerouslySetInnerHTML={{
          __html:
            'Diese aufgerufene Seite existiert nicht (mehr).<br /> Eventuell können sie den gesuchten Inhalt über das Dashboard finden!',
        }}
      />
      <Button variant="contained" color="secondary" href="/" fullWidth>
        Zurück zum Dashboard
      </Button>
    </FormWrapper>
  );
};
