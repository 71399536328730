import { Shadows as ShadowsType } from '@material-ui/core/styles/shadows';

function createShadow(px: number): string {
  return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

export const Shadows: ShadowsType = [
  'none',
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
];
