import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { useStateMachine } from 'little-state-machine';
import { Invoice } from '../dto/Invoice';

import { graphQLClient, queryClient } from './setup';
import {
  updateSelectedInvoice,
  updateSapInvoice,
  updateOnMutateInvoice,
  setIsUpdateSap,
} from '../stores/globalStore';

const QUERY = gql`
  mutation UpdateSap($_id: ID!, $creditorId: String) {
    updateSap(_id: $_id, creditorId: $creditorId) {
      _id
      creditorId
      lock
      creditorIdTag
      creditorName
      invoiceNr
      invoiceDate
      bookingDate
      received
      taxTypes
      taxAmounts
      grossTotal
      netTotal
      sapInvoiceNumber
      discountBool
      discountValue
      invoiceStatus
      incomingDate
      imageWidth
      imageOriginalName
      imageHeight
      credentialsCheck
      release
      docType
      orderNr
      supplierId
      currency
      pageCount
      mailEkGr
      mailBanf
      mailObserver
      acceptors
      sapType
      seperateCosts
      customText
      blockZTerm
      acceptorsHistory
      workflowType
      workflowStep
      subsequent
      blockAutoWorkflow
      creditorStreet
      creditorHouseNr
      creditorCountry
      creditorCity
      creditorPLZ
      creditorIBAN
      creditorBank
      startSkontoDate
      skontoAmount
      comments {
        author
        comment
        date
        reason
      }
      positions {
        taged
        MmType
        OrderNrSelected {
          orderId
          creditorId
          positions {
            BEDAT
            EBELN
            EBELP
            NETWR
            MENGE
            WEMNG
            WEMNG
            REMNG
            ZTERM
          }
        }
        PositionSelected {
          BEDAT
          EBELN
          EBELP
          NETWR
          WEMNG
          MENGE
          WEMNG
          REMNG
          ZTERM
        }
        count
        sum
        taxSign {
          taxSign
          tax
          text
        }
        GlAccount {
          CompCode
          GlAccount
          ShortText
          LongText
        }
        Costcenter {
          CoArea
          Costcenter
          CocntrTxt
        }
        controllingType
        allocNr
        account
      }
      positionsFi {
        taged
        GlAccount {
          CompCode
          GlAccount
          ShortText
          LongText
        }
        Costcenter {
          CoArea
          Costcenter
          CocntrTxt
        }
        pspElement
        internal
        taxSign {
          taxSign
          tax
          text
        }
        sum
        allocNr
        account
        controllingType
      }
      zTerm {
        number
        text
      }
    }
  }
`;

interface InvoiceData {
  updateSap: Invoice;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateSapMutation = (
  variables: Partial<Invoice> | undefined
): any => {
  const { state, actions } = useStateMachine({
    updateSelectedInvoice,
    updateSapInvoice,
    updateOnMutateInvoice,
    setIsUpdateSap,
  });
  return useMutation<InvoiceData, Error>(
    'invoices',
    async () => {
      const data = await graphQLClient.request(QUERY, variables);
      return data;
    },
    {
      onSuccess: async (data) => {
        console.log(data);
        actions.updateSelectedInvoice(data.updateSap);
        actions.setIsUpdateSap(true);
      },
    }
  );
};
