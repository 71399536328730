import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SVGForeignObject } from './SVGForeignObject';

export interface WidgetProps {
  title: string;
  action?: React.ReactElement;
  subtitle?: string;
  width?: number;
  height?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  })
);

export const Widget: FC<WidgetProps> = (props) => {
  const { title, subtitle, width = 3, height = 1, action, children } = props;
  const classes = useStyles();

  return (
    <Card className={classes.paper}>
      <CardHeader
        title={title}
        subheader={<Typography variant="caption">{subtitle}</Typography>}
        action={action}
      />
      <CardContent style={{ height: '250px' }}>{children}</CardContent>
    </Card>
  );
};
