import { useQuery, UseQueryResult } from 'react-query';
import { useStateMachine } from 'little-state-machine';
import { gql } from 'graphql-request';
import { Invoice } from '../dto/Invoice';
import { graphQLClient } from './setup';
import {
  updateSelectedInvoice,
  updateSapInvoice,
  updateOnMutateInvoice,
  setIsUpdateSap,
} from '../stores/globalStore';

interface InvoiceData {
  invoice: Invoice;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useInvoicesDetailQuery = (
  filter?: any,
  fields?: string
): UseQueryResult<InvoiceData, Error> => {
  const { actions, state } = useStateMachine({
    updateSelectedInvoice,
    updateSapInvoice,
    updateOnMutateInvoice,
    setIsUpdateSap,
  });
  const queryFields =
    fields ||
    `_id
  creditorId
  lock
  creditorIdTag
  creditorName
  invoiceNr
  invoiceDate
  bookingDate
  received
  taxTypes
  taxAmounts
  grossTotal
  grossTotalInvoice
  netTotal
  sapInvoiceNumber
  discountBool
  discountValue
  invoiceStatus
  incomingDate
  imageWidth
  imageOriginalName
  imageHeight
  credentialsCheck
  release
  docType
  orderNr
  supplierId
  currency
  pageCount
  mailEkGr
  mailBanf
  mailObserver
  acceptors
  sapType
  seperateCosts
  customText
  blockZTerm
  acceptorsHistory
  workflowType
  workflowStep
  subsequent
  blockAutoWorkflow
  creditorStreet
  creditorHouseNr
  creditorCountry
  creditorCity
  creditorPLZ
  creditorIBAN
  creditorBank
  startSkontoDate
  skontoAmount
  comments {
    author
    comment
    date
    reason
  }
  positions {
    taged
    MmType
    OrderNrSelected {
      orderId
      creditorId
      positions {
        BEDAT
        EBELN
        EBELP
        NETWR
        MENGE
        WEMNG
        WEMNG
        REMNG
        ZTERM
      }
    }
    PositionSelected {
      BEDAT
      EBELN
      EBELP
      NETWR
      WEMNG
      MENGE
      WEMNG
      REMNG
      ZTERM
    }
    count
    sum
    taxSign {
      taxSign
      tax
      text
    }
    GlAccount {
      CompCode
      GlAccount
      ShortText
      LongText
    }
    Costcenter {
      CoArea
      Costcenter
      CocntrTxt
    }
    controllingType
    allocNr
    account
  }
  positionsFi {
    taged
    GlAccount {
      CompCode
      GlAccount
      ShortText
      LongText
    }
    Costcenter {
      CoArea
      Costcenter
      CocntrTxt
    }
    pspElement
    internal
    taxSign {
      taxSign
      tax
      text
    }
    sum
    allocNr
    account
    controllingType
  }
  zTerm {
    number
    text
  }`;

  const QUERY = gql`
  query($_id: ID) {
    invoice(_id: $_id) {
      ${queryFields}
    }
  }
`;

  return useQuery<InvoiceData, Error>(
    'invoiceDetail',
    async () => {
      const data = await graphQLClient.request(QUERY, filter);
      return data;
    },
    {
      enabled: false,
    }
  );
};
