import React, {
  ChangeEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
} from 'react';
import { TablePagination as _MuiTablePagination } from '@material-ui/core';
import { TableInstance } from 'react-table';

const rowsPerPageOptions = [10, 25, 50, 100, 500];

interface PaginationProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | undefined;
}

// avoid all of the redraws caused by the internal withStyles
const interestingPropsEqual = (
  prevProps: PaginationProps,
  nextProps: PaginationProps
): boolean =>
  prevProps.count === nextProps.count &&
  prevProps.rowsPerPage === nextProps.rowsPerPage &&
  prevProps.page === nextProps.page &&
  prevProps.onChangePage === nextProps.onChangePage &&
  prevProps.onChangeRowsPerPage === nextProps.onChangeRowsPerPage;

// a bit of a type hack to keep OverridableComponent working as desired
type T = typeof _MuiTablePagination;
const MuiTablePagination: T = React.memo(
  _MuiTablePagination,
  interestingPropsEqual
) as T;

// eslint-disable-next-line @typescript-eslint/no-shadow
export const TablePagination = <T extends Record<string, unknown>>({
  instance,
}: PropsWithChildren<{ instance: TableInstance<T> }>): ReactElement => {
  const {
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
  } = instance;
  const rowCount = rows.length;

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      if (newPage === pageIndex + 1) {
        nextPage();
      } else if (newPage === pageIndex - 1) {
        previousPage();
      } else {
        gotoPage(newPage);
      }
    },
    [gotoPage, nextPage, pageIndex, previousPage]
  );

  const onChangeRowsPerPage = useCallback(
    (e) => {
      setPageSize(Number(e.target.value));
    },
    [setPageSize]
  );

  return rowCount ? (
    <MuiTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={rowCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelRowsPerPage="Zeilen pro Seite"
      labelDisplayedRows={({ from, to, count }) =>
        `${from} bis ${to} von ${count}`
      }
    />
  ) : (
    <></>
  );
};
