import React, { FC, useEffect } from 'react';
import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { Breadcrumbs } from '../layout/components/page/Breadcrumbs';
import { User, useUsersQuery } from '../../../query/UsersQuery';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  TextField,
} from '@material-ui/core';
import { Delete, Save } from '@material-ui/icons';
import { useAddUser } from '../../../query/AddUser';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useDeleteUser } from '../../../query/DeleteUser';

export const UsersPage: FC = () => {
  const { data: usersData } = useUsersQuery();
  const { register, watch, setValue } = useForm<User>();

  const { mutate: addUser, error } = useAddUser({
    mail: watch('mail'),
    role: watch('role'),
  });

  const { mutate: deleteUser } = useDeleteUser();

  useEffect(() => {
    register('mail');
    register('role');
    register('_id');

    setValue('role', 'User');
  }, []);

  return (
    <PageContentContainer>
      <PageTitle>Benutzer</PageTitle>
      <Breadcrumbs
        pageTitle="Benutzer"
        entries={[{ name: 'Dashboard', to: '/' }]}
      />
      <h2>Benutzer hinzufügen</h2>
      <Box
        style={{
          display: 'flex',
          width: '25%',
          height: 70,
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <div>
          <TextField
            label="E-Mail"
            value={watch('mail') || ''}
            onChange={(event) => setValue('mail', event.target.value)}
          />
          {error && (
            <p style={{ color: 'red', marginTop: 5, fontSize: '0.8em' }}>
              Diese E-Mail existiert bereits
            </p>
          )}
        </div>
        <Autocomplete
          style={{ width: 100 }}
          options={['User', 'Admin']}
          value={watch('role') || ''}
          onChange={(_, value) => setValue('role', value)}
          renderInput={(params) => <TextField {...params} label="Rolle" />}
        />

        <Button
          onClick={(event) => {
            event.preventDefault();
            addUser();
          }}
        >
          <Save />
        </Button>
      </Box>
      <Divider />
      <List>
        {usersData?.users?.map((user) => {
          return (
            <>
              <ListItem key={user._id}>
                <Box
                  style={{
                    display: 'flex',
                    width: '25%',
                    height: 50,
                    justifyContent: 'space-between',
                    alignContent: 'center',
                  }}
                >
                  <p style={{ width: '50%' }}>{user.mail}</p>
                  <p style={{ flexGrow: 1 }}>{user.role}</p>
                  {/* <Button>
                  <Save />
                </Button> */}
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      deleteUser({ _id: user._id });
                    }}
                  >
                    <Delete />
                  </Button>
                </Box>
              </ListItem>
              <Divider style={{ width: '30%' }} />
            </>
          );
        })}
      </List>
    </PageContentContainer>
  );
};
