import React, { FC, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Tooltip,
} from '@material-ui/core';
import { ViewColumn } from '@material-ui/icons';
import { TableInstance } from 'react-table';
import { Invoice } from '../../../../../dto/Invoice';

export interface ShowHideColumnsIconProps {
  instance: TableInstance<Invoice>;
}

export const ShowHideColumnsIcon: FC<ShowHideColumnsIconProps> = (props) => {
  const { instance } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(undefined);
  };

  const { toggleHideColumn, columns } = instance;

  return (
    <>
      <Tooltip title="Show/hide columns">
        <IconButton aria-label="show/hide columns" onClick={handleClick}>
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
      >
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {columns.map((column) => (
            <FormControlLabel
              style={{
                margin: 0,
                padding: 0,
              }}
              key={column.id}
              control={
                <Checkbox
                  checked={column.isVisible}
                  name={column.id}
                  size="small"
                  onChange={() => {
                    toggleHideColumn(column.id);
                  }}
                />
              }
              label={column.render('Header')}
            />
          ))}
        </Paper>
      </Popover>
    </>
  );
};
