import React, { FC } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import {
  endOfWeek,
  startOfWeek,
  startOfYear,
  endOfYear,
  subYears,
  startOfMonth,
  subMonths,
  endOfMonth,
  subWeeks,
  endOfDay,
} from 'date-fns';
import { Widget } from '../Widget';
import { Kpi } from '../../../../../dto/Kpi';

export interface SingleValueWidgetProps {
  label: string;
  value?: string;
  relativeChange?: number;
}

const SKONTO_QUERY = gql`
  query($startDate: Float, $endDate: Float) {
    kpiFive(startDate: $startDate, endDate: $endDate) {
      Kpi
      KpiDate
      Counter
      Netvalue
    }
  }
`;

const filterByDate = (startDate: Date, endDate: Date) => (
  kpi: Kpi
): boolean => {
  const date = Number.parseInt(kpi.KpiDate, 10);
  return startDate.getTime() <= date && endDate.getTime() >= date;
};

export const NichtGenutzterSkonto: FC<SingleValueWidgetProps> = (props) => {
  const { label } = props;

  const startDate = subYears(startOfYear(new Date()), 1);
  const endDate = endOfDay(new Date());

  const startOfLastYear = startDate;
  const endOfLastYear = endOfYear(startDate);
  const startOfThisYear = startOfYear(new Date());
  const endOfThisYear = endDate;
  const startOfLastMonth = subMonths(startOfMonth(new Date()), 1);
  const endOfLastMonth = endOfMonth(startOfLastMonth);
  const startOfLastWeek = subWeeks(startOfWeek(new Date()), 1);
  const endOfLastWeek = endOfWeek(startOfLastWeek);

  const { data } = useQuery<{ kpiFive: Kpi[] }>(SKONTO_QUERY, {
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
  });

  const result = data?.kpiFive || [];

  const skontoUnrealizedLastWeek = result
    .filter(filterByDate(startOfLastWeek, endOfLastWeek))
    .map((kpi) => kpi.Netvalue)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((a, b) => a + Number.parseFloat(b), 0);
  const skontoUnrealizedLastMonth = result
    .filter(filterByDate(startOfLastMonth, endOfLastMonth))
    .map((kpi) => kpi.Netvalue)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((a, b) => a + Number.parseFloat(b), 0);
  const skontoUnrealizedLastYear = result
    .filter(filterByDate(startOfLastYear, endOfLastYear))
    .map((kpi) => kpi.Netvalue)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((a, b) => a + Number.parseFloat(b), 0);
  const skontoUnrealizedThisYear = result
    .filter(filterByDate(startOfThisYear, endOfThisYear))
    .map((kpi) => kpi.Netvalue)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((a, b) => a + Number.parseFloat(b), 0);

  return (
    <Widget title={label} height={1.25} width={3}>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            value={`${skontoUnrealizedLastWeek.toFixed(2)} €`}
            label="Letzte Woche"
            fullWidth
            size="small"
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={`${skontoUnrealizedLastMonth.toFixed(2)} €`}
            label="Letzten Monat"
            fullWidth
            size="small"
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={`${skontoUnrealizedLastYear.toFixed(2)} €`}
            label="Dieses Jahr"
            fullWidth
            size="small"
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={`${skontoUnrealizedThisYear.toFixed(2)} €`}
            label="Letztes Jahr"
            fullWidth
            size="small"
            InputProps={{ disableUnderline: true, readOnly: true }}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};
