import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import { useStateMachine } from 'little-state-machine';
import styled from 'styled-components';
import { InvoiceAcceptModalBody } from './InvoiceAcceptModalBody';
import { InvoiceAcceptModalSummary } from './InvoiceAcceptModalSummary';
import { useFormContext } from 'react-hook-form';
import { useUsersQuery } from '../../../../../query/UsersQuery';
import { useUpdateInvoiceMutation } from '../../../../../query/UpdateInvoiceMutation';
import { updateSelectedInvoice } from '../../../../../stores/globalStore';

const SCModal = styled(Dialog)`
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  color: black;
`;

const SCBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface InvoiceAcceptModalProps {
  appendComment: any;
}

export const InvoiceAcceptModal: FC<InvoiceAcceptModalProps> = (props) => {
  const { appendComment } = props;
  const [open, setOpen] = useState(false);
  const [dataComplete, setDataComplete] = useState<boolean>(true);
  const { actions, state } = useStateMachine({ updateSelectedInvoice });

  const {
    register,
    getValues,
    setValue,
    errors,
    trigger,
    watch,
  } = useFormContext();

  const { mutate: mutateInvoice } = useUpdateInvoiceMutation(
    {
      ...state.selectedInvoice!,
      ...getValues(),
      positions: watch('positions'),
      positionsFi: watch('positionsFi'),
      comments: watch('comments'),
    },
    false
  );

  const { data: usersData } = useUsersQuery();

  const openModal = (): void => {
    console.log('VALUE', 'SETOPEN');
    mutateInvoice();
    setDataComplete(true);
    setOpen(true);
  };

  const [checkOpenModal, setCheckOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && checkOpenModal) {
      setCheckOpenModal(false);
      openModal();
    }
    if (checkOpenModal) {
      setCheckOpenModal(false);
    }
  }, [checkOpenModal]);

  const closeModal = (): void => {
    setOpen(false);
  };

  const areAcceptorsInMailList = (): boolean => {
    let isAcceptorAccountedFor = true;
    // eslint-disable-next-line unicorn/prefer-set-has
    const personsArray = usersData?.users?.map((mail) => {
      return mail.mail.toLowerCase();
    });
    getValues('acceptors').forEach((acceptor: string) => {
      // eslint-disable-next-line unicorn/prefer-ternary
      if (personsArray?.includes(acceptor.toLowerCase())) {
        isAcceptorAccountedFor = true;
      } else {
        isAcceptorAccountedFor = false;
      }
    });
    return isAcceptorAccountedFor;
  };

  const listOfAcceptorsNotInMailList = (): string[] => {
    const isAcceptorAccountedFor: string[] = [];
    // eslint-disable-next-line unicorn/prefer-set-has
    const personsArray = usersData?.users?.map((mail) => {
      return mail.mail;
    });
    getValues('acceptors').forEach((acceptor: string) => {
      // eslint-disable-next-line unicorn/prefer-ternary
      if (!personsArray?.includes(acceptor)) {
        isAcceptorAccountedFor.push(acceptor);
      }
    });
    return isAcceptorAccountedFor;
  };

  return (
    <>
      <Button
        autoFocus
        onClick={(_) => {
          trigger();
          setCheckOpenModal(true);
        }}
        color="secondary"
      >
        Rechnung abschließen
      </Button>
      <SCModal
        maxWidth={false}
        onClose={
          () => closeModal() /* actions.updateSelectedInvoice(undefined) */
        }
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>Rechnung bestätigen oder ablehnen</DialogTitle>
        {!areAcceptorsInMailList() && (
          <p style={{ color: 'red', marginLeft: '15px' }}>
            Nicht alle Bearbeiter sind in der Mailliste hinterlegt
          </p>
        )}
        <SCBodyWrapper>
          <InvoiceAcceptModalBody
            closeModal={closeModal}
            dataComplete={dataComplete}
            setDataComplete={setDataComplete}
            areAcceptorsInMailList={listOfAcceptorsNotInMailList}
            appendComment={appendComment}
          />
          <InvoiceAcceptModalSummary setDataComplete={setDataComplete} />
        </SCBodyWrapper>
      </SCModal>
    </>
  );
};
