import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';

const QUERY = gql`
  query {
    glList {
      CompCode
      GlAccount
      ShortText
      LongText
    }
  }
`;
export interface GlListData {
  glList: GlList[];
}
export interface GlList {
  CompCode: string;
  GlAccount: string;
  ShortText: string;
  LongText: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGlListQuery = (): UseQueryResult<GlListData, Error> => {
  return useQuery<GlListData, Error>('glList', async () => {
    const data = await graphQLClient.request(QUERY);
    return data;
  });
};
