import React from 'react';
import { CellProps } from 'react-table';
import { Entity } from '../../../../../dto/Entity';

export const NoClickCell = <TYPE extends Entity>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;
  if (value) {
    return (
      <div style={{ border: 'none', zIndex: 10 }}>
        <p
          style={{
            cursor: 'default',
            zIndex: -1,
          }}
        >
          {value}
        </p>
      </div>
    );
  }
  return <p>-</p>;
};
