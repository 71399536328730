import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const FormWrapperComponent = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export const FormWrapper = FormWrapperComponent;
