import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
/* import { useMsal } from '@azure/msal-react';
 */ import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { Breadcrumbs } from '../layout/components/page/Breadcrumbs';
import { InvoiceDetailModal } from '../components/dialog/InvoiceDetailModal/InvoiceDetailModal';
import { InvoiceTable } from '../tables/InvoiceTable';
import { useInvoicesQuery } from '../../../query/InvoicesQuery';
import { Invoice } from '../../../dto/Invoice';
import { useMsal } from '@azure/msal-react';
import { checkAuthorisation } from '../checkAuthorisation';
import { LinearProgress } from '@material-ui/core';

const filterData = (
  account: string,
  invoices?: Invoice[] | undefined
): Invoice[] | undefined => {
  if (!invoices) return undefined;
  const accountIntern = account.replace('@intern.', '@');
  return invoices.filter((invoice) => {
    console.log(
      invoice.acceptors.includes(account.toLowerCase()),
      invoice.mailObserver.includes(account.toLowerCase()),
      invoice.acceptors.includes(accountIntern.toLowerCase()),
      invoice.mailObserver.includes(accountIntern.toLowerCase()),
      checkAuthorisation(account.toLowerCase()),
      checkAuthorisation(accountIntern.toLowerCase())
    );
    return (
      invoice.acceptors.includes(account.toLowerCase()) ||
      invoice.mailObserver.includes(account.toLowerCase()) ||
      invoice.acceptors.includes(accountIntern.toLowerCase()) ||
      invoice.mailObserver.includes(accountIntern.toLowerCase()) ||
      checkAuthorisation(account.toLowerCase()) ||
      checkAuthorisation(accountIntern.toLowerCase())
    );
  });
};

export const IncomingInvoicesPage = (): JSX.Element => {
  const filterStatus = ['0', '8'];
  const { data, error, refetch, isLoading, isFetching } = useInvoicesQuery({
    invoiceStatus: filterStatus,
  });
  const { state } = useStateMachine();
  const { accounts } = useMsal();

  const filteredData = filterData(accounts[0].username!, data?.invoices);

  useEffect(() => {
    if (state.selectedInvoice === undefined) {
      refetch();
    }
  }, [state.selectedInvoice]);

  return (
    <PageContentContainer>
      <PageTitle>Rechnungseingang</PageTitle>
      <Breadcrumbs
        pageTitle="Rechnungseingang"
        entries={[{ name: 'Dashboard', to: '/' }]}
      />
      <div>
        {error && (
          <>
            <p style={{ color: 'red' }}>
              Derzeit besteht keine Verbindung zum Server
            </p>
          </>
        )}
        {(isLoading || isFetching) && <LinearProgress color="secondary" />}
        {data && <InvoiceTable invoiceData={filteredData!} />}
      </div>
      {state.selectedInvoice !== undefined && (
        <InvoiceDetailModal config="incomingInvoice" queryString="invoices" />
      )}
    </PageContentContainer>
  );
};

/* force push     */
