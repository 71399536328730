import React, { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enUS, de } from 'date-fns/locale';

const MuiPickersProviderComponent: FC = ({ children }) => {
  const localeMap = {
    en: enUS,
    de,
  } as { [key: string]: unknown };

  const locale = localeMap.de;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export const MuiPickersProvider = MuiPickersProviderComponent;
