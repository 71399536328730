import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { Workflow } from '../dto/Workflow';
import { graphQLClient } from './setup';
import { useWorkflowQuery } from './WorkflowQuery';

const QUERY = gql`
  mutation updateWorkflow(
    $workflowName: String
    $workflowPriority: String
    $mainNode: [MainNodeInput]
    $subNode: [SubNodeInput]
    $endNode: [String]
  ) {
    updateWorkflow(
      workflowName: $workflowName
      workflowPriority: $workflowPriority
      mainNode: $mainNode
      subNode: $subNode
      endNode: $endNode
    ) {
      _id
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateWorkflowMutation = (variables?: Workflow): any => {
  const { refetch } = useWorkflowQuery();
  return useMutation<Workflow, Error>(
    'workflow',
    async () => {
      const data = await graphQLClient.request(QUERY, variables);
      return data;
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
};
