import React, { FC, useMemo, useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  IconButton,
  makeStyles,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import { ViewList, KeyboardArrowUp } from '@material-ui/icons';
import {
  Column,
  useTable,
  useSortBy,
  Row,
  TableRowProps,
  Meta,
  useGroupBy,
  useExpanded,
  usePagination,
  useFilters,
  useGlobalFilter,
  IdType,
  TableState,
  useColumnOrder,
} from 'react-table';
import { useStateMachine } from 'little-state-machine';
import { Invoice } from '../../../dto/Invoice';
import { HeaderCell } from '../components/table/cells/HeaderCell';
import { DiscountCell } from '../components/table/cells/DiscountCell';
import { AccessorsCell } from '../components/table/cells/AccessorsCell';
import { SapTypeCell } from '../components/table/cells/SapTypeCell';
import { DateCell } from '../components/table/cells/DateCell';
import { NoClickCell } from '../components/table/cells/NoClickCell';
import { StatusCell } from '../components/table/cells/StatusCell';
import { HeadCheckCell } from '../components/table/cells/HeadCheckCell';
import { TablePagination } from '../components/table/TablePagination';
import { TableToolbar } from '../components/table/TableToolbar';
import { ShowHideColumnsIcon } from '../components/table/icons/ShowHideColumnsIcon';
import { FilterIcon } from '../components/table/icons/FilterIcon';
import { FilterRow } from '../components/table/FilterRow';
import { TextFilter } from '../components/table/filters/TextFilter';
import {
  GlobalFilterValue,
  GlobalSearchField,
} from '../components/table/GlobalSearchField';
import { SaveIcon } from '../components/table/icons/SaveIcon';
import { LoadIcon } from '../components/table/icons/LoadIcon';
import {
  OptionFilter,
  filterOptions,
} from '../components/table/filters/OptionFilter';
import { updateSelectedInvoice } from '../../../stores/globalStore';
import { useMsal } from '@azure/msal-react';
import { useInvoicesDetailQuery } from '../../../query/InvoicesQueryDetail';
import { queryClient } from '../../../query/setup';
import { setDate } from 'date-fns/esm';

export interface InvoiceTableProps {
  invoiceData: Invoice[];
}

export const useStyles = makeStyles(() =>
  createStyles({
    iconDirectionAsc: {
      transform: 'rotate(90deg)',
    },
    iconDirectionDesc: {
      transform: 'rotate(180deg)',
    },
    cellIcon: {
      '& svg': {
        width: 16,
        height: 16,
        marginTop: 3,
      },
    },
  })
);

export interface InvoiceData {
  invoices: Invoice[];
}

export const InvoiceTable: FC<InvoiceTableProps> = (props) => {
  const { invoiceData } = props;
  const { accounts } = useMsal();
  const [fetchInvoiceDetail, setFetchInvoiceDetail] = useState<
    Invoice | undefined
  >(undefined);
  const [
    fetchInvoiceDetailForcer,
    setFetchInvoiceDetailForcer,
  ] = useState<boolean>(false);

  const { actions } = useStateMachine({
    updateSelectedInvoice,
  });

  const {
    data: invoiceDetailData,
    refetch: invoiceDetailRefetch,
    isFetching: invoiceDetailIsFetching,
    isLoading: invoiceDetailIsLoading,
    status,
  } = useInvoicesDetailQuery({
    _id: fetchInvoiceDetail?._id,
  });

  const [time, setTime] = useState<any>();

  useEffect(() => {
    actions.updateSelectedInvoice(undefined);
  }, []);

  useEffect(() => {
    if (fetchInvoiceDetailForcer) {
      console.log('TWO');
      console.log(Date.now() - time);
      queryClient.removeQueries('invoiceDetail');
      console.log('THREE');
      console.log(Date.now() - time);
      invoiceDetailRefetch();
      setFetchInvoiceDetailForcer(false);
    }
    if (fetchInvoiceDetail !== undefined && invoiceDetailData === undefined) {
      setFetchInvoiceDetail(undefined);
    }
    console.log(status);
    console.log(Date.now() - time);
  }, [
    fetchInvoiceDetail,
    invoiceDetailData,
    invoiceDetailIsFetching,
    fetchInvoiceDetailForcer,
  ]);

  useEffect(() => {
    if (status === 'success' && !invoiceDetailIsFetching) {
      actions.updateSelectedInvoice(invoiceDetailData?.invoice);
    }
  }, [status]);

  const getCustomRowProps = (
    rowProps: Partial<TableRowProps>,
    meta: Meta<Invoice, { row: Row<Invoice> }>
  ): Record<string, unknown> => {
    return {
      ...rowProps,
      onClick: () => {
        if (meta.row.original === undefined) return;
        setTime(Date.now());
        setFetchInvoiceDetailForcer(true);
        setFetchInvoiceDetail(meta.row.original);
      },
      style: {
        ...rowProps.style,
        cursor: 'pointer',
        zIndex: 0,
        pointerEvents: 'none',
      },
    };
  };

  const columns = React.useMemo(
    () =>
      ([
        {
          Header: 'Belegart',
          accessor: 'sapType',
          Cell: SapTypeCell,
          Filter: TextFilter,
        },
        {
          Header: 'Lief.Nr.',
          accessor: 'creditorId',
          Filter: TextFilter,
        },
        {
          Header: 'Lieferant',
          accessor: 'creditorName',
          Filter: TextFilter,
        },
        {
          Header: 'Status',
          accessor: 'invoiceStatus',
          Cell: StatusCell,
          Filter: OptionFilter,
          filter: 'filterOptions',
        },
        {
          Header: 'Rechnungsdatum',
          accessor: (row: any) => {
            if (typeof row.invoiceDate === 'string') {
              const formattedDate = new Date(
                Number.parseInt(row.invoiceDate, 10)
              ).toISOString();
              return formattedDate.slice(0, 10);
            }
            return undefined;
          } /* 'invoiceDate' */,
          Cell: DateCell,
          Filter: TextFilter,
        },
        {
          Header: 'Eingangsdatum',
          accessor: (row: any) => {
            if (typeof row.received === 'string') {
              const formattedDate = new Date(
                Number.parseInt(row.received, 10)
              ).toISOString();
              return formattedDate.slice(0, 10);
            }
            return undefined;
          } /* 'received' */,
          Cell: DateCell,
          Filter: TextFilter,
        },
        {
          Header: 'Rechnungsnr.',
          accessor: 'invoiceNr',
          Cell: NoClickCell,
          Filter: TextFilter,
        },
        {
          Header: 'Brutto',
          accessor: 'grossTotalInvoice',
          Cell: NoClickCell,
          Filter: TextFilter,
        },
        {
          Header: 'Belegnummer',
          accessor: 'sapInvoiceNumber',
          Cell: NoClickCell,
          Filter: TextFilter,
        },
        {
          Header: 'Adresse',
          accessor: 'credentialsCheck',
          Cell: HeadCheckCell,
          Filter: TextFilter,
        },
        {
          Header: 'Skonto',
          accessor: 'discountBool',
          Cell: DiscountCell,
          Filter: TextFilter,
        },
        {
          Header: 'Freigeber',
          accessor: 'acceptors',
          Cell: AccessorsCell,
          Filter: TextFilter,
        },
        {
          Header: 'Freigegeben',
          accessor: 'acceptorsHistory',
          Cell: AccessorsCell,
          Filter: TextFilter,
        },
      ] as unknown) as Column<Invoice>[],
    []
  );

  const globalFilter = (
    rows: Row<Invoice>[],
    columnIds: IdType<Invoice>[],
    filterValue: GlobalFilterValue
  ): Row<Invoice>[] => {
    return rows.filter((row) => {
      let searchResult = true;
      if (filterValue.search) {
        const searchTerm = filterValue.search?.toLocaleLowerCase();
        const creditorIdTagContainsSearchTerm =
          row.original.creditorIdTag &&
          row.original.creditorIdTag.toLocaleLowerCase().includes(searchTerm);
        const grossTotalContainsSearchTerm =
          row.original.grossTotal &&
          row.original.grossTotal
            .toString()
            .toLocaleLowerCase()
            .includes(searchTerm);
        const invoiceStatusContainsSearchTerm =
          row.original.invoiceStatus &&
          row.original.invoiceStatus.toLocaleLowerCase().includes(searchTerm);
        const invoiceDateContainsSearchTerm =
          row.original.invoiceDate &&
          row.original.invoiceDate.toLocaleLowerCase().includes(searchTerm);
        const sapInvoiceNumberContainsSearchTerm =
          row.original.sapInvoiceNumber &&
          row.original.sapInvoiceNumber
            .toString()
            .toLocaleLowerCase()
            .includes(searchTerm);
        const invoiceNrContainsSearchTerm =
          row.original.invoiceNr &&
          row.original.invoiceNr.toLocaleLowerCase().includes(searchTerm);
        const discountValueContainsSearchTerm =
          row.original.discountValue &&
          row.original.discountValue.toLocaleLowerCase().includes(searchTerm);
        const taxAmountsContainsSearchTerm =
          row.original.taxAmounts &&
          row.original.taxAmounts
            .toString()
            .toLocaleLowerCase()
            .includes(searchTerm);

        searchResult =
          creditorIdTagContainsSearchTerm ||
          grossTotalContainsSearchTerm ||
          invoiceStatusContainsSearchTerm ||
          invoiceDateContainsSearchTerm ||
          sapInvoiceNumberContainsSearchTerm ||
          invoiceNrContainsSearchTerm ||
          discountValueContainsSearchTerm ||
          taxAmountsContainsSearchTerm;
      }
      return searchResult;
    });
  };

  const getInitialState = (load = true): Partial<TableState> => {
    if (load) {
      try {
        const storedState = localStorage.getItem('stored-states');
        if (storedState) {
          return JSON.parse(storedState).latest;
        }
      } catch {
        // do nothing
      }
    }
    return {
      pageSize: 50,
      pageIndex: 0,
      hiddenColumns: ['received'],
      sortBy: [
        {
          id: 'sapInvoiceNumber',
          desc: true,
        },
      ],
    };
  };

  const storeState = (newState: TableState<Invoice>): TableState<Invoice> => {
    try {
      const states = JSON.parse(localStorage.getItem('stored-states') || '{}');
      states.latest = newState;
      localStorage.setItem('stored-states', JSON.stringify(states));
    } catch (error_) {
      // eslint-disable-next-line no-console
      console.error('Error interacting with localstorage', error_);
    }
    return newState;
  };

  const tableInstance = useTable<Invoice>(
    {
      columns,
      data: invoiceData,
      initialState: {
        ...getInitialState(true),
      },
      globalFilter,
      stateReducer: storeState,
      filterTypes: useMemo(
        () => ({
          filterOptions,
        }),
        []
      ),
    },
    useFilters,
    useGlobalFilter,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { filters },
    setAllFilters,
  } = tableInstance;

  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(/* filters?.length > 0 */ true);

  const toggleFilter = (): void => {
    setAllFilters([]);
    setShowFilter(!showFilter);
  };

  return (
    <div>
      <Paper>
        <TableToolbar>
          <span style={{ flex: 1 }} />
          {/*         <GlobalSearchField instance={tableInstance} />
           */}
          {/*         <FilterIcon active={showFilter} toggleFilter={toggleFilter} />
           */}{' '}
          <ShowHideColumnsIcon instance={tableInstance} />
          <SaveIcon instance={tableInstance} />
          <LoadIcon
            instance={tableInstance}
            initialState={getInitialState(false)}
          />
        </TableToolbar>
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead style={{ backgroundColor: '#f0f1f2' }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps({
                        style: { position: 'relative', whiteSpace: 'nowrap' },
                      })}
                    >
                      {column.canGroupBy && (
                        <IconButton
                          {...column.getGroupByToggleProps()}
                          size="small"
                          style={{
                            backgroundColor: column.isGrouped
                              ? 'lightgrey'
                              : undefined,
                            marginRight: 8,
                          }}
                        >
                          <ViewList />
                        </IconButton>
                      )}
                      {column.canSort && (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          {...column.getSortByToggleProps()}
                        >
                          <div>
                            <HeaderCell>{column.render('Header')}</HeaderCell>

                            {column.isSorted ? (
                              <span
                                style={{
                                  border: 0,
                                  clip: 'rect(0 0 0 0)',
                                  height: 1,
                                  margin: -1,
                                  overflow: 'hidden',
                                  padding: 0,
                                  position: 'absolute',
                                  top: 20,
                                  width: 1,
                                }}
                              >
                                {column.isSortedDesc
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : undefined}
                          </div>
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {showFilter && <FilterRow instance={tableInstance} />}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <TableRow
                    {...row.getRowProps(getCustomRowProps)}
                    /* style={
                    row.values.invoiceStatus === '0'
                      ? {
                          borderLeftStyle: 'solid',
                          borderLeftColor: 'red',
                          borderLeftWidth: '6px',
                        }
                      : undefined
                  } */
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell key={Math.random()}>
                          {cell.isGrouped ? (
                            <>
                              <TableSortLabel
                                classes={{
                                  iconDirectionAsc: classes.iconDirectionAsc,
                                  iconDirectionDesc: classes.iconDirectionDesc,
                                }}
                                active
                                direction={row.isExpanded ? 'desc' : 'asc'}
                                IconComponent={KeyboardArrowUp}
                                {...row.getToggleRowExpandedProps()}
                                className={classes.cellIcon}
                              />{' '}
                              {cell.render('Cell')} ({row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? undefined : (
                            cell.render('Cell')
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination instance={tableInstance} />
      </Paper>
    </div>
  );
};
