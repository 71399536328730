import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Settings } from '../../../../dto/Settings';
import { Label } from '../../../../dto/Label';
import { TextField } from '../inputFields/TextField';

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 1;
  grid-row: 3;
  margin: 20px;
`;

interface EmailSetupProps {
  settings: Settings;
  createChangeHandler: (id: string, value: string) => void;
  updateSettings: () => void;
}

const FormElementStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EmailSetup = (props: EmailSetupProps): ReactElement => {
  const { settings, createChangeHandler, updateSettings } = props;

  const clickEvent = (): /*     event: React.MouseEvent<HTMLButtonElement, MouseEvent>
   */ void => {
    updateSettings();
  };

  const fields: Label[] = [
    {
      id: 'mailAddress',
      label: 'E-Mail',
      fieldType: TextField({
        id: 'mailAddress',
        label: 'E-Mail',
        defaultValue: settings.mailSettings
          ? settings.mailSettings.mailAddress
          : undefined,
        createChangeHandler,
      }),
    },
    /*  {
      id: 'mailUserName',
      label: 'Benutzername',
      fieldType: TextField({
        placeHolder: 'Benutzername',
        defaultValue: data ? data.settings[0].host : '',
      }),
    },
    {
      id: 'imapHost',
      label: 'Imap Host',
      fieldType: TextField({
        placeHolder: 'Imap Host',
        defaultValue: data ? data.settings[0].password : '',
        type: 'password',
      }),
    },
    {
      id: 'smtpHost',
      label: 'Smtp Host',
      fieldType: TextField({
        placeHolder: 'Smtp Host',
        defaultValue: data ? data.settings[0].mailAddress : '',
      }),
    },
    {
      id: 'imapPort',
      label: 'Imap Port',
      fieldType: TextField({
        placeHolder: 'Imap Port',
        defaultValue: data ? data.settings[0].host : '',
      }),
    },
    {
      id: 'smtpPort',
      label: 'Smtp Port',
      fieldType: TextField({
        placeHolder: 'Smtp Port',
        defaultValue: data ? data.settings[0].password : '',
        type: 'password',
      }),
    },
    {
      id: 'password',
      label: 'Passwort',
      fieldType: TextField({
        placeHolder: 'Passwort',
        defaultValue: data ? data.settings[0].password : '',
        type: 'password',
      }),
    }, */
  ];

  return (
    <FormStyled>
      <h1>Email Einstellungen</h1>
      <div>
        {settings &&
          fields.map((obj) => {
            return <FormElementStyled>{obj.fieldType}</FormElementStyled>;
          })}
      </div>
      <Button onClick={(/* event */) => clickEvent(/* event */)}>
        Speichern
      </Button>
    </FormStyled>
  );
};
