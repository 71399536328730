import { useState, useEffect } from 'react';

export interface WindowSize {
  width: number;
  height: number;
}

const isClient = (): boolean => {
  return typeof window === 'object';
};

const getSize = (): WindowSize => {
  return {
    width: isClient() ? window.innerWidth : 500,
    height: isClient() ? window.innerHeight : 500,
  };
};

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    const handleResize = (): void => {
      try {
        setWindowSize(getSize());
      } catch {
        // full screen error
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};
