import React, { useEffect } from 'react';
/* import { useMsal } from '@azure/msal-react';
 */ import { useStateMachine } from 'little-state-machine';
import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { Breadcrumbs } from '../layout/components/page/Breadcrumbs';
import { InvoiceDetailModal } from '../components/dialog/InvoiceDetailModal/InvoiceDetailModal';
import { InvoiceTable } from '../tables/InvoiceTable';
import { useParkedInvoicesQuery } from '../../../query/ParkedInvoicesQuery';
import { Invoice } from '../../../dto/Invoice';
import { useMsal } from '@azure/msal-react';
import { checkAuthorisation } from '../checkAuthorisation';
import { LinearProgress } from '@material-ui/core';

const filterData = (
  account: string,
  invoices?: Invoice[] | undefined
): Invoice[] | undefined => {
  if (!invoices) return undefined;
  const accountIntern = account.replace('@intern.', '@');
  return invoices.filter((invoice) => {
    return (
      invoice.acceptors.includes(account) ||
      invoice.mailObserver.includes(account) ||
      invoice.acceptors.includes(accountIntern) ||
      invoice.mailObserver.includes(accountIntern) ||
      checkAuthorisation(account.toLowerCase()) ||
      checkAuthorisation(accountIntern.toLowerCase())
    );
  });
};

export const ParkedInvoicesPage = (): JSX.Element => {
  const filterStatus = ['4'];
  const {
    data,
    error,
    refetch,
    isLoading,
    isFetching,
  } = useParkedInvoicesQuery({
    invoiceStatus: filterStatus,
  });
  const { state } = useStateMachine();
  const { accounts } = useMsal();

  useEffect(() => {
    if (state.selectedInvoice === undefined) {
      refetch();
    }
  }, [state.selectedInvoice]);

  const filteredData = filterData(accounts[0].username!, data?.invoices);

  return (
    <PageContentContainer>
      <PageTitle>Vorerfasste Rechnungen</PageTitle>
      <Breadcrumbs
        pageTitle="Vorerfasst"
        entries={[{ name: 'Dashboard', to: '/' }]}
      />
      <div>
        {error && (
          <>
            <p style={{ color: 'red' }}>
              Derzeit besteht keine Verbindung zum Server
            </p>
          </>
        )}
        {(isLoading || isFetching) && <LinearProgress color="secondary" />}
        {data && <InvoiceTable invoiceData={filteredData!} />}
      </div>
      {state.selectedInvoice !== undefined && (
        <InvoiceDetailModal
          config="parkedInvoices"
          queryString="parkedInvoices"
        />
      )}
    </PageContentContainer>
  );
};
