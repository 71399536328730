import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';

const QUERY = gql`
  query {
    users {
      _id
      mail
      role
    }
  }
`;

export interface UsersData {
  users?: User[];
}
export interface User {
  _id?: string;
  mail: string;
  role: string;
}

export const useUsersQuery = (): UseQueryResult<UsersData, Error> => {
  return useQuery<UsersData, Error>('users', async () => {
    const data = await graphQLClient.request(QUERY);
    return data;
  });
};

/* export const MailQuery = [
  {
    mail: 'marco.pichler@hitzinger.at',
  },
  {
    mail: 'sebastian.schneeberger@hitzinger.at',
  },
  {
    mail: 'schulung1@hitzinger.at',
  },
  {
    mail: 'bernhard.blacher@hitzinger.at',
  },
  {
    mail: 'jaimy-lee.schopper@hitzinger.at',
  },
  {
    mail: 'murat.aslan@hitzinger.at',
  },
  {
    mail: 'anyu.vonpanderlee@hitzinger.at',
  },
  {
    mail: 'jessica.aigner-shamanov@hitzinger.at',
  },
  {
    mail: 'nandor.szeibel@hitzinger.at',
  },
  {
    mail: 'mihaela.bankovic@hitzinger.at',
  },
  {
    mail: 'gernot.eybl@hitzinger.at',
  },
  {
    mail: 'juergen.weissenboeck@hitzinger.at',
  },
  {
    mail: 'gerald.leitner@hitzinger.at',
  },
  {
    mail: 'gregor.bauer@hitztinger.at',
  },
  {
    mail: 'vera.leitner-fischlauer@hitzinger.at',
  },
  {
    mail: 'jasmine.unter@hitzinger.at',
  },
  {
    mail: 'michael.kahr@hitzinger.at',
  },
  {
    mail: 'herbert.schrattenecker@hitzinger.at',
  },
  {
    mail: 'werner.gammer@hitzinger.at',
  },
  {
    mail: 'heinz-peter.freilinger@hitzinger.at',
  },
  {
    mail: 'peter.oberpeilsteiner@hitzinger.at',
  },
  {
    mail: 'attila.both@hitzinger.at',
  },
  {
    mail: 'daniel.lengauer@hitzinger.at',
  },
  {
    mail: 'michael.pirklbauer@hitzinger.at',
  },
  {
    mail: 'klaus.seimayr@hitzinger.at',
  },
  {
    mail: 'herfried.pichler@hitzinger.at',
  },
  {
    mail: 'dietmar.pfeiffer@hitzinger.at',
  },
  {
    mail: 'thomas.pleli@hitzinger.at',
  },
  {
    mail: 'bettina.troebinger@hitzinger.at',
  },
  {
    mail: 'benedikt.haider@hitzinger.at',
  },
  {
    mail: 'gerhard.kellermann@hitzinger.at',
  },
  {
    mail: 'susanne.siedl@hitzinger.at',
  },
  {
    mail: 'steven.breier@hitzinger.at',
  },
  {
    mail: 'andreas.weinmueller@hitzinger.at',
  },
  {
    mail: 'juergen.gruber@hitzinger.at',
  },
  {
    mail: 'tamara.schrenk@hitzinger.at',
  },
  {
    mail: 'sabrina.hawel@hitzinger.at',
  },
  {
    mail: 'kurt.taferner@hitzinger.at',
  },
  {
    mail: 'ursula.schabes@hitzinger.at',
  },
  {
    mail: 'markus.standhartinger@hitzinger.at',
  },
  {
    mail: 'wolfgang.krenn@hitzinger.at',
  },
  {
    mail: 'siegfried.seimair@hitzinger.at',
  },
  {
    mail: 'richard.prammer@hitzinger.at',
  },
  {
    mail: 'peter.oberpeilsteiner@hitzinger.at',
  },
  {
    mail: 'michael.kopatsch@hitzinger.at',
  },
  {
    mail: 'daniel.lengauer@hitzinger.at',
  },
  {
    mail: 'attila.both@hitzinger.at',
  },
  {
    mail: 'thomas.schmidt2@hitzinger.at',
  },
  {
    mail: 'mario.rapo@hitzinger.at',
  },
  {
    mail: 'markus.draxler@hitzinger.at',
  },
  {
    mail: 'maria.scharnboeck@hitzinger.at',
  },
  {
    mail: 'volker.schmid@hitzinger.at',
  },
  {
    mail: 'roland.kappler@hitzinger.at',
  },
  {
    mail: 'renate.czizek@hitzinger.at',
  },
  {
    mail: 'markus.ivo@hitzinger.at',
  },
  {
    mail: 'marco.cruz@hitzinger.at',
  },
  {
    mail: 'sandra.hartl@hitzinger.at',
  },
  {
    mail: 'wolfgang.stallinger@hitzinger.at',
  },
  {
    mail: 'markus.wilflingseder@hitzinger.at',
  },
  {
    mail: 'sasa.milisavljevic@hitzinger.at',
  },
  {
    mail: 'ivan.brkanovic@hitzinger.at',
  },
  {
    mail: 'barbara.theiss@hitzinger.at',
  },
  {
    mail: 'manfred.guetl@hitzinger.at',
  },
  {
    mail: 'martin.draxler@hitzinger.at',
  },
  {
    mail: 'markus.hanreich@hitzinger.at',
  },
  {
    mail: 'anna.heinle@hitzinger.at',
  },
  {
    mail: 'rigobert.schierl@hitzinger.at',
  },
  {
    mail: 'nikola.pesa@hitzinger.at',
  },
  {
    mail: 'berthold.holz@hitzinger.at',
  },
  {
    mail: 'hannes.seilinger@hitzinger.at',
  },
  {
    mail: 'lisa.staudinger@hitzinger.at',
  },
  {
    mail: 'werner.muttenthaler@hitzinger.at',
  },
];
 */
