import React, { FC, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { TableInstance } from 'react-table';
import { Invoice } from '../../../../../dto/Invoice';

export interface SaveIconProps {
  instance: TableInstance<Invoice>;
}

export const SaveIcon: FC<SaveIconProps> = (props) => {
  const { instance } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
  const [stateName, setStateName] = useState('');
  const { state } = instance;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleSave = (): void => {
    try {
      const states = JSON.parse(localStorage.getItem('stored-states') || '{}');
      states[stateName] = state;
      localStorage.setItem('stored-states', JSON.stringify(states));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error interacting with localstorage', error);
    }
    handleClose();
  };

  const handleClose = (): void => {
    setAnchorElement(undefined);
    setStateName('');
  };

  return (
    <>
      <Tooltip title="Tabellenkonfiguration speichern">
        <IconButton aria-label="save table configuration" onClick={handleClick}>
          <Save />
        </IconButton>
      </Tooltip>
      <Dialog open={Boolean(anchorElement)} onClose={handleClose}>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogTitle>Tabellenkonfiguration speichern</DialogTitle>
          <DialogContent>
            <TextField
              value={stateName}
              onChange={(event) => {
                setStateName(event.target.value);
              }}
              fullWidth
              label="Bezeichnung"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSave}
              fullWidth
              color="primary"
              variant="contained"
            >
              Speichern
            </Button>
            <Button onClick={handleClose} fullWidth variant="outlined">
              Abbrechen
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};
