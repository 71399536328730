import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AdminRoutes } from '../modules/admin/config/AdminRoutes';
import { Route as RouteConfig } from './Route';

import { DashboardLayout } from '../modules/admin/layout/Dashboard';

import { AuthLayout } from '../modules/auth/layout/Auth';
import { AuthenticationCheck } from '../AuthenticationCheck';
import { AfterLogoutPage } from '../modules/auth/pages/AfterLogout';
import { LoginPage } from '../modules/auth/pages/Login';
import { Page404 } from '../pages/Page404';

const childRoutes = (
  Layout: React.FC,
  routes: RouteConfig[]
): (React.ReactElement | React.ReactElement[] | null)[] => {
  return routes.map(
    ({
      children,
      path,
      component: Component,
      exact = true,
      authRequired = false,
    }) => {
      // Route item with children
      if (children && children.length > 0) {
        // eslint-disable-next-line no-shadow
        return children.map(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ({ path, component: Component }) => {
            return (
              <Route
                key={path}
                path={path}
                exact
                render={(props) => (
                  <>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {authRequired ? (
                      <AuthenticationCheck>
                        <Layout>
                          <Component {...props} />
                        </Layout>
                      </AuthenticationCheck>
                    ) : (
                      <Layout>
                        <Component {...props} />
                      </Layout>
                    )}
                  </>
                )}
              />
            );
          }
        );
      }

      if (Component) {
        // Route item without children
        return (
          <Route
            key={path}
            path={path}
            exact={exact}
            render={(props) => (
              <>
                {authRequired ? (
                  <AuthenticationCheck>
                    <Layout>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading,@typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      <Component {...props} />
                    </Layout>
                  </AuthenticationCheck>
                ) : (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                )}
              </>
            )}
          />
        );
      }

      return <></>;
    }
  );
};

export const Routes = (): React.ReactElement => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, AdminRoutes)}
      <Route path="/logout">
        <AfterLogoutPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route
        render={(props) => (
          <AuthLayout>
            <Page404 {...props} />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);
