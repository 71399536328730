import React, { FC } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Grid,
  Hidden,
  IconButton,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { MenuIcon } from './header/MenuIcon';

const SAP_SYSTEM_QUERY = gql`
  query {
    sapSystem {
      system
    }
  }
`;

interface System {
  system: string;
}

interface SapSystem {
  sapSystem: System;
}

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const SystemType = styled.div`
  color: white;
  margin: 20px;
  width: 180px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  background: red;
  font-weight: 1000;
`;

interface HeaderProps {
  onMenuIconClick(): void;
}

const HeaderComponent: FC<HeaderProps> = (props) => {
  const { data } = useQuery<SapSystem>(SAP_SYSTEM_QUERY);
  const { onMenuIconClick } = props;
  const { width } = useWindowSize();
  const { instance, accounts } = useMsal();

  const { name } = (accounts[0] as AccountInfo) || { name: '' };

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar disableGutters={(width || 1) < 400}>
        {data && data.sapSystem.system !== 'P' && (
          <SystemType>
            <p>SAP System: {data.sapSystem.system}</p>
          </SystemType>
        )}
        <Grid container alignItems="center">
          <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
            <Hidden lgUp implementation="css">
              <MenuIcon onClick={onMenuIconClick} />
            </Hidden>
            <div style={{ flexGrow: 1 }} />
            <div>{name}</div>
            <IconButton
              onClick={() => {
                instance.logout();
              }}
            >
              <ExitToApp />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export const Header = HeaderComponent;
