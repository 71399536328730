import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';

const QUERY = gql`
  query {
    costcenter {
      CoArea
      Costcenter
      CocntrTxt
    }
  }
`;
export interface CostcenterData {
  costcenter: Costcenter[];
}

export interface Costcenter {
  CoArea: string;
  Costcenter: string;
  CocntrTxt: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCostcenterQuery = (): UseQueryResult<CostcenterData, Error> => {
  return useQuery<CostcenterData, Error>('costcenter', async () => {
    const data = await graphQLClient.request(QUERY);
    return data;
  });
};
