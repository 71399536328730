import styled from 'styled-components';
import { Toolbar } from '@material-ui/core';

export const TableToolbar = styled(Toolbar)`
  > * {
    margin-left: 0;
    margin-right: 0;
  }
  ,
  > *:first-child {
    margin-left: 0;
  }
  ,
  > *:last-child {
    margin-right: 0;
  }
`;
