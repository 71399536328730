import { Autocomplete } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { FilterProps, Row } from 'react-table';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Invoice } from '../../../../../dto/Invoice';

export interface Option {
  value: string;
  label: string;
}

const options = [
  {
    value: '0',
    label: 'Neu',
  },
  {
    value: '1',
    label: 'In Bearbeitung',
  },
  /*  {
    value: '2',
    label: 'Zur Freigabe',
  }, */
  {
    value: '2',
    label: 'Gebucht',
  },
  {
    value: '3',
    label: 'Ungültig',
  },
  {
    value: '4',
    label: 'Vorerfasst',
  },
  {
    value: '5',
    label: 'Buchung Fehlgeschlagen',
  },
  {
    value: '7',
    label: 'kein Wareneingang',
  },
  {
    value: '8',
    label: 'Duplikat',
  },
  {
    value: '9',
    label: 'Abgelehnt',
  },
  {
    value: '10',
    label: 'Freigegeben',
  },
  {
    value: '11',
    label: 'HEP',
  },
  {
    value: '12',
    label: 'VZ',
  },
];

export const filterOptions = (
  rows: Row<Invoice>[],
  columnIds: string[],
  filterValue: Option[]
): Row<Invoice>[] => {
  const acceptedIds = new Set(filterValue.map((filter) => filter.value));
  return rows.filter((row) => {
    const value = row.values[columnIds[0]];
    if (Array.isArray(value)) {
      const ids = [] as string[];
      value.forEach((entry: unknown) => {
        ids.push(entry as string);
      });
      // TODO
    }
    return acceptedIds.has(value);
  });
};

filterOptions.autoRemove = (val: Option[]) => {
  return !val || val.length === 0;
};

export const OptionFilter = <TYPE extends Invoice>(
  props: FilterProps<TYPE>
): ReactElement => {
  const { column } = props;
  const { filterValue, setFilter, preFilteredRows, id } = column;

  const existingValues = React.useMemo(() => {
    const set = new Set<string>();
    preFilteredRows.forEach((row) => {
      if (Array.isArray(row.values[id])) {
        row.values[id].forEach((value: string) => {
          set.add(value);
        });
      } else {
        set.add(row.values[id]);
      }
    });
    return [...set.values()];
  }, [id, preFilteredRows]);

  const existingOptions = options.filter((option) =>
    existingValues.includes(option.value)
  );

  return (
    <Autocomplete<Option, true, false, false>
      options={existingOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(a, b) => a.value === b.value}
      defaultValue={filterValue}
      renderOption={(option, { selected }) => {
        return (
          <FormControlLabel
            style={{
              margin: 0,
              padding: 0,
              whiteSpace: 'nowrap',
            }}
            control={
              <Checkbox checked={selected} name="checkedA" size="small" />
            }
            label={option.label}
          />
        );
      }}
      renderInput={(params) => {
        const { InputProps } = params;

        return (
          <TextField
            {...params}
            InputProps={{
              ...InputProps,
            }}
            label=""
          />
        );
      }}
      limitTags={1}
      renderTags={(T, getTagProps) => {
        return T.map((tag, index) => {
          if (index < T.length - 1) {
            return (
              <>
                <span
                  {...getTagProps({ index })}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {tag.label}
                </span>
                <span>, </span>
              </>
            );
          }
          return <span style={{ whiteSpace: 'nowrap' }}>{tag.label}</span>;
        });
      }}
      multiple
      onChange={(event, value) => {
        setFilter(value);
      }}
    />
  );
};
