import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { useStateMachine } from 'little-state-machine';
import { Invoice } from '../dto/Invoice';
import { graphQLClient, queryClient } from './setup';
import { updateSelectedInvoice } from '../stores/globalStore';

const QUERY = gql`
  mutation UpdateInvoice(
    $_id: ID!
    $creditorId: String
    $creditorIdTag: String
    $creditorName: String
    $invoiceNr: String
    $invoiceDate: String
    $bookingDate: String
    $taxTypes: [String]
    $taxAmounts: [String]
    $grossTotal: String
    $grossTotalInvoice: String
    $netTotal: String
    $sapInvoiceNumber: String
    $discountBool: Boolean
    $discountValue: Float
    $invoiceStatus: String
    $incomingDate: String
    $docType: String
    $orderNr: String
    $supplierId: String
    $positions: [InvoicePositionInput]
    $positionsFi: [InvoicePositionFiInput]
    $sapType: String
    $workflowType: String
    $workflowStep: String
    $mailEkGr: String
    $mailBanf: String
    $mailObserver: [String]
    $acceptors: [String]
    $lock: String
    $currency: String
    $seperateCosts: String
    $dismissText: String
    $customText: String
    $blockZTerm: Boolean
    $zTerm: ZTermInput
    $acceptorsHistory: [String]
    $subsequent: Boolean
    $comments: [CommentInput]
    $blockAutoWorkflow: Boolean
    $creditorStreet: String
    $creditorHouseNr: String
    $creditorCountry: String
    $creditorCity: String
    $creditorPLZ: String
    $creditorIBAN: String
    $creditorBank: String
    $startSkontoDate: String
    $skontoAmount: String
  ) {
    updateInvoice(
      _id: $_id
      creditorId: $creditorId
      creditorIdTag: $creditorIdTag
      creditorName: $creditorName
      invoiceNr: $invoiceNr
      invoiceDate: $invoiceDate
      bookingDate: $bookingDate
      taxTypes: $taxTypes
      taxAmounts: $taxAmounts
      grossTotal: $grossTotal
      grossTotalInvoice: $grossTotalInvoice
      netTotal: $netTotal
      sapInvoiceNumber: $sapInvoiceNumber
      discountBool: $discountBool
      discountValue: $discountValue
      invoiceStatus: $invoiceStatus
      incomingDate: $incomingDate
      docType: $docType
      orderNr: $orderNr
      supplierId: $supplierId
      positions: $positions
      positionsFi: $positionsFi
      sapType: $sapType
      workflowType: $workflowType
      workflowStep: $workflowStep
      mailEkGr: $mailEkGr
      mailBanf: $mailBanf
      mailObserver: $mailObserver
      acceptors: $acceptors
      lock: $lock
      currency: $currency
      seperateCosts: $seperateCosts
      dismissText: $dismissText
      customText: $customText
      blockZTerm: $blockZTerm
      zTerm: $zTerm
      acceptorsHistory: $acceptorsHistory
      subsequent: $subsequent
      blockAutoWorkflow: $blockAutoWorkflow
      comments: $comments
      creditorStreet: $creditorStreet
      creditorHouseNr: $creditorHouseNr
      creditorCountry: $creditorCountry
      creditorCity: $creditorCity
      creditorPLZ: $creditorPLZ
      creditorIBAN: $creditorIBAN
      creditorBank: $creditorBank
      startSkontoDate: $startSkontoDate
      skontoAmount: $skontoAmount
    ) {
      _id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUpdateInvoiceMutation = (
  variables: Invoice | undefined,
  close: boolean,
  queryString?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const { actions } = useStateMachine({
    updateSelectedInvoice,
  });
  /* const changedVariables = {
    ...variables,
    invoiceDate: new Date(variables!.invoiceDate).toISOString(),
    bookingDate: new Date(variables!.bookingDate).toISOString(),
  }; */
  return useMutation<Invoice, Error>(
    'invoice',
    async () => {
      const data = await graphQLClient.request(QUERY, variables);
      return data;
    },
    {
      onSuccess: async () => {
        if (close) {
          actions.updateSelectedInvoice(undefined);
          if (queryString) queryClient.refetchQueries(queryString);
        }
      },
    }
  );
};
