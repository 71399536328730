import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Fab, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add, DeleteForever, ArrowForward, Save } from '@material-ui/icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useUsersQuery } from '../../../query/UsersQuery';
import {
  useWorkflowQuery,
  WorkflowType,
  SubNodeType,
} from '../../../query/WorkflowQuery';
import { useUpdateWorkflowMutation } from '../../../query/UpdateWorkflowMutation';
import { useRemoveWorkflowMutation } from '../../../query/RemoveWorkflowMutation';

const SCForm = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
  width: calc(100% - 50px);
  align-items: center;
  background: rgb(232, 232, 232);
  padding: 10px;
  border-radius: 10px;
`;

const FormElementStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  margin-top: 5px;
  position: relative;
`;

const MainNode = styled.div`
  display: flex;
  flex-direction: column;
  background: grey;
  padding: 15px;
  margin: 10px;
  border-radius: 25px;
`;

const SubNode = styled.div`
  display: flex;
  flex-direction: column;
  background: lightgrey;
  padding: 15px;
  margin: 10px;
  border-radius: 25px;
`;
const EndNode = styled.div`
  display: flex;
  flex-direction: column;
  background: grey;
  padding: 15px;
  margin: 10px;
  border-radius: 25px;
`;

const SubNodes = styled.div`
  display: flex;
  flex-direction: column;
  background: lightgrey;
  padding: 15px;
  margin: 10px;
  border-radius: 25px;
`;

const MainNodeArraySelectors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  margin-bottom: 30px;
`;

const MainNodeArray = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: none;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const optionsOperators = ['=', '!=', '<', '>', '<=', `>=`, `2203`];
const optionsDatabaseTags = [
  'KreditorId',
  'Nettobetrag',
  'Rechnungstyp',
  'Kostenstelle',
  'Sachkonto',
];
const mailGroup = ['Einkäufer', 'Anforderer', 'KS1', 'KS2', 'KS3', 'KS4'];
const endNodeActions = ['Rechnung buchen', 'Rechnung vorerfassen'];

const getSymbolOf = (unicode: string): string | number => {
  if (unicode.length > 2) {
    return String.fromCodePoint(Number.parseInt(unicode, 16));
  }
  return unicode;
};

interface WFProps {
  workflow: WorkflowType;
  refetch: () => void;
}

export const WorkflowComponent = (props: WFProps): JSX.Element => {
  // eslint-disable-next-line prefer-destructuring
  const { workflow, refetch } = props;
  const { register, getValues, setValue, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      workflowName: workflow.workflowName,
      workflowPriority: workflow.workflowPriority,
      mainNode: workflow.mainNode,
      subNode: workflow.subNode,
      endNode: workflow.endNode,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mainNode',
  });
  const {
    fields: subNodeFields,
    append: subNodeAppend,
    remove: subNodeRemove,
  } = useFieldArray<SubNodeType>({
    control,
    name: 'subNode',
  });

  const { mutate: updateWorkflow } = useUpdateWorkflowMutation({
    workflowName: getValues('workflowName'),
    workflowPriority: getValues('workflowPriority'),
    mainNode: fields.map((field) => {
      const returnValue = {
        databaseTag: field.databaseTag as string,
        operator: field.operator as string,
        compareValue: field.compareValue as string,
      };
      return returnValue;
    }),
    subNode: subNodeFields?.map((field, index) => {
      const returnValue: SubNodeType = {
        step: (index + 1).toString(),
        viewers: watch(`subNode[${index}].viewers`),
        acceptors: watch(`subNode[${index}].acceptors`),
      };
      return returnValue;
    }),
    endNode: watch('endNode'),
  });
  const { data: usersData } = useUsersQuery();

  const { mutate: removeWorkflow } = useRemoveWorkflowMutation({
    workflowName: getValues('workflowName'),
    workflowPriority: getValues('workflowPriority'),
    mainNode: fields.map((field) => {
      const returnValue = {
        databaseTag: field.databaseTag as string,
        operator: field.operator as string,
        compareValue: field.compareValue as string,
      };
      return returnValue;
    }),
    subNode: subNodeFields?.map((field, index) => {
      const returnValue = {
        step: (index + 1).toString(),
        viewers: watch(`subNode[${index}].viewers`) as string[],
        acceptors: watch(`subNode[${index}].acceptors`) as string[],
      };
      return returnValue;
    }),
    endNode: watch('endNode'),
  });

  useEffect(() => {
    register('endNode');
    register('workflowName');
  }, [register]);

  const addMainNodeForm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    append({
      databaseTag: getValues('databaseTag'),
      operator: getValues('operator'),
      compareValue: getValues('compareValue'),
    });
  };

  return (
    <SCForm>
      <h4 style={{ width: '100%' }}>Workflow Name: {workflow.workflowName}</h4>
      <MainNode>
        {/*  */}
        {/* <FormElementStyled>
          <TextField
            inputRef={register}
            name="workflowPriority"
            type="number"
            label="Priorität"
            style={{ width: '50px' }}
          />
        </FormElementStyled> */}
        {/*  */}
        <MainNodeArraySelectors>
          <FormElementStyled>
            <TextField
              inputRef={register}
              name="databaseTag"
              label="Datenbankeintrag"
              style={{ width: '100px' }}
              select
              SelectProps={{
                native: true,
              }}
            >
              {optionsDatabaseTags.map((value) => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </TextField>
          </FormElementStyled>
          <FormElementStyled>
            <TextField
              inputRef={register}
              name="operator"
              label="Operator"
              style={{ width: '50px' }}
              select
              SelectProps={{
                native: true,
              }}
            >
              {optionsOperators.map((value) => {
                return (
                  <option key={value} value={value}>
                    {getSymbolOf(value)}
                  </option>
                );
              })}
            </TextField>
          </FormElementStyled>
          <FormElementStyled>
            <TextField
              inputRef={register}
              name="compareValue"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              label="Vergleichswert"
              style={{ width: '100px' }}
            />
          </FormElementStyled>
          <FormElementStyled>
            <Fab
              style={{ marginTop: '5px' }}
              size="small"
              color="primary"
              onClick={(event) => addMainNodeForm(event)}
            >
              <Add />
            </Fab>
          </FormElementStyled>
        </MainNodeArraySelectors>
        {fields.map((field, fieldIndex) => {
          return (
            <MainNodeArray key={field.id}>
              <FormElementStyled>
                <TextField
                  style={{ width: '100px' }}
                  defaultValue={field.databaseTag}
                  name={`mainNode[${fieldIndex}].databaseTag`}
                  inputRef={register}
                  disabled
                />
              </FormElementStyled>
              <FormElementStyled>
                <TextField
                  style={{ width: '50px' }}
                  defaultValue={getSymbolOf(field.operator)}
                  name={`mainNode[${fieldIndex}].operator`}
                  inputRef={register}
                  disabled
                />
              </FormElementStyled>
              <FormElementStyled>
                <TextField
                  style={{ width: '100px' }}
                  defaultValue={field.compareValue}
                  name={`mainNode[${fieldIndex}].compareValue`}
                  inputRef={register}
                  disabled
                />
              </FormElementStyled>
              <FormElementStyled>
                <DeleteForever
                  onClick={() => remove(fieldIndex)}
                  style={{
                    color: 'white',
                  }}
                />
              </FormElementStyled>
            </MainNodeArray>
          );
        })}
      </MainNode>
      <ArrowForward />

      {subNodeFields.map((field, fieldIndex) => {
        register(`subNode[${fieldIndex}].viewers`);
        register(`subNode[${fieldIndex}].acceptors`);

        if (!getValues(`subNode[${fieldIndex}].viewers`)) {
          setValue(`subNode[${fieldIndex}].viewers`, field?.viewers);
        }
        if (!getValues(`subNode[${fieldIndex}].acceptors`)) {
          setValue(`subNode[${fieldIndex}].acceptors`, field?.acceptors);
        }
        return (
          <div
            key={field.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SubNode>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '5px',
                }}
              >
                <Typography variant="h6">Schritt {fieldIndex + 1}</Typography>
                <DeleteForever
                  style={{ cursor: 'pointer', color: 'white' }}
                  onClick={() => {
                    subNodeRemove(fieldIndex);
                  }}
                />
              </div>
              <FormElementStyled>
                <Autocomplete
                  multiple
                  options={
                    usersData?.users
                      ? [
                          ...mailGroup,
                          ...usersData.users.map((value) => value.mail),
                        ]
                      : []
                  }
                  getOptionLabel={(option) => option}
                  style={{ width: '200px' }}
                  getOptionSelected={(option, value) => value.includes(option)}
                  value={getValues(`subNode[${fieldIndex}].viewers`)}
                  onChange={(_, value) => {
                    setValue(`subNode[${fieldIndex}].viewers`, value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Viewer" />
                  )}
                />
              </FormElementStyled>
              {/*  */}
              <FormElementStyled>
                <Autocomplete
                  multiple
                  options={
                    usersData?.users
                      ? [
                          ...mailGroup,
                          ...usersData.users.map((value) => value.mail),
                        ]
                      : []
                  }
                  getOptionLabel={(option) => option}
                  style={{ width: '200px' }}
                  getOptionSelected={(option, value) => value.includes(option)}
                  value={getValues(`subNode[${fieldIndex}].acceptors`)}
                  onChange={(_, value) =>
                    setValue(`subNode[${fieldIndex}].acceptors`, value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Freigeber" />
                  )}
                />
              </FormElementStyled>
              {/*  */}
            </SubNode>
            <ArrowForward />
          </div>
        );
      })}
      <EndNode>
        {getValues('endNode') && (
          <FormElementStyled>
            <Autocomplete
              multiple
              options={endNodeActions}
              getOptionLabel={(option) => option}
              style={{ width: '200px' }}
              getOptionSelected={(option, value) => value.includes(option)}
              value={getValues('endNode')}
              onChange={(_, options) => setValue('endNode', options)}
              renderInput={(params) => (
                <TextField {...params} label="Aktionen" />
              )}
            />
          </FormElementStyled>
        )}
      </EndNode>
      {/*  */}
      <span style={{ height: '1px', width: '100%' }} />

      <FormElementStyled>
        <Fab
          style={{ marginTop: '5px' }}
          size="small"
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            updateWorkflow(event);
            console.log(getValues());
            refetch();
          }}
        >
          <Save />
        </Fab>
      </FormElementStyled>
      {workflow.workflowName !== 'default' && (
        <FormElementStyled>
          <Fab
            style={{ marginTop: '5px' }}
            size="small"
            color="primary"
            onClick={(event) => {
              removeWorkflow(event);
              refetch();
            }}
          >
            <DeleteForever />
          </Fab>
        </FormElementStyled>
      )}
      <FormElementStyled>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          onClick={() => {
            subNodeAppend({
              viewers: [],
              acceptors: [],
            });
          }}
        >
          <Add style={{ marginRight: '10px' }} /> SubNode hinzufügen
        </Fab>
      </FormElementStyled>
      <span style={{ height: '1px', width: '100%' }} />
    </SCForm>
  );
};
