import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';

const QUERY = gql`
  query {
    workflows {
      workflowName
      workflowPriority
      mainNode {
        databaseTag
        operator
        compareValue
      }
      subNode {
        step
        viewers
        acceptors
      }
      endNode
    }
  }
`;
interface WorkflowData {
  workflows: WorkflowType[];
}
export interface WorkflowType {
  workflowName: string;
  workflowPriority: string;
  mainNode: MainNodeType[];
  subNode: SubNodeType[] | undefined;
  endNode: string[];
}
interface MainNodeType {
  databaseTag: string;
  operator: string;
  compareValue: string;
}
export interface SubNodeType {
  step: string;
  viewers: string[];
  acceptors: string[];
}
/* interface EndNodeType {
  action: [string];
} */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWorkflowQuery = (): UseQueryResult<WorkflowData, Error> => {
  return useQuery<WorkflowData, Error>('workflow', async () => {
    const data = await graphQLClient.request(QUERY);
    return data;
  });
};
