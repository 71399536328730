import React, { FC } from 'react';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { DashboardWidgetTheme } from '../../../theme';
import { SkontoForecast } from '../components/dashboard/widgets/SkontoForecast';
import { RechnungsEingangProMonat } from '../components/dashboard/widgets/RechnungsEingangProMonat';
import { Rechnungsfaelligkeit } from '../components/dashboard/widgets/Rechnungsfaelligkeit';
import { VorerfassteRechnungen } from '../components/dashboard/widgets/VorerfassteRechnungen';
import { NochNichtErfassteRechnungen } from '../components/dashboard/widgets/NochNichtErfassteRechnungen';
import { NichtGenutzterSkonto } from '../components/dashboard/widgets/NichtGenutzterSkonto';
import { checkAuthorisation } from '../checkAuthorisation';
import { IncomingInvoicesPage } from './IncomingInvoices';
import { useMsal } from '@azure/msal-react';
import { gql, useQuery } from '@apollo/client';
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { Invoice } from '../../../dto/Invoice';
import { InBearbeitungWidget } from '../components/dashboard/widgets/InBearbeitungWidget';
import { FreigegebenWidget } from '../components/dashboard/widgets/FreigegebenWidget';

const INVOICE_QUERY = gql`
  query {
    invoices {
      _id
      invoiceStatus
    }
  }
`;

export const DashboardPage: FC = () => {
  const { accounts } = useMsal();

  const startDate = startOfWeek(new Date());
  const endDate = addWeeks(endOfWeek(new Date()), 4);

  const { data } = useQuery<{ invoices: Invoice[] }>(INVOICE_QUERY, {
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
  });

  if (!checkAuthorisation(accounts[0].username!)) {
    return <IncomingInvoicesPage />;
  }

  return (
    <MuiThemeProvider theme={DashboardWidgetTheme}>
      <PageContentContainer>
        <PageTitle>Dashboard</PageTitle>
        <Grid container spacing={5} style={{ marginTop: 16 }}>
          <Grid item xs={6} md={3}>
            <NochNichtErfassteRechnungen
              label="Noch nicht erfasste Rechnungen"
              data={data}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InBearbeitungWidget label="In Bearbeitung" data={data} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FreigegebenWidget label="Freigegeben" data={data} />
          </Grid>
          <Grid item xs={6} md={3}>
            <VorerfassteRechnungen label="Vorerfasste Rechungen" />
          </Grid>
          <Grid item xs={6} md={3}>
            <NichtGenutzterSkonto label="Nicht genutzter Skonto" />
          </Grid>
          <Grid item xs={12} md={6}>
            <RechnungsEingangProMonat />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkontoForecast />
          </Grid>
          <Grid item xs={12} md={6}>
            <Rechnungsfaelligkeit />
          </Grid>
        </Grid>
      </PageContentContainer>
    </MuiThemeProvider>
  );
};
