import React, { FC, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { PageContentContainer } from '../layout/PageContentContainer';
import { PageTitle } from '../layout/components/page/PageTitle';
import { Breadcrumbs } from '../layout/components/page/Breadcrumbs';
/* import { MiscData } from '../components/server/MiscData';
 */ import { CompanySettings, Settings } from '../../../dto/Settings';
import { EmailSetup } from '../components/server/EmailSetup';

const settingsQuery = gql`
  query {
    settings {
      mailSettings {
        mailAddress
        mailUserName
        imapHost
        smtpHost
        imapPort
        smtpPort
        password
      }
      companyHeader {
        iban
        companyName
        street
        streetNr
        city
        postalCode
      }
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation UpdateSettings(
    $mailSettings: MailSettings
    $companyHeader: CompanyHeader
  ) {
    updateSettings(
      _id: $_id
      mailSettings: $mailSettings
      companyHeader: $companyHeader
    ) {
      _id
    }
  }
`;

export const ServerPage: FC = () => {
  const { loading, data } = useQuery<CompanySettings>(settingsQuery);
  const updateSettings = useMutation<CompanySettings>(UPDATE_SETTINGS)[0];

  const [companySettings, setCompanySettings] = useState<Settings>();

  const createChangeHandler = (/* id: string, value: string */): void => {
    /* const Ids = id.split('.');

    const mainId = Ids[0];
    const subId = Ids[1];
    const settingsType: MailSettings | CompanyHeader = {
      ...[mainId],
      [subId]: value,
    };
    setCompanySettings({
      ...companySettings,
      settingsType,
    }); */
  };
  return (
    <PageContentContainer>
      {loading && <p>...Loading</p>}
      {data && !companySettings && setCompanySettings(data.settings)}
      <PageTitle>Server</PageTitle>
      <Breadcrumbs
        pageTitle="Server"
        entries={[{ name: 'Dashboard', to: '/' }]}
      />
      {data && (
        <EmailSetup
          settings={data.settings}
          createChangeHandler={createChangeHandler}
          updateSettings={updateSettings}
        />
      )}
    </PageContentContainer>
  );
};
