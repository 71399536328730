import React, { FC } from 'react';
import AriivaLogo from '../../../assets/images/bitflow-logo-dark.svg';
//  import AriivaLogo from 'bitflow-client/src/modules/admin/assets/images/hepBitFlow.png';

const LogoComponent: FC = () => {
  return (
    <img
      src={AriivaLogo}
      alt="bitFlow Logo"
      style={{ objectFit: 'contain', width: '60%' }}
    />
  );
};

export const Logo = LogoComponent;
