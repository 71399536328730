import React, { FC } from 'react';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose: () => void;
}

export const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { children, onClose, style, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      style={{
        position: 'relative',
      }}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      ) : (
        <></>
      )}
    </MuiDialogTitle>
  );
};
