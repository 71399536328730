import React, { FC, useState } from 'react';
import {
  Chip,
  Collapse,
  createStyles,
  darken,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ClassNameMap, withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { AdminRouteSections } from '../../../config/AdminRoutes';
import { Route, RouteSection, SubRoute } from '../../../../../routes/Route';
import { Brand } from './Brand';
import { Scrollbar } from './Scrollbar';
import { useMsal } from '@azure/msal-react';
import { checkAuthorisation } from '../../../checkAuthorisation';

interface MenuProps {
  classes: ClassNameMap;
  theme: Theme;
}
interface MenuState {
  openState: KeyValue<boolean>;
}
interface KeyValue<ValueType> {
  [key: string]: ValueType;
}

const MenuComponent = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(true);

  const isOpen = (key: string): boolean => {
    return true;
  };
  const toggle = (key: string): boolean => {
    return true;
  };
  /* public state = { openState: {} };

  private toggle = (path: string): void => {
    this.setState((prevState: MenuState) => {
      const newState: { [key: string]: boolean } = { ...prevState.openState };
      // Collapse all elements
      const oldIndexState = newState[path];
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      // Toggle selected element
      newState[path] = !oldIndexState;
      return { openState: newState };
    });
  };

  private isOpen = (key: string): boolean => {
    return (this.state.openState as { [key: string]: boolean })[key];
  }; */
  const { accounts } = useMsal();
  return (
    <>
      <div>
        <Brand />
      </div>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {AdminRouteSections.map((section: RouteSection, index) => {
              const {
                label,
                permission,
                role,
                includeInMenu = true,
                routes,
              } = section;
              if (!includeInMenu) {
                return <React.Fragment key={label} />;
              }
              if (
                // eslint-disable-next-line unicorn/consistent-destructuring
                section.routes[index].role === 'admin' &&
                !checkAuthorisation(accounts[0].username!)
              ) {
                return null;
              }
              return (
                <React.Fragment key={label}>
                  {label && (
                    <SidebarSection permission={permission} role={role}>
                      {label}
                    </SidebarSection>
                  )}
                  {routes.map((appRoute: Route) => {
                    const key = appRoute.id;
                    if (
                      // eslint-disable-next-line unicorn/consistent-destructuring
                      appRoute.role === 'admin' &&
                      !checkAuthorisation(accounts[0].username!)
                    ) {
                      return null;
                    }
                    return (
                      <SideBarCategory
                        key={appRoute.id}
                        name={appRoute.id}
                        label={appRoute.label}
                        to={appRoute.path}
                        component={appRoute.path ? NavLink : undefined}
                        icon={appRoute.icon}
                        button={false}
                        isOpen={isOpen(key)}
                        toggleOpen={() => toggle(key)}
                        subRoutes={appRoute.children || []}
                        permission={appRoute.permission || ''}
                        role={appRoute.role}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Items>
        </List>
      </Scrollbar>
    </>
  );
};

export const Menu = withStyles(
  (theme: Theme) =>
    createStyles({
      toolbar: theme.mixins.toolbar,
    }),
  { withTheme: true }
)(MenuComponent);

const SidebarSectionContainer = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarSection: FC<{
  permission?: string | string[];
  children: string;
  role?: string;
}> = (props) => {
  const { children } = props;
  return <SidebarSectionContainer>{children}</SidebarSectionContainer>;
};

const SideBarCategory: FC<{
  name: string;
  to: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<NavLinkProps>;
  icon?: React.ReactElement;
  exact?: boolean;
  label: string;
  button?: false;
  badge?: React.ReactNode;
  isOpen: boolean;
  toggleOpen(): void;
  subRoutes: SubRoute[];
  permission: string | string[];
  role?: string;
}> = (props) => {
  const { isOpen, toggleOpen, subRoutes, permission, ...rest } = props;
  const { accounts } = useMsal();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <>
      <Category {...rest} button onClick={toggleOpen}>
        {/* button has to be true because of TS reasons */}
        {props.icon}
        <CategoryText>{props.label}</CategoryText>
        {subRoutes.length > 0 ? (
          // <IconButton>
          isOpen ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : // </IconButton>
        undefined}
      </Category>
      {subRoutes && (
        <Collapse in={isOpen}>
          {subRoutes.map((route) => (
            <SidebarLink
              key={route.path}
              name={route.label}
              to={route.path}
              badge=""
              component={NavLink}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

const SidebarLink: FC<{
  name: string;
  to: string;
  badge?: string;
  component?: React.ComponentType<NavLinkProps>;
}> = (props) => {
  return (
    <Link button dense {...props}>
      <LinkText>{props.name}</LinkText>
      {props.badge ? <LinkBadge label={props.badge} /> : ''}
    </Link>
  );
};

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(14)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: rgba(238, 238, 238, 0.7);
  }

  &:hover span {
    color: rgba(238, 238, 238, 0.9);
  }

  &.active {
    background-color: ${(props) =>
      darken(props.theme.sidebar.background, 0.06)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.active {
    background-color: ${(props) =>
      darken(props.theme.sidebar.background, 0.05)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;
