import React from 'react';
import {
  StateMachineProvider,
  createStore,
  GlobalState,
} from 'little-state-machine';
import { Invoice } from '../dto/Invoice';
import { Order, Position } from '../dto/Order';
import { queryClient } from '../query/setup';

declare module 'little-state-machine' {
  interface GlobalState {
    selectedInvoice: Invoice | undefined;
    selectedOrder: Order | undefined;
    selectedPosition: Position | undefined;
    sapInvoice: Invoice | undefined;
    onMutateInvoice: boolean;
    isUpdateSap: boolean;
  }
}
createStore({
  selectedInvoice: undefined,
  selectedOrder: undefined,
  selectedPosition: undefined,
  sapInvoice: undefined,
  onMutateInvoice: false,
  isUpdateSap: false,
});

export const updateSelectedInvoice = (
  state: GlobalState,
  payload: Invoice | undefined
): GlobalState => {
  queryClient.removeQueries('invoiceDetail');
  return {
    ...state,
    selectedInvoice: payload,
  };
};

export const updateSapInvoice = (
  state: GlobalState,
  payload: Invoice | undefined
): GlobalState => {
  return {
    ...state,
    sapInvoice: payload,
  };
};

export const updateSapType = (
  state: GlobalState,
  payload: string
): GlobalState => {
  return {
    ...state,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedInvoice: { ...state.selectedInvoice!, sapType: payload },
  };
};

export const updateOnMutateInvoice = (
  state: GlobalState,
  payload: boolean
): GlobalState => {
  return {
    ...state,
    onMutateInvoice: payload,
  };
};
export const setIsUpdateSap = (
  state: GlobalState,
  payload: boolean
): GlobalState => {
  return {
    ...state,
    isUpdateSap: payload,
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const StateProvider: React.FC<{}> = ({ children }) => {
  return <StateMachineProvider>{children}</StateMachineProvider>;
};
