import * as msal from '@azure/msal-browser';
import { Configuration, RedirectRequest, LogLevel } from '@azure/msal-browser';
import { Environment } from './config/Environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: Environment.AZURE_AD_CLIENT_ID || '',
    authority: Environment.AZURE_AD_AUTHORITY,
    redirectUri: Environment.AZURE_AD_REDIRECT_URI,
    postLogoutRedirectUri: Environment.AZURE_AD_POSTLOGOUT_REDIRECT_URI,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        // eslint-disable-next-line no-console
        console.info(level, message, containsPii);
      },
      piiLoggingEnabled: true,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read' /* ,'Group.Read.All' */],
};

export const loginSilentRequest = {
  scopes: [`${Environment.AZURE_AD_CLIENT_ID}/user.read`],
};
export const msalInstance = new msal.PublicClientApplication(msalConfig);
