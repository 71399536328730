import React, { ReactElement } from 'react';
import { spacing } from '@material-ui/system';
import { TextField as MuiTextField } from '@material-ui/core';
import styled from 'styled-components';

const TextFieldSpacing = styled(MuiTextField)(spacing);

const SC = styled(TextFieldSpacing)`
  width: inherit;
  & > * {
    width: inherit;
  }
`;

export interface TextFieldProps {
  id: string;
  type?: string;
  label?: string;
  defaultValue?: string;
  value?: string;
  placeHolder?: string;
  options?: string[];
  createChangeHandler: (id: string, value: string) => void;
}

export const TextField = (props: TextFieldProps): ReactElement => {
  const { id, defaultValue, value, label, type, createChangeHandler } = props;
  return (
    <SC
      data-date-format="DD MMMM YYYY"
      label={label}
      defaultValue={defaultValue}
      value={value}
      InputLabelProps={{ shrink: true }}
      type={type}
      onChange={(event) => createChangeHandler(id, event.target.value)}
    />
  );
};
