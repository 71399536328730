import React, { FC, useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useIsAuthenticated } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import {
  msalInstance,
  loginRequest,
  loginSilentRequest,
} from '../setupAuthentication';
import { Environment } from '../config/Environment';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AsyncTokenLookup = async () => {
  const accounts = await msalInstance.getAllAccounts();

  const account = accounts[0];
  if (account) {
    try {
      const result = await msalInstance.acquireTokenSilent({
        ...loginSilentRequest,
        account,
      });
      return result.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(loginRequest);
      }
    }
  } else if (!account) {
    return msalInstance.acquireTokenRedirect(loginRequest);
  }
  return '';
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ApolloConnection: FC<any> = (props) => {
  const { children } = props;
  const [isLoggedIn] = useState(useIsAuthenticated());

  const withToken = setContext(async (_, { headers }) => {
    const token = await AsyncTokenLookup();

    return {
      headers: {
        ...headers,
        Authorization: token,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: `${Environment.API_ENDPOINT}/graphql`,
  });

  //  eslint-disable-next-line react-hooks/exhaustive-deps
  const client = new ApolloClient({
    link: from([withToken, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  useEffect(() => {
    if (!isLoggedIn) {
      (async () => {
        await client.resetStore();
      })();
    }
  }, [isLoggedIn, client]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
