import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';

const QUERY = gql`
  query {
    zTerms {
      ZTERM
      VTEXT
    }
  }
`;

export interface ZTermsData {
  zTerms: ZTerms[];
}
export interface ZTerms {
  ZTERM: string;
  VTEXT: string;
}

export const useZTermsQuery = (): UseQueryResult<ZTermsData, Error> => {
  return useQuery<ZTermsData, Error>('zTerms', async () => {
    const data = await graphQLClient.request(QUERY);
    return data;
  });
};
