import React from 'react';
import { CellProps } from 'react-table';
import { Entity } from '../../../../../dto/Entity';

export const DateCell = <TYPE extends Entity>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;
  if (value) {
    return <div>{value}</div>;
  }
  return <p>-</p>;
};
