import React, { FC } from 'react';
import styled from 'styled-components';
import { ListItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Logo } from './Logo';

/* const Container = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`; */

const Container = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: #f1f1f1;
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`;

const BrandComponent: FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Container button={false as any} style={{ paddingLeft: 27 }}>
      <NavLink to="/" style={{ width: '100%', height: '40px' }}>
        <Logo />
      </NavLink>
    </Container>
  );
};

export const Brand = BrandComponent;

/* const BrandComponent: FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Container button={false as any} style={{ paddingLeft: 27 }}>
      <NavLink to="/" style={{ width: '45%' }}>
        <Logo />
      </NavLink>
    </Container>
  );
};

export const Brand = BrandComponent; */
