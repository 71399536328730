import { grey, teal } from '@material-ui/core/colors';
import { Variant } from '../Variant';

export const TealVariant: Variant = {
  name: 'Teal',
  palette: {
    primary: {
      main: teal[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: teal[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: teal[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: teal[700],
    header: {
      color: '#FFF',
      background: teal[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: teal[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F7F9FC',
  },
};
