import React, { VFC } from 'react';
import { Redirect } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../../setupAuthentication';
import AriivaLogo from '../../admin/assets/images/bitflow-logo-dark.svg';

export const LoginPage: VFC = () => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect as const,
    loginRequest
  );

  const triggerSignin = (
    event:
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.KeyboardEvent<HTMLImageElement>
  ): void => {
    event.preventDefault();
    login();
  };

  return (
    <div
      style={{
        width: 400,
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '50vh',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions  */}
      <img
        src={AriivaLogo}
        alt="bitFlow Logo"
        style={{ objectFit: 'contain', width: '60%' }}
        onClick={(event) => triggerSignin(event)}
        onKeyDown={(event) => triggerSignin(event)}
      />
      {error && <div>{error}</div>}
      <AuthenticatedTemplate>
        <Redirect to="/" />
      </AuthenticatedTemplate>
    </div>
  );
};
