import { blue, green, grey } from '@material-ui/core/colors';
import { Variant } from '../Variant';

export const DarkVariant: Variant = {
  name: 'Dark',
  palette: {
    primary: {
      main: '#232F3E',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#898989',
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFFFFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: '#1B2430',
    header: {
      color: grey[200],
      background: '#232f3e',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#232f3e',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    background: 'rgb(247,249,252)',
  },
};
