import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { Cancel, Pageview, Save, SaveAlt } from '@material-ui/icons';
import {
  updateSelectedInvoice,
  setIsUpdateSap,
} from '../../../../../stores/globalStore';
import { DialogTitle } from '../DialogTitle';
import { ImageInvoiceSmall } from './ImageInvoiceSmall';
import { InvoiceDetailForm } from './InvoiceDetailForm';
import { Environment } from '../../../../../config/Environment';
import { useZTermsQuery } from '../../../../../query/ZTermsQuery';
import { useUpdateInvoiceMutation } from '../../../../../query/UpdateInvoiceMutation';
import {
  Comment,
  Invoice,
  InvoicePosition,
  InvoicePositionFi,
} from '../../../../../dto/Invoice';
import { InvoiceAcceptModal } from '../InvoiceAcceptModal/InvoiceAcceptModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const StyledDialog = styled(Dialog)`
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  position: relative;
  padding: 0px;
`;

const SCFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1000px;
  overflow: auto;
  overflow-x: hidden;
`;

const SCImageContainer = styled.div`
  width: 1100px;
  max-height: 900px;
  overflow: auto;
`;

interface InvoiceDetailModalProps {
  config: string;
  queryString: string;
  disableWholeForm?: boolean;
}

interface DetailForm extends Invoice {
  orderNrSelected: string;
  positionSelected: string;
  positionSumMm: string;
  positionCountMm: string;
  commentField: string;
}

export const InvoiceDetailModal = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: InvoiceDetailModalProps
): JSX.Element => {
  const { config, queryString, disableWholeForm } = props;
  const [calc, setCalc] = useState<boolean>(false);
  const { actions, state } = useStateMachine({
    updateSelectedInvoice,
    setIsUpdateSap,
  });
  const [magnify, setMagnify] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    grossTotalInvoice: yup.string().required(),
    pspElement: yup.lazy((value) =>
      value === ''
        ? yup.string()
        : yup.string().matches(/[A-Za-z]-\d{2}-\d{7}(?:-\d{3}){3}/)
    ),
    customText: yup.string().max(25),
  });

  const { isLoading } = useZTermsQuery();
  const methods = useForm<DetailForm>({
    mode: 'onChange',
    defaultValues: {
      ...state.selectedInvoice,
      orderNrSelected: '',
      positionSelected: '',
      controllingType: 'Kostenstelle',
      MmType: false,
      account: undefined,
      allocNr: '',
      positionSumFi: '',
      taxSign: undefined,
      pspElement: '',
      internal: '',
      commentField: '',
      comments: state.selectedInvoice?.comments || [],
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    getValues,
    setValue,
    register,
    watch,
    control,
    errors,
    trigger,
  } = methods;

  const {
    fields: fieldsMm,
    append: appendMmPosition,
    remove: removeMmPosition,
  } = useFieldArray<InvoicePosition>({
    control,
    name: `positions`,
  });

  const {
    fields: fieldsFi,
    append: appendFiPosition,
    remove: removeFiPosition,
  } = useFieldArray<InvoicePositionFi>({
    control,
    name: `positionsFi`,
  });

  const {
    fields: fieldsComments,
    append: appendComment,
  } = useFieldArray<Comment>({
    control,
    name: `comments`,
  });

  useEffect(() => {
    register('orderNrSelected');
    register('positionSelected');
    register('acceptors');
    register('controllingType');
    register('GlAccount');
    register('Costcenter');
    register('MmType');
    register('subsequent');
    register('blockAutoWorkflow');
    register('blockZTerm');
    register('taxSign');
    register('account');
    register('taxSignManual');
    register('accountManual');
    register('zTerm');
    register('workflowType');
    register('lock');
    register('sapType');
    register('netTotal');

    if (calc) {
      calculateGrossTotal();
      setCalc(false);
    }
  });

  useEffect(() => {
    if (state.isUpdateSap) {
      setValue('creditorName', state.selectedInvoice?.creditorName);
      setValue('creditorCountry', state.selectedInvoice?.creditorCountry);
      setValue('creditorStreet', state.selectedInvoice?.creditorStreet);
      setValue('creditorHouseNr', state.selectedInvoice?.creditorHouseNr);
      setValue('creditorPLZ', state.selectedInvoice?.creditorPLZ);
      setValue('creditorCity', state.selectedInvoice?.creditorCity);
      setValue('creditorIBAN', state.selectedInvoice?.creditorIBAN);
      setValue('positions', state.selectedInvoice?.positions);

      actions.setIsUpdateSap(false);
    }
  });

  const {
    mutate: mutateInvoice,
    isLoading: isLoadingMutateInvoice,
  } = useUpdateInvoiceMutation(
    {
      ...state.selectedInvoice,
      ...getValues(),
      positions: watch('positions'),
      positionsFi: watch('positionsFi'),
      comments: watch('comments'),
    },
    true,
    queryString
  );

  //  i cannot use forloop in jsx, maybe someone else can
  const dummyArray: number[] = [];
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  for (let i = 0; i < state.selectedInvoice!.pageCount; i++) {
    dummyArray.push(i);
  }

  const fillWithLeadingZeros = (value: string, totalLength: number): string => {
    let returnValue = value;
    while (returnValue.length < totalLength) {
      returnValue = `0${returnValue}`;
    }
    return returnValue;
  };

  const addManualPositionRender = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fixedLeadingZerosOnPositionNumber = getValues(
      'positionManualPosition'
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fixedLeadingZerosOnPositionNumber.EBELP = fillWithLeadingZeros(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fixedLeadingZerosOnPositionNumber.EBELP,
      5
    );

    appendMmPosition({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      OrderNrSelected: getValues('positionManualOrderNr'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      PositionSelected: fixedLeadingZerosOnPositionNumber,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      taxSign: getValues('taxSignManual'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sum: getValues('positionManualSum'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      count: getValues('positionManualCount'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      MmType: getValues('MmType'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account: getValues('accountManual'),
    });
    setCalc(true);
  };
  const addPositionRender = (): void => {
    if (getValues('sapType') === 'MM' && getValues('MmType') === false) {
      appendMmPosition({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        OrderNrSelected: getValues('orderNrSelected'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        PositionSelected: getValues('positionSelected'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        taxSign: getValues('taxSign'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sum: getValues('positionSumMm'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        count: getValues('positionCountMm'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MmType: getValues('MmType'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        account: getValues('account'),
      });
    }
    if (getValues('sapType') === 'MM' && getValues('MmType') === true) {
      appendMmPosition({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        GlAccount: getValues('GlAccount'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Costcenter: getValues('Costcenter'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pspElement: getValues('pspElement'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        internal: getValues('internal'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        taxSign: getValues('taxSign'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sum: getValues('positionSumFi'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        allocNr: getValues('allocNr'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        controllingType: getValues('controllingType'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MmType: getValues('MmType'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        account: getValues('account'),
      });
    }
    if (getValues('sapType') === 'FI') {
      appendFiPosition({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        GlAccount: getValues('GlAccount'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Costcenter: getValues('Costcenter'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pspElement: getValues('pspElement'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        internal: getValues('internal'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        taxSign: getValues('taxSign'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sum: getValues('positionSumFi'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        allocNr: getValues('allocNr'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        controllingType: getValues('controllingType'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        account: getValues('account'),
      });
    }
    setCalc(true);
  };
  const removePositionRender = (position: number): void => {
    if (getValues('sapType') === 'MM') {
      removeMmPosition(position);
    }
    if (getValues('sapType') === 'FI') {
      removeFiPosition(position);
    }
    setCalc(true);
  };

  const calculateGrossTotal = (): void => {
    let sum = 0;
    let lastTaxSign = 0;
    if (getValues('sapType') === 'MM') {
      watch('positions').forEach((position: InvoicePosition) => {
        if (position.account === 'Soll') {
          sum +=
            (Number.parseFloat(position.sum as string) / 100) *
            (100 + position.taxSign.tax);
          lastTaxSign = position.taxSign.tax;
        }
        if (position.account === 'Haben') {
          sum -=
            (Number.parseFloat(position.sum as string) / 100) *
            (100 + position.taxSign.tax);
          lastTaxSign = position.taxSign.tax;
        }
      });
    }
    if (getValues('sapType') === 'FI') {
      watch('positionsFi').forEach((position: InvoicePositionFi) => {
        if (position.account === 'Soll') {
          sum +=
            (Number.parseFloat(position.sum) / 100) *
            (100 + position.taxSign.tax);
          lastTaxSign = position.taxSign.tax;
        }
        if (position.account === 'Haben') {
          sum -=
            (Number.parseFloat(position.sum) / 100) *
            (100 + position.taxSign.tax);
          lastTaxSign = position.taxSign.tax;
        }
      });
    }

    if (watch('seperateCosts')) {
      const addend =
        (Number.parseFloat(watch('seperateCosts')) / 100) * (100 + lastTaxSign);
      sum += addend;
    }
    setValue('grossTotal', sum.toFixed(2).toString());
    setValue(
      'netTotal',
      ((sum / (lastTaxSign + 100)) * 100).toFixed(2).toString()
    );
  };

  const [download, setDownload] = useState<boolean>(false);
  useEffect(() => {
    if (download) {
      /* downloadPdf(state.selectedInvoice!.imageOriginalName); */
      setDownload(false);
    }
  }, []);

  const [submit, setSubmit] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line unicorn/explicit-length-check, unicorn/new-for-builtins
    if (Object.keys(errors).length === 0 && submit) {
      setSubmit(false);
      mutateInvoice();
    }
    console.log('VALUE', errors);
  });

  const onSubmit = (): void => {
    setSubmit(true);
  };

  return (
    <>
      <StyledDialog
        maxWidth={false}
        onClose={() => {
          console.log('CLOSING');
          actions.updateSelectedInvoice(undefined);
        }}
        aria-labelledby="customized-dialog-title"
        open
      >
        <FormProvider {...methods}>
          <DialogTitle
            style={{
              padding: '5px 20px',
              width: '100%',
            }}
            onClose={() => actions.updateSelectedInvoice(undefined)}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 'auto',
              }}
            >
              <h3 style={{ margin: '0px' }}>Rechnung Details</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ marginRight: '70px' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '100px' }}
                    href={`${Environment.API_ENDPOINT}/requests/${
                      state.selectedInvoice!.imageOriginalName
                    }`}
                    download={state.selectedInvoice!.imageOriginalName}
                  >
                    Download
                    <p style={{ margin: '0px 10px 0px 10px' }}>
                      {state.selectedInvoice!.imageOriginalName}
                    </p>
                    <SaveAlt />
                  </Button>
                </div>
                <div style={{ marginRight: '70px' }}>
                  <Button color="primary" onClick={() => setMagnify(!magnify)}>
                    <Pageview
                      fontSize="large"
                      style={{ margin: 0 }}
                      color={magnify ? 'action' : 'primary'}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              padding: 0,
              paddingBottom: '5px',
              background: 'snow',
              maxHeight: '900px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                maxHeight: '900px',
              }}
            >
              <SCFormWrapper>
                {watch('sapType') !== 'MM' && watch('sapType') !== 'FI' && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '840px',
                      height: '100%',
                      background: 'white',
                      opacity: '50%',
                      zIndex: 10,
                    }}
                  />
                )}

                {isLoading ? (
                  <CircularProgress
                    style={{ alignSelf: 'center', margin: 'auto' }}
                  />
                ) : (
                  <>
                    <InvoiceDetailForm
                      fieldsMm={fieldsMm}
                      fieldsFi={fieldsFi}
                      fieldsComments={fieldsComments}
                      appendComment={appendComment}
                      addPositionRender={addPositionRender}
                      addManualPositionRender={addManualPositionRender}
                      removePositionRender={removePositionRender}
                      setCalc={setCalc}
                      config={config}
                      disableWholeForm={disableWholeForm}
                    />
                  </>
                )}
              </SCFormWrapper>
              <SCImageContainer>
                {dummyArray.map((_, index) => {
                  return (
                    <ImageInvoiceSmall
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      imageUrl={`${Environment.API_ENDPOINT}/requests/${
                        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                        state.selectedInvoice!._id
                      }-${index + 1}.jpg`}
                      magnify={magnify}
                    />
                  );
                })}
              </SCImageContainer>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<Cancel />}
              disableRipple
              autoFocus
              onClick={() => actions.updateSelectedInvoice(undefined)}
              color="primary"
            >
              Abbrechen
            </Button>
            {(getValues('sapType') === 'MM' ||
              getValues('sapType') === 'FI') && (
              <>
                <div style={{ width: 200 }}>
                  <Button
                    startIcon={<Save />}
                    disableRipple
                    fullWidth
                    autoFocus
                    onClick={() => {
                      trigger();
                      onSubmit();
                    }}
                    color="primary"
                  >
                    {isLoadingMutateInvoice ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Änderungen speichern'
                    )}
                  </Button>
                </div>

                {(config === 'workInProgress' ||
                  config === 'incomingInvoice') && (
                  <InvoiceAcceptModal appendComment={appendComment} />
                )}
              </>
            )}
          </DialogActions>
        </FormProvider>
      </StyledDialog>
    </>
  );
};
