import React, { FC, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { Drawer } from './components/Drawer';
import { FullHeightContainer } from './components/FullHeightContainer';
import { FullWidthContainer } from './components/FullWidthContainer';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { ErrorBoundary } from '../../../pages/ErrorBoundary';
import { ResponsivenessConfiguration } from './ResponsivenessConfiguration';

const DashboardLayoutComponent: FC = (props) => {
  const { children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <FullHeightContainer>
      <CssBaseline />
      <Drawer>
        <Sidebar
          PaperProps={{
            style: { width: ResponsivenessConfiguration.drawerWidth },
          }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Drawer>
      <FullWidthContainer>
        <Header onMenuIconClick={handleDrawerToggle} />
        <ErrorBoundary>{children}</ErrorBoundary>
      </FullWidthContainer>
    </FullHeightContainer>
  );
};

export const DashboardLayout = DashboardLayoutComponent;
