import React, { PropsWithChildren } from 'react';
import { Entity } from '../../../../../dto/Entity';

export const HeaderCell = <TYPE extends Entity>(
  props: PropsWithChildren<TYPE>
): JSX.Element => {
  const { children } = props;

  return (
    <div style={{ whiteSpace: 'nowrap', textOverflow: 'elipsis' }}>
      {children}
    </div>
  );
};
