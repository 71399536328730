import { useQuery, UseQueryResult } from 'react-query';
import { useStateMachine } from 'little-state-machine';
import { gql } from 'graphql-request';
import { Invoice } from '../dto/Invoice';
import { graphQLClient } from './setup';
import {
  updateSelectedInvoice,
  updateSapInvoice,
  updateOnMutateInvoice,
  setIsUpdateSap,
} from '../stores/globalStore';

interface InvoiceData {
  invoices: Invoice[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWorkInProgressQuery = (
  filter?: any,
  fields?: string
): UseQueryResult<InvoiceData, Error> => {
  const { actions, state } = useStateMachine({
    updateSelectedInvoice,
    updateSapInvoice,
    updateOnMutateInvoice,
    setIsUpdateSap,
  });
  const queryFields =
    fields ||
    `_id
  creditorId
  creditorName
  invoiceNr
  invoiceDate
  bookingDate
  received
  sapInvoiceNumber
  discountBool
  invoiceStatus
  incomingDate
  acceptors
  acceptorsHistory
  mailObserver
  sapType
  grossTotalInvoice`;

  const QUERY = gql`
  query($invoiceStatus: [String]) {
    invoices(invoiceStatus: $invoiceStatus) {
      ${queryFields}
    }
  }
`;

  return useQuery<InvoiceData, Error>(
    'workInProgress',
    async () => {
      const data = await graphQLClient.request(QUERY, filter);
      return data;
    },
    {
      onSuccess: () => {
        actions.updateOnMutateInvoice(true);
        if (state.isUpdateSap) {
          actions.updateSelectedInvoice(undefined);
          actions.setIsUpdateSap(false);
        }
      },
    }
  );
};
