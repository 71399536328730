import React, { FC } from 'react';
import styled from 'styled-components';
import { GlassMagnifier } from 'react-image-magnifiers';

const SCImage = styled.img`
  display: inline;
  width: 100%;
  object-fit: contain;
`;

const SCImageMag = styled(GlassMagnifier)`
  display: inline;
  width: 100%;
  object-fit: contain;
`;

export interface ImageInvoiceSmallProps {
  imageUrl: string;
  magnify: boolean;
}

export const ImageInvoiceSmall: FC<ImageInvoiceSmallProps> = (props) => {
  const { imageUrl, magnify } = props;

  if (magnify)
    return (
      <SCImageMag
        imageSrc={imageUrl}
        imageAlt="Invoice"
        largeImageSrc={imageUrl}
        magnifierSize="100%"
        square={false}
      />
    );
  return <SCImage src={imageUrl} alt="Invoice" />;
};
