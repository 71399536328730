import React, { FC, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Invoice } from '../../../../../dto/Invoice';
import { JSONData, Word } from './JSONData';
import { Label } from '../../../../../dto/Label';
import { ClickableInvoiceOverlay } from './components/ClickableInvoiceOverlay';
import {
  splitBoxCoordinates,
  extractWords,
} from '../../../../../utils/OCRUtils';
import { Environment } from '../../../../../config/Environment';

const ADD_RULE = gql`
  mutation AddRule(
    $creditorId: String
    $keyGlobal: String
    $keyValue: String
    $keyBox: String
    $currentInvoice: String
  ) {
    addRule(
      creditorId: $creditorId
      keyGlobal: $keyGlobal
      keyValue: $keyValue
      keyBox: $keyBox
      currentInvoice: $currentInvoice
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles(() => ({
  key: {
    backgroundcolor: 'white',
    color: 'green',
    position: 'relative',
  },
  value: {
    backgroundcolor: 'white',
    color: 'blue',
    position: 'relative',
    padding: '16px 0',
  },
  buttonwrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
    width: 300,
    backgroundColor: 'white',
    height: '100%',
    borderRight: '1px solid black',
    padding: 16,
  },
  active: {
    opacity: '1',
  },
}));

export interface InvoiceSelectImageProps {
  json: any[];
  invoice: Invoice;
  label: Label;
  handleClose: () => void;
}

export enum Mode {
  NONE,
  KEY,
  VALUE,
}

interface Data {
  creditorId: string;
  keyGlobal: string;
  keyValue: string;
  keyBox: string;
}

export const InvoiceSelectImage: FC<InvoiceSelectImageProps> = (props) => {
  const classes = useStyles();
  const [addRule] = useMutation<Data>(ADD_RULE);
  const [keyWords, setKeyWords] = useState([] as Word[]);
  const [valueWords, setValueWords] = useState([] as Word[]);
  const [mode, setMode] = useState(Mode.NONE);

  const { json, invoice, label, handleClose } = props;

  const { imageWidth, imageHeight } = invoice;
  const { innerWidth: screenWidth } = window;
  const i = 0;

  // calculations
  const ImgDisplayWidth = screenWidth - 300;
  const ImgDisplayHeight = (imageHeight[i] / imageWidth[i]) * ImgDisplayWidth;
  const RatioOriginalImage = ImgDisplayWidth / imageWidth[i];

  const ImageWrapper = styled.div`
    position: relative;
    width: ${ImgDisplayWidth}px;
    height: ${ImgDisplayHeight}px;
    margin: auto;
    margin-left: 300px;
    &:focus {
      outline: none;
    }
  `;

  const Image = styled.img`
    width: ${ImgDisplayWidth}px;
    height: ${ImgDisplayHeight}px;
    outline: none;
  `;

  const toggleKeyMode = (): void => {
    if (mode === Mode.KEY) {
      setMode(Mode.NONE);
    } else {
      setMode(Mode.KEY);
    }
  };
  const toggleValueMode = (): void => {
    if (mode === Mode.VALUE) {
      setMode(Mode.NONE);
    } else {
      setMode(Mode.VALUE);
    }
  };
  const deleteKey = (): void => {
    setKeyWords([]);
  };
  const deleteValue = (): void => {
    setValueWords([]);
  };

  const selectWord = (word: Word): void => {
    if (mode === Mode.KEY) {
      setKeyWords([...keyWords, word]);
    }
    if (mode === Mode.VALUE) {
      setValueWords([...valueWords, word]);
    }
  };

  interface DataButtonProps {
    json: JSONData;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const DataButton = (props: DataButtonProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { json } = props;
    const words = extractWords(json);

    return (
      <>
        {words.map((word) => {
          const parsedBoxCoordinates = splitBoxCoordinates(word.boundingBox);
          const scaledBoxCoordinates = {
            x: parsedBoxCoordinates.x * RatioOriginalImage,
            y: parsedBoxCoordinates.y * RatioOriginalImage,
            height: parsedBoxCoordinates.height * RatioOriginalImage,
            width: parsedBoxCoordinates.width * RatioOriginalImage,
          };

          const isValue = valueWords.includes(word);
          const isKey = keyWords.includes(word);
          const highlightColor = isValue ? 'blue' : isKey ? 'green' : undefined;
          return (
            <ClickableInvoiceOverlay
              key={word.boundingBox}
              coordinates={scaledBoxCoordinates}
              onClick={() => {
                selectWord(word);
              }}
              highlight={highlightColor}
            >
              {word.text}
            </ClickableInvoiceOverlay>
          );
        })}{' '}
      </>
    );
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { creditorId, _id } = invoice;
  const ruleType = label.id;

  const addRuleMutation = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();

    let newBox = '';
    let keyWordsValue = '';
    let keyWordsBox = '';
    keyWords.forEach((word, index) => {
      keyWordsValue = `${keyWordsValue} ${word.text}`;
      if (index === 0) {
        keyWordsBox = word.boundingBox;
      }
    });

    if (ruleType !== 'creditorIdTag') {
      let valueWordsValue = '';
      let valueWordsBox = '';
      valueWords.forEach((word, index) => {
        valueWordsValue = `${valueWordsValue} ${word.text}`;
        if (index === 0) {
          valueWordsBox = word.boundingBox;
        }
      });

      const K = keyWordsBox.split(',');
      const V = valueWordsBox.split(',');

      const vWCenter =
        Number.parseInt(V[0], 10) + Number.parseInt(V[2], 10) / 2;
      const kWCenter =
        Number.parseInt(K[0], 10) + Number.parseInt(K[2], 10) / 2;

      const vHCenter =
        Number.parseInt(V[1], 10) + Number.parseInt(V[3], 10) / 2;
      const kHCenter =
        Number.parseInt(K[1], 10) + Number.parseInt(K[3], 10) / 2;

      const newWidth = vWCenter - kWCenter;
      const newHeight = vHCenter - kHCenter;

      newBox = `${newWidth} ${newHeight}`;
    }

    addRule({
      variables: {
        creditorId,
        keyGlobal: ruleType,
        keyValue: keyWordsValue,
        keyBox: newBox,
        currentInvoice: _id,
      },
    }).catch((error) => {
      throw new Error(error);
    });
    handleClose();
  };

  return (
    <div>
      <div className={classes.buttonwrapper}>
        <Typography variant="h3" gutterBottom>
          {/* eslint-disable-next-line  unicorn/consistent-destructuring */}
          {invoice.creditorName || invoice.creditorId}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Attribut: {label.label}
        </Typography>

        <Typography variant="h4" gutterBottom style={{ marginTop: 32 }}>
          Label
        </Typography>

        {keyWords && (
          <p className={classes.key}>
            {keyWords.map((word) => word.text).join(' ')}
          </p>
        )}
        {!keyWords && (
          <p
            className={classes.key}
            style={{
              color: 'grey',
            }}
          >
            Noch kein Label markiert
          </p>
        )}
        <Button
          onClick={toggleKeyMode}
          color="secondary"
          variant="contained"
          style={{
            backgroundColor: mode === Mode.KEY ? 'blue' : undefined,
          }}
          fullWidth
        >
          {mode === Mode.KEY ? 'Markieren beenden' : 'Label markieren'}
        </Button>
        <Button onClick={deleteKey} style={{ marginTop: 8 }} fullWidth>
          Label löschen
        </Button>

        {(() => {
          if (ruleType !== 'creditorIdTag') {
            return (
              <div>
                <Typography variant="h4" gutterBottom style={{ marginTop: 32 }}>
                  Wert
                </Typography>
                {valueWords && (
                  <p className={classes.value}>
                    {valueWords.map((word) => word.text).join(' ')}
                  </p>
                )}
                {!valueWords && (
                  <p
                    className={classes.value}
                    style={{
                      color: 'grey',
                    }}
                  >
                    Noch kein Wert markiert
                  </p>
                )}
                <Button
                  onClick={toggleValueMode}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: mode === Mode.VALUE ? 'blue' : undefined,
                  }}
                >
                  {mode === Mode.VALUE ? 'Markieren beenden' : 'Wert markieren'}
                </Button>

                <Button
                  onClick={deleteValue}
                  fullWidth
                  style={{
                    marginTop: 8,
                  }}
                >
                  Wert löschen
                </Button>
              </div>
            );
          }
          return <></>;
        })()}

        <Button
          fullWidth
          onClick={addRuleMutation}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: 'green',
            marginTop: 64,
          }}
        >
          Neue Regel speichern
        </Button>
        <Button
          style={{
            marginTop: 8,
          }}
          fullWidth
          onClick={() => {
            handleClose();
          }}
        >
          Abbrechen
        </Button>
      </div>
      {json.map((jsonText: any, index) => {
        return (
          <ImageWrapper>
            <Image
              src={`${Environment.API_ENDPOINT}/requests/${_id}-${
                index + 1
              }.jpg`}
              alt="No image found!"
            />
            <DataButton json={JSON.parse(jsonText)} />
          </ImageWrapper>
        );
      })}
    </div>
  );
};
