import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Widget } from '../Widget';
import { Invoice } from '../../../../../dto/Invoice';

export interface SingleValueWidgetProps {
  label: string;
  value?: string;
  relativeChange?: number;
  data?: { invoices: Invoice[] };
}

export const NochNichtErfassteRechnungen: FC<SingleValueWidgetProps> = (
  props
) => {
  const { label, data } = props;

  const count = (data?.invoices || []).filter((invoice) =>
    ['0'].includes(invoice.invoiceStatus)
  ).length;

  return (
    <Widget title={label} height={1.25} width={3}>
      <Typography variant="body1">{count}</Typography>

      <Typography variant="body2" style={{ display: 'none' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;zur Vergleichsperiode
      </Typography>
    </Widget>
  );
};
