import React, { FC } from 'react';
import styled from 'styled-components';
import { Done, Warning, Error } from '@material-ui/icons';
import { Invoice } from '../../../../../dto/Invoice';
import { useFormContext } from 'react-hook-form';

const SCCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SCWrapper = styled.div`
  padding: 40px;
  min-width: 300px;
`;

const SCLabelText = styled.p`
  margin-left: 10px;
`;

interface InvoiceAcceptModalBodyProps {
  setDataComplete: (arg0: boolean) => void;
}

interface ChecklistType {
  id: keyof Invoice;
  label: string;
  required: boolean | 'warning';
}

interface ChecklistProps {
  checkList: ChecklistType[];
  setDataComplete: (arg0: boolean) => void;
}

interface CheckListObjectProps {
  type: number;
}

const CheckListObject = (props: CheckListObjectProps): JSX.Element => {
  const { type } = props;
  const Icons: { [key: number]: JSX.Element } = {
    0: <Done style={{ color: 'green' }} />,
    1: <Error style={{ color: 'red' }} />,
    2: <Warning style={{ color: 'yellow' }} />,
  };
  return Icons[type];
};

const CheckList = (props: ChecklistProps): JSX.Element => {
  const { checkList, setDataComplete } = props;
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const validateChecklistObject = (checkListItem: ChecklistType): number => {
    if (checkListItem.id === 'compareBookingdate') {
      const invoiceDate = new Date(
        Number.parseInt(getValues('invoiceDate'), 10)
      );
      const bookingDate = new Date(
        Number.parseInt(getValues('bookingDate'), 10)
      );

      if (
        invoiceDate.getFullYear() !== bookingDate.getFullYear() ||
        invoiceDate.getMonth() !== bookingDate.getMonth()
      ) {
        return 2;
      }
      return 0;
    }

    if (checkListItem.id === 'compareGrossTotal') {
      if (getValues('grossTotal') !== getValues('grossTotalInvoice')) return 1;
      return 0;
    }

    // eslint-disable-next-line prefer-destructuring
    const idCheckList = checkListItem.id;
    if (getValues(`${idCheckList}`)) {
      return 0;
    }
    if (!getValues(`${idCheckList}`) && checkListItem.required !== 'warning') {
      return 1;
    }
    return 2;
  };

  return (
    <>
      {checkList.map((obj) => {
        return (
          <SCCheck key={obj.id}>
            {validateChecklistObject(obj) === 1 && setDataComplete(false)}
            <CheckListObject type={validateChecklistObject(obj)} />
            <SCLabelText>{obj.label}</SCLabelText>
          </SCCheck>
        );
      })}
    </>
  );
};

export const InvoiceAcceptModalSummary: FC<InvoiceAcceptModalBodyProps> = (
  props
) => {
  const { setDataComplete } = props;

  const checkList: ChecklistType[] = [
    /*   {
      id: 'creditorId',
      required: 'warning',
      label: 'Kreditor',
    }, */
    {
      id: 'grossTotal',
      required: 'warning',
      label: 'Brutto gesamt',
    },
    {
      id: 'zTerm',
      required: 'warning',
      label: 'Zahlungsbedingungen',
    },
    /*   {
      id: 'invoiceNr',
      required: 'warning',
      label: 'Rechnungsnummer',
    }, */
    /*   {
      id: 'invoiceDate',
      required: 'warning',
      label: 'RechnungsDatum',
    }, */
    /*  {
      id: 'seperateCosts',
      required: 'warning',
      label: 'Nebenkosten',
    }, */
    {
      id: 'compareBookingdate',
      required: 'warning',
      label: 'Buchungsdatum abgleich',
    },
    {
      id: 'compareGrossTotal',
      required: true,
      label: 'Bruttobetrag abgleich',
    },
  ];

  return (
    <SCWrapper>
      <ul>
        <CheckList checkList={checkList} setDataComplete={setDataComplete} />
      </ul>
    </SCWrapper>
  );
};
