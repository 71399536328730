import React from 'react';
import { CellProps } from 'react-table';
import { ReportProblemOutlined } from '@material-ui/icons';
import { Entity } from '../../../../../dto/Entity';

export const DiscountCell = <TYPE extends Entity>(
  props: CellProps<TYPE>
): JSX.Element => {
  const { value } = props;

  return value ? (
    <ReportProblemOutlined style={{ color: 'rgb(255,0,0)' }} />
  ) : (
    <p>-</p>
  );
};
