import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

interface PageTitleProps {
  children: string;
}

const PageTitleComponent: FC<PageTitleProps> = (props) => {
  const { children } = props;

  return (
    <Typography variant="h3" gutterBottom display="inline">
      {children}
    </Typography>
  );
};

export const PageTitle = PageTitleComponent;
