import React, { FC } from 'react';
import { grey } from '@material-ui/core/colors';

import {
  Drawer,
  DrawerProps,
  Hidden,
  createStyles,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Menu } from './sidebar/Menu';
import { ResponsivenessConfiguration } from '../ResponsivenessConfiguration';

const { drawerWidth, permanentSidebarBreakPoint } = ResponsivenessConfiguration;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up(permanentSidebarBreakPoint)]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      background: '#1B2430',
      color: grey[200],
      width: drawerWidth,
    },
  })
);

const SidebarComponent: FC<DrawerProps> = (props) => {
  const { container, open, onClose } = props;
  const classes = useStyles();

  return (
    <>
      <nav className={classes.drawer} aria-label="Sidebar navigation">
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Menu />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Menu />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export const Sidebar = SidebarComponent;
