import { useQuery, UseQueryResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';
import { Order } from '../dto/Order';

const QUERY = gql`
  query($creditorId: String!) {
    orders(creditorId: $creditorId) {
      orderId
      creditorId
      positions {
        EBELN
        EBELP
        BEDAT
        NETWR
        MENGE
        WEMNG
        MATNR
        ZTERM
        REMNG
      }
    }
  }
`;
interface OrderData {
  orders: Order[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOrdersQuery = (
  creditorId: string | undefined
): UseQueryResult<OrderData, Error> => {
  return useQuery<OrderData, Error>(
    'orders',
    async () => {
      const data = await graphQLClient.request(QUERY, { creditorId });
      console.log('ORDERSDATA:', data);
      return data;
    },
    { cacheTime: 0 }
  );
};
