import { addWeeks, addMonths, startOfMonth, startOfWeek } from 'date-fns';
import { Kpi } from '../../../../../dto/Kpi';

export type AggregatedChartData = Record<string, AggregatedChartDataValue>;

export interface AggregatedChartDataValue {
  date: Date;
  count: number;
  value: 0;
}

export const totals = (data: Kpi[]): { Counter: number; Netvalue: number } => {
  // eslint-disable-next-line unicorn/no-array-reduce
  return data.reduce(
    (acc: { Counter: number; Netvalue: number }, value) => {
      acc.Counter += Number.parseInt(value.Counter, 10);
      acc.Netvalue += Number.parseFloat(value.Netvalue);
      return acc;
    },
    { Counter: 0, Netvalue: 0 }
  );
};

export const aggregateByWeek = (
  startDate: Date,
  endDate: Date,
  data: Kpi[]
): AggregatedChartData => {
  const chartData = {} as AggregatedChartData;
  let currentDate = startOfWeek(startDate);
  do {
    chartData[currentDate.toISOString()] = {
      date: currentDate,
      count: 0,
      value: 0,
    };
    currentDate = addWeeks(currentDate, 1);
  } while (currentDate.getTime() < endDate.getTime());

  if (data) {
    data.forEach((kpi) => {
      const { Counter, KpiDate, Netvalue } = kpi;
      const dateKey = startOfWeek(new Date(KpiDate)).toISOString();
      const entry = chartData[dateKey];

      if (entry) {
        entry.count += Number.parseInt(Counter, 10);
        entry.value += Number.parseFloat(Netvalue);
      } else {
        // eslint-disable-next-line no-console
        console.warn('date out of bounds', dateKey);
      }
    });
  }
  return chartData;
};

export const aggregateByMonth = (
  startDate: Date,
  endDate: Date,
  data: Kpi[]
): AggregatedChartData => {
  const chartData = {} as AggregatedChartData;
  let currentDate = startOfMonth(startDate);
  do {
    chartData[currentDate.toISOString()] = {
      date: currentDate,
      count: 0,
      value: 0,
    };
    currentDate = addMonths(currentDate, 1);
  } while (currentDate.getTime() < endDate.getTime());

  if (data) {
    data.forEach((kpi) => {
      const { Counter, KpiDate, Netvalue } = kpi;
      const dateKey = startOfMonth(new Date(KpiDate)).toISOString();
      const entry = chartData[dateKey];

      if (entry) {
        entry.count += Number.parseInt(Counter, 10);
        entry.value += Number.parseFloat(Netvalue);
      } else {
        // eslint-disable-next-line no-console
        console.warn('date out of bounds', dateKey);
      }
    });
  }
  return chartData;
};
