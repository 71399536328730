import React, { FC } from 'react';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';
import { ApolloConnection } from './gql/ApolloConnection';

import { Themes } from './theme';
import { MuiPickersProvider } from './MuiPickersProvider';
import { msalInstance } from './setupAuthentication';

import { StateProvider } from './stores/globalStore';

import { queryClient } from './query/setup';
import { Environment } from './config/Environment';

const AppProviderComponent: FC = ({ children }) => {
  console.log('auth', Environment.AZURE_AD_CLIENT_ID);
  console.log('auth', Environment.AZURE_AD_REDIRECT_URI);
  // eslint-disable-next-line @typescript-eslint/dot-notation
  console.log('auth', process.env['PORT']);
  return (
    <MsalProvider instance={msalInstance}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={Themes[0]}>
          <ThemeProvider theme={Themes[0]}>
            <StateProvider>
              <QueryClientProvider client={queryClient}>
                <ApolloConnection>
                  <SnackbarProvider>
                    <MuiPickersProvider>{children}</MuiPickersProvider>
                  </SnackbarProvider>
                </ApolloConnection>
              </QueryClientProvider>
            </StateProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </MsalProvider>
  );
};

export const AppProvider = AppProviderComponent;
