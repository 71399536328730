import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

const TitleComponent: FC = (props) => (
  <Typography component="h1" variant="h3" align="center" gutterBottom>
    {props.children}
  </Typography>
);

export const Title = TitleComponent;
