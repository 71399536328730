import React, { FC, ReactElement } from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import {
  NewReleases,
  ViewList,
  Work,
  Ballot,
  Error,
  AttachFile,
  Storage,
  FileCopy,
  HighlightOff,
  DoneOutline,
  AccountBalance,
  DonutLarge,
} from '@material-ui/icons';

export interface StatusVisualisation {
  icon: ReactElement;
  label: string;
  styles: React.CSSProperties;
}

export interface StatusBadgeProps extends ChipProps {
  status: number;
}

export const statusBadges: { [key: number]: StatusVisualisation } = {
  0: {
    icon: <NewReleases style={{ color: '#ffffff' }} />,
    label: 'Neu',
    styles: {
      background: 'rgb(223, 169, 149)',
      color: '#ffffff',
    },
  },
  1: {
    icon: <Work style={{ color: '#ffffff' }} />,
    label: 'In Bearbeitung',
    styles: {
      background: 'rgb(168, 193, 198)',
      color: 'rgb(255,255,255)',
    },
  },

  2: {
    icon: <ViewList style={{ color: '#ffffff' }} />,
    label: 'Gebucht',
    styles: {
      background: 'rgb(152, 230, 144)',
      color: 'rgb(255,255,255)',
    },
  },
  3: {
    icon: <ViewList style={{ color: '#ffffff' }} />,
    label: 'Ungültig',
    styles: {
      background: 'rgb(248, 88, 88)',
      color: 'rgb(255,255,255)',
    },
  },
  4: {
    icon: <Ballot style={{ color: '#ffffff' }} />,
    label: 'Vorerfasst',
    styles: {
      background: 'rgb(130, 125, 0)',
      color: 'rgb(255,255,255)',
    },
  },
  5: {
    icon: <Error style={{ color: '#ffffff' }} />,
    label: 'Buchung Fehlgeschlagen',
    styles: {
      background: 'rgb(130, 0, 0)',
      color: 'rgb(255,255,255)',
    },
  },
  /*  6: {
    icon: <AttachFile style={{ color: '#ffffff' }} />,
    label: 'Einkauf',
    styles: {
      background: 'rgb(59, 31, 184)',
      color: '#ffffff',
    },
  }, */
  7: {
    icon: <Storage style={{ color: '#ffffff' }} />,
    label: 'kein Wareneingang',
    styles: {
      background: 'rgb(255, 176, 6)',
      color: '#ffffff',
    },
  },
  8: {
    icon: <FileCopy style={{ color: '#ffffff' }} />,
    label: 'Duplikat',
    styles: {
      background: 'rgb(209, 50, 100)',
      color: '#ffffff',
    },
  },
  9: {
    icon: <HighlightOff style={{ color: '#ffffff' }} />,
    label: 'Abgelehnt',
    styles: {
      background: 'rgb(145, 21, 12)',
      color: '#ffffff',
    },
  },
  10: {
    icon: <DoneOutline style={{ color: '#ffffff' }} />,
    label: 'Freigegeben',
    styles: {
      background: 'rgb(52, 135, 42)',
      color: '#ffffff',
    },
  },
  11: {
    icon: <AccountBalance style={{ color: '#ffffff' }} />,
    label: 'HEP',
    styles: {
      background: 'rgb(226, 135, 67)',
      color: '#ffffff',
    },
  },
  12: {
    icon: <DonutLarge style={{ color: '#ffffff' }} />,
    label: 'VZ',
    styles: {
      background: 'rgb(135, 62, 35)',
      color: '#ffffff',
    },
  },
};

const unknownStatus = {
  icon: '',
  label: '???',
  styles: {},
};

export const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const { status } = props;
  const statusConfig = statusBadges[status] || unknownStatus;
  return (
    <Chip
      icon={statusConfig.icon}
      label={statusConfig.label}
      style={{
        ...statusConfig.styles,
        whiteSpace: 'nowrap',
      }}
    />
  );
};
