import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient } from './setup';
import { User, useUsersQuery } from './UsersQuery';

const QUERY = gql`
  mutation addUser($mail: String, $role: String) {
    addUser(mail: $mail, role: $role) {
      _id
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAddUser = (
  variables?: User
): UseMutationResult<User, Error, void> => {
  const { refetch } = useUsersQuery();

  return useMutation<User, Error, void>(
    'user',
    async () => {
      const data = await graphQLClient.request(QUERY, variables);
      return data;
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
};
