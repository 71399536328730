import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { graphQLClient, queryClient } from './setup';

const QUERY = gql`
  mutation AcceptInvoice($_id: ID!, $acceptor: String, $acceptType: String) {
    acceptInvoice(_id: $_id, acceptor: $acceptor, acceptType: $acceptType) {
      booked
      receiptNumber
      message {
        type
        message
        messagev1
        messagev2
        messagev3
        messagev4
      }
    }
  }
`;

interface AcceptType {
  _id: string;
  acceptor: string;
  acceptType: string;
}

interface ResponseType {
  booked: boolean;
  receiptNumber: string;
  message: string[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAcceptInvoiceMutation = (
  variables: AcceptType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  console.log('mutating');
  return useMutation<ResponseType, Error>('acceptInvoice', async () => {
    const data = await graphQLClient.request(QUERY, variables);
    console.log('gotData');
    return data;
  });
};
